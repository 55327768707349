"use strict";
exports.__esModule = true;
exports.FeatureFlags = void 0;
// centrally managed feature toggles from configcat.com
var FeatureFlags;
(function (FeatureFlags) {
    FeatureFlags["JUST_DONATE_USER_JOURNEY_ENABLED"] = "justdonateuserjourneyenabled";
    FeatureFlags["NEW_BRAND_DESIGN"] = "newBrandDesign";
    /**
     * Enables/disables the backend functionalities for user registration, login, password forget, and user profile.
     */
    FeatureFlags["USER_ACCOUNTS_ENABLED_BACKEND"] = "userAccountsEnabledBackend";
    /**
     * Enables the routes and UI components that allow for user registration, login, password reset and user profile page.
     */
    FeatureFlags["USER_ACCOUNTS_ENABLED_FRONTEND"] = "userAccountsEnabledFrontend";
    /**
     * Enables the frontend integration of the Adyen payments
     */
    FeatureFlags["PAYMENTS_ADYEN_ENABLED_FRONTEND"] = "paymentsAdyenEnabledFrontend";
    /**
     * Enables the backend endpoints of the Adyen payments
     */
    FeatureFlags["PAYMENTS_ADYEN_ENABLED_BACKEND"] = "paymentsAdyenEnabledBackend";
})(FeatureFlags = exports.FeatureFlags || (exports.FeatureFlags = {}));
