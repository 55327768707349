// fetch the slug to show in the users browser
export const getSlugForPageConfig = (
    config: any,
    journeyName: string,
    flowName: string,
    stepName: string,
    language: string
): string => {
    try {
        return config.userJourneyMap[language][journeyName][flowName][stepName].slug
    } catch(e) { // initial config might be not set partially
        return '';
    }
};
