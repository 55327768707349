import React from 'react'

export interface IStepper {
    onClick: () => void,
    disabled?: boolean,
    color?: string,
}

export const StepperLeft = ({ onClick, disabled, color }: IStepper) => (
    <svg
        width={38}
        height={38}
        fill="none"
        cursor="pointer"
        style={{ marginRight: 10 }}
        viewBox="0 0 38 38"
        onClick={onClick}
        opacity={disabled ? 0.5 : 1}
    >
        <circle cx="19" cy="19" r="19" transform="rotate(-180 19 19)" fill="white" />
        <path d="M21.375 13.4584L15.8333 19.0001L21.375 24.5417" stroke={color} strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
    </svg>
)
