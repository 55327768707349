import React, {
    ReactNode,
    useCallback,
    useContext,
    useEffect,
    useLayoutEffect,
    useState,
} from "react";
import { Box } from "@material-ui/core";

import { BREAKPOINTS, Margin, useViewport } from "@cpyou/shared/dist";
import { LayoutContext } from "../context/layoutContext";

export enum ContentType {
    "DEFAULT" = "DEFAULT",
    "PICTURE_TEXT_LIST" = "PICTURE_TEXT_LIST",
}
export interface IPropsContentLayout {
    containerPaddingBottom?: string | number;
    containerPaddingTop?: string | number;
    contentType?: ContentType;
    children: ReactNode;
}

export const ContentLayout = ({
    containerPaddingBottom = 0,
    containerPaddingTop = "80px",
    contentType = ContentType.DEFAULT,
    children,
}: IPropsContentLayout) => {
    const { containerRef } = useContext(LayoutContext);
    const sizeModel = useViewport(BREAKPOINTS, containerRef);

    const [layoutContainerSpace, setLayoutContainerSpace] = useState({
        left: 0,
        center: 0,
        right: 0,
    });

    const calcLayoutSpace = useCallback(() => {
        if (sizeModel.isMobile)
            setLayoutContainerSpace({ left: 0, center: 12, right: 0 });
        if (sizeModel.isTablet)
            setLayoutContainerSpace({ left: 1, center: 10, right: 1 });
        if (sizeModel.isDesktop) {
            setLayoutContainerSpace(
                contentType === ContentType.PICTURE_TEXT_LIST
                    ? { left: 1, center: 10, right: 1 }
                    : { left: 2, center: 8, right: 2 }
            );
        }
        if (sizeModel.isDesktopXL) {
            setLayoutContainerSpace(
                contentType === ContentType.PICTURE_TEXT_LIST
                    ? { left: 1, center: 10, right: 1 }
                    : { left: 2, center: 8, right: 2 }
            );
        }
        if (sizeModel.isDesktopXXL) {
            setLayoutContainerSpace(
                contentType === ContentType.PICTURE_TEXT_LIST
                    ? { left: 2, center: 8, right: 2 }
                    : { left: 3, center: 6, right: 3 }
            );
        }
    }, [sizeModel.viewportWidth, contentType]);

    useLayoutEffect(() => {
        calcLayoutSpace();
    }, [sizeModel.viewportWidth]);

    const leftWidth =
        layoutContainerSpace.left *
        (sizeModel.columnWidth + sizeModel.gutterWidth);
    const centerWidth =
        layoutContainerSpace.center *
            (sizeModel.columnWidth + sizeModel.gutterWidth) -
        sizeModel.gutterWidth;
    const rightWidth =
        layoutContainerSpace.right *
        (sizeModel.columnWidth + sizeModel.gutterWidth);

    return (
        <Box
            display="flex"
            style={{
                paddingTop: containerPaddingTop,
                paddingBottom: containerPaddingBottom,
            }}
        >
            <Margin viewportEl={containerRef} />
            <Box width={leftWidth} />
            <Box width={centerWidth} display="flex" flexDirection="column">
                {children}
            </Box>
            <Box width={rightWidth} />
            <Margin viewportEl={containerRef} />
        </Box>
    );
};
