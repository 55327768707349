import React, { useContext, useState } from 'react';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import { Button } from '@material-ui/core';
import { GMenu } from '../interface/graphql/GMenu';
import { getClasses } from './DrawerDesktopMenu.jss';
import { DrawerContext } from './Drawer';
import { PageContext } from '../layouts/MainLayout';
import { IPageContext } from '../interface/PageContext';
import { pageToLink } from '../functions/pageToLink';
import { navigate } from '../functions/navigate';
import { joinClasses } from '../functions/join';
import { GLink } from '../interface/graphql/GLink';
import { FeatureFlagsContext } from './App';
import { getLinkDisplayStyle } from '../functions/getLinkDisplayStyle';
import { FeatureFlags, IFeatureFlags } from '@cpyou/shared/dist';
import { useEffectOnce } from 'react-use';
import { getFeatureFlag } from '../functions/getFeatureFlag';
import Image from './image/Image';
import { getImageUrl } from '../functions/getImageUrl';

export interface IPropsDrawerMenu {
    className?: string;
    handleLanguageChange: (selectedLanguage: string) => Promise<void>;
}

export const DrawerDesktopMenu = ({ className, handleLanguageChange }: IPropsDrawerMenu) => {
    const {
        desktopArrow,
        menu, translation, color, justDonateButton,
    } = useContext(DrawerContext);
    const { language, coBrand } = useContext(PageContext) as IPageContext;
    const featureFlags = useContext(FeatureFlagsContext) as IFeatureFlags;
    const [newBrandDesignEnabled] = useState(featureFlags[FeatureFlags.NEW_BRAND_DESIGN]);

    const [justDonateUserJourneyEnabled] = useState(featureFlags[FeatureFlags.JUST_DONATE_USER_JOURNEY_ENABLED]);
    // use build-time feature flag value as the default
    const [userAccountEnabled/*, setUserAccountsEnabled*/] = useState(featureFlags[FeatureFlags.USER_ACCOUNTS_ENABLED_FRONTEND]);

    /*
    useEffectOnce(() => {
        (async() => {

            // DEMO: fetch run-time feature flag if necessary (probably not in this case!)
            setUserAccountsEnabled(await getFeatureFlag(FeatureFlags.USER_ACCOUNTS_ENABLED_FRONTEND));
        })();
    })
    */

    const [anchorEl, setAnchorEl] = React.useState<{ [key: string]: Element | undefined | null }>({});
    const classes = getClasses();

    const handleClick = (title: string) => (event: React.MouseEvent) => {
        const { parentNode } = event.currentTarget;
        if (parentNode && !!parentNode.children[2]) {
            setAnchorEl((c) => ({
                ...c,
                [title]: parentNode.children[2],
            }));
        }
    };

    const handleClose = (title: string) => () => {
        setAnchorEl((c) => ({
            ...c,
            [title]: null,
        }));
    };

    const handleNavigationClick = async (page?: { slug: string }, search?: string) => {
        await navigate(pageToLink(language, coBrand, page, search));
    }

    return (
        <div className={className}>
            {!!menu && menu.map((m: GMenu, idx) => (
                <span key={`${m.title}${idx}`} style={getLinkDisplayStyle(m, featureFlags)}>
                    <div className={classes.titleNotLast}>
                        <div className={classes.title} onClick={handleClick(m.title)}>
                            <div style={{ color }}>
                                {m.title}
                            </div>
                            <div className={classes.titleArrow}>
                                <Image className={classes.titleArrowImg} src={getImageUrl(desktopArrow)} alt={desktopArrow?.alt} />
                            </div>
                        </div>
                        {/* dropdown opens in middle of anchor element => factor 2 */}
                        <div style={{ height: 21 * 2 }} />
                        {/* don't delete the anchor element */}
                        <div />
                    </div>
                    <Menu
                        anchorEl={anchorEl[m.title]}
                        keepMounted
                        classes={{ list: classes.subTitleMenuList }}
                        open={Boolean(anchorEl[m.title])}
                        onClose={handleClose(m.title)}
                    >
                        {m.subTitle.map((subM, idx) => (
                            <MenuItem
                                style={getLinkDisplayStyle(subM, featureFlags)}
                                key={`${subM.title}${idx}`}
                                className={classes.subTitleMenu}
                                onClick={() => handleNavigationClick(subM.page, subM.search)}
                            >
                                <div className={classes.subTitle}>
                                    {subM.title}
                                </div>
                            </MenuItem>
                        ))}
                    </Menu>
                </span>
            ))}
            {justDonateButton && !coBrand &&(
                <Button
                    color="secondary"
                    variant="contained"
                    style={{ fontSize: 'inherit' }}
                    className={joinClasses({
                        [classes.buttonJustDonate]: true,
                        [classes.buttonJustDonateGreen]: color === 'black',
                    })}
                    onClick={() => handleNavigationClick(justDonateButton.page, justDonateButton.search)}
                >
                    <span>{justDonateButton?.title}</span>
                </Button>
            )}
            <div className={joinClasses([classes.title, classes.translation])}>
                {translation?.languages
                    .map((v) => ({ ...v, lan: v.locale.substring(0, 2).toUpperCase() }))
                    .map((l, index) => (
                        <span key={l.locale} style={{ color }}>
                            <span style={{ fontWeight: language.toUpperCase() === l.lan ? 'bold' : 'normal' }} onClick={() => handleLanguageChange(l.locale)}>
                                {l.lan}
                            </span>
                            {(index !== translation?.languages.length - 1)
                                && (
                                    <span style={{ marginRight: 15, marginLeft: 15 }}>
                                        |
                                    </span>
                                )}
                        </span>
                    ))}
            </div>

        </div>
    )
}
