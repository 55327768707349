import React, { PropsWithChildren } from 'react';
import { memo } from 'react';
import { useCookie, useEffectOnce } from 'react-use';
import { v4 as uuidv4 } from 'uuid';
import { DrawerContext, IDrawerContext } from './Drawer';
import { FooterContext } from './Footer';
import { SeoContext } from './Seo';
import { PageContext } from '../layouts/MainLayout';
import { IPageContext } from '../interface/PageContext';
import { GSeo } from '../interface/graphql/GSeo';
import { GFooter } from '../interface/graphql/GFooter';
import { GHeader } from '../interface/graphql/GHeader';
import { CIThemeProvider } from '../coperate-identity/Theme';
import { KeyValuesContext } from './KeyValuesContext';
import { GKeyValue } from '../interface/graphql/GKeyValue';
import { useAlias } from '../hook/useAlias';
import { getSessionStorageApi, IFeatureFlags } from '@cpyou/shared/dist';
import { useMixpanel } from '../hook/useMixpanel';
import { useWaitForDefined } from '../hook/useWaitForDefined';

export const FeatureFlagsContext = React.createContext<IFeatureFlags>({});

export interface IPropsApp {
  pageContext: IPageContext;
  seoContext: GSeo;
  keyValuesContext: GKeyValue;
  footerContext: GFooter;
  header: GHeader;
  borderMobileMenu?: boolean;
}

export const App = memo(
    ({
        children,
        pageContext,
        seoContext,
        footerContext,
        header,
        keyValuesContext,
        borderMobileMenu = false,
    }: PropsWithChildren<IPropsApp>) => {
        const [uuidCookie, updateUuidCookie] = useCookie(
            'uuid',
        );
        const [isIdentified, updateIsIdentified] = useCookie('is-user-identified');
        const { setAlias } = useAlias();
        const { mixpanel } = useMixpanel();

        // using the coBrand-dependent header instead of the one provided by the page GQL
        if (pageContext && pageContext.coBrand && pageContext.coBrand.header) {

            pageContext.coBrand.header.forEach((coBrandHeader: { node: GHeader & { locale: string } }) => {

                if (coBrandHeader.node && pageContext.locale === coBrandHeader.node.locale) {
                    header = coBrandHeader.node;

                    // coBrand header has no special onlyMobile flag;
                    // but in calculator mode, we need to switch that one on
                    if (pageContext.templatePath === 'CalculatorPageTemplate') {
                        header.onlyMobile = true;
                    }
                }
            });
        }

        useWaitForDefined(typeof window !== 'undefined' ? window : {}, 'UC_UI', () => {
            // update the UserCentrics language to the language set by the user
            (window as any).UC_UI.updateLanguage(pageContext.language)
        })

        const drawerContext: IDrawerContext = {
            isOnlyMobile: header.onlyMobile,
            mobileCloseButton: header.menuMobileCloseButton,
            mobileBurgerButton: header.menuMobileBurgerButton,
            desktopArrow: header.menuDesktopArrowDown,
            menu: header?.menu,
            justDonateButton: header.justDonateButton,
            translation: header.translation,
            color: header.color,
            borderMobileMenu,
        }

        useEffectOnce(() => {
            //console.log('website featureFlags', pageContext.featureFlags)
            //console.log('website coBrand', pageContext.coBrand)

            // set built-time feature flags
            if (typeof window !== 'undefined') {
                try {
                    getSessionStorageApi().setItem('featureFlags', JSON.stringify(pageContext.featureFlags));
                } catch(e) {
                    // ignore
                }
            }

            // TODO: Test after Usercentrics implementation. probably rework or move to mixpanel.js
            if (!uuidCookie && mixpanel) {
                const uuid = uuidv4();

                updateUuidCookie(uuid, {
                    expires: 9999,
                })

                setAlias(uuid, 'uuid');

                updateIsIdentified('true');
            }

            if (isIdentified !== 'true' && uuidCookie && mixpanel) {
                mixpanel.identify(uuidCookie)
                updateIsIdentified('true');
            }
        });

        return (
            <FeatureFlagsContext.Provider value={pageContext.featureFlags}>
                <PageContext.Provider value={pageContext}>
                    <SeoContext.Provider value={seoContext}>
                        <KeyValuesContext.Provider value={keyValuesContext}>
                            <FooterContext.Provider value={footerContext}>
                                <DrawerContext.Provider value={drawerContext}>
                                    <CIThemeProvider featureFlags={pageContext.featureFlags} coBrand={pageContext.coBrand}>
                                        <>{children}</>
                                    </CIThemeProvider>
                                </DrawerContext.Provider>
                            </FooterContext.Provider>
                        </KeyValuesContext.Provider>
                    </SeoContext.Provider>
                </PageContext.Provider>
            </FeatureFlagsContext.Provider>
        );
    },
);
