import React from 'react'

export const Location = ({ color }: { color: string }) => (

    <svg width="15" height="21" viewBox="0 0 15 21" fill="none" xmlns="http://www.w3.org/2000/svg">
        <g clipPath="url(#clip0_13359:50050)">
            <path d="M7.24 0.410004C3.24 0.420004 0 3.66 0 7.66C0 11.66 7.24 20.59 7.24 20.59C7.24 20.59 14.49 11.66 14.49 7.66C14.49 3.66 11.24 0.410004 7.24 0.410004ZM7.24 11.05C5.4 11.05 3.9 9.55 3.9 7.71C3.9 5.87 5.4 4.37 7.24 4.37C9.08 4.37 10.58 5.87 10.58 7.71C10.58 9.55 9.08 11.05 7.24 11.05Z" fill="black" fillOpacity="0.54"/>
        </g>
        <defs>
            <clipPath id="clip0_13359:50050">
                <rect width="14.49" height="20.18" fill="white" transform="translate(0 0.410004)"/>
            </clipPath>
        </defs>
    </svg>
)
