import React, { PropsWithChildren, useContext, useLayoutEffect, useState } from 'react'
import { graphql, PageProps } from 'gatsby';
import { Box, Typography, useTheme } from '@material-ui/core';
import { BREAKPOINTS, ISizeModel, Margin, useForceUpdate, useOnResize, useViewport } from '@cpyou/shared/dist';
import { getClasses } from './FaqPageTemplate.jss'
import { GFooter } from '../../interface/graphql/GFooter';
import { GSeo } from '../../interface/graphql/GSeo';
import { GHeader } from '../../interface/graphql/GHeader';
import { getImageUrl } from '../../functions/getImageUrl';
import { App } from '../../components/App';
import { GImage } from '../../interface/graphql/GImage';
import { PageContext } from '../../layouts/MainLayout';
import { mapKeyValues } from '../../functions/mapKeyValues';
import { GQuestionAnswer } from '../../interface/graphql/GQuestionAnswer';
import { GFaqCategory, FaqPageContentCategories } from './components/FaqPageContentCategories/FaqPageContentCategories';
import FaqPageContentQuestionsAnswers from './components/FaqPageContentQuestionAnswers/FaqPageContentQuestionsAnswers'
import { KeyValuesContext } from '../../components/KeyValuesContext';
import { NewMainBrandLayout } from '../../layouts/NewMainBrandLayout';
import { getPageHeaderBgImageUrl, PageTemplateName } from '../../functions/getPageHeaderBgImageUrl';

interface IFaqPage {
  category: { id: string };
  slug: string;
}

export interface IPropFaqPageTemplate {
  allContentfulKeyValue: any;
  allContentfulFaqPage: {
    nodes: Array<IFaqPage>
  },
  contentfulFaqPage: {
    title: string;
    locale: string;
    category?: GFaqCategory;
    slug: string;
    categoriesCollection: { categories: Array<GFaqCategory> };
    questionAnswers?: Array<GQuestionAnswer>;
    background: GImage;
    backgroundMobile: GImage;
    header: GHeader
    footer: GFooter
    seo: GSeo
  }
}

const mapPageToCategories = (allFaqPages: Array<IFaqPage>, categories: Array<GFaqCategory>) => categories.map((c) => {
    const { slug } = allFaqPages.find((p) => {
        if (p && p.category) {
            return p.category.id === c.id
        }
        return false;
    }) as IFaqPage;

    return { ...c, slug }
})

const PageContent = (props: PropsWithChildren<{ background: GImage; backgroundMobile: GImage; logo: GImage; 
  title: string; header: GHeader; category?: GFaqCategory; categories: Array<GFaqCategory & { slug: string }>; 
  questionAnswers?: Array<GQuestionAnswer>; pageTemplateName: PageTemplateName }>) => {
  const {
      logo, title, header, categories, questionAnswers, category, pageTemplateName
  } = props;

  const { featureFlags, images } = useContext(PageContext);
  const [containerRef, ] = useState<Element>();
  const sizeModel = useViewport(BREAKPOINTS, containerRef);

  const [layoutContainerSpace, setLayoutContainerSpace] = useState({left: 0, center: 0, right: 0})

  const forceUpdate = useForceUpdate();

  const calcLayoutSpace = (sizeModel: ISizeModel) => {
    if (sizeModel.isMobile) {
        setLayoutContainerSpace({left: 0, center: 12, right: 0})
    }
    if (sizeModel.isDesktop || sizeModel.isTablet) {
        setLayoutContainerSpace({left: 1, center: 10, right: 1})
    }
    if (sizeModel.isDesktopXL) {
      setLayoutContainerSpace({left: 2, center: 8, right: 2})
    }
    if (sizeModel.isDesktopXXL) {
        setLayoutContainerSpace({left: 3, center: 6, right: 3})
    }
    forceUpdate();
  }

  useLayoutEffect(() => {
    // initial run
    calcLayoutSpace(sizeModel)

    // on resize run
    // eslint-disable-next-line
    return useOnResize(calcLayoutSpace, BREAKPOINTS)
  }, [sizeModel.viewportWidth]);

    const classes = getClasses(featureFlags)();
    const keyValues = useContext(KeyValuesContext);

    const backgroundURL = getPageHeaderBgImageUrl(pageTemplateName, sizeModel.breakpointSize, images)
    const theme = useTheme()

    const headerContentSection = (
      <Box role='headerContainer' display='flex' flexDirection='row'>
          {!sizeModel.isMobile && (
            <Box role='outerSpaceLeft' width={sizeModel.columnWidth + sizeModel.gutterWidth} />
          )}
          <Box role='headerContainer' display='flex' flexDirection='column'>
          {sizeModel.isDesktopXXL ? (
            <>
              <Box width={sizeModel.columnWidth}>
                <Typography variant='h1'>{title}</Typography>
              </Box>
              <Typography variant='h1'>&mdash;</Typography>
              <Box width={3 * sizeModel.columnWidth + 2 * sizeModel.gutterWidth}>
                <Typography variant='body1'>{keyValues.faqPageInfoText}</Typography>
              </Box>
            </>
          ) : sizeModel.isDesktopXL ? (
            <>
              <Box width={sizeModel.columnWidth + 2 * sizeModel.gutterWidth}>
                <Typography variant='h1'>{title}</Typography>
              </Box>
              <Typography variant='h1'>&mdash;</Typography>
              <Box width={4 * sizeModel.columnWidth +  4 * sizeModel.gutterWidth}>
                <Typography variant='body1'>{keyValues.faqPageInfoText}</Typography>
              </Box>
            </>
          ) : sizeModel.isDesktop ? (
            <>
              <Box width={2 * sizeModel.columnWidth + sizeModel.gutterWidth}>
                <Typography variant='h1'>{title}</Typography>
              </Box>
              <Typography variant='h1'>&mdash;</Typography>
              <Box width={5 * sizeModel.columnWidth + 5 * sizeModel.gutterWidth}>
                <Typography variant='body1'>{keyValues.faqPageInfoText}</Typography>
              </Box>
            </>
          ) : sizeModel.isTablet ? (
            <>
              <Box width={2 * sizeModel.columnWidth + sizeModel.gutterWidth}>
                <Typography variant='h1'>{title}</Typography>
              </Box>
              <Typography variant='h1'>&mdash;</Typography>
              <Box width={6 * sizeModel.columnWidth + 5 * sizeModel.gutterWidth}>
                <Typography variant='body1'>{keyValues.faqPageInfoText}</Typography>
              </Box>
            </>
          ) : (
            <>
              <Box width={3 * sizeModel.columnWidth + 2 * sizeModel.gutterWidth}>
                <Typography variant='h1'>{title}</Typography>
              </Box>
              <Typography variant='h1'>&mdash;</Typography>
              <Box width={7 * sizeModel.columnWidth + 7 * sizeModel.gutterWidth}>
                <Typography variant='body1'>{keyValues.faqPageInfoText}</Typography>
              </Box>
            </>
          )}
        </Box>
      </Box>
    )

    return (
        <NewMainBrandLayout
            classes={{
                background: classes.background,
                backgroundRect: classes.background,
            }}
            backgroundUrl={backgroundURL}
            logoUrl={getImageUrl(logo)}
            headerBgShadeColor={theme.palette.primary.main}
            header={{ mainSlug: header.mainSlug }}
            childrenUnderBackground={(
                <>
                  <Box role='mainContainer' display='flex' flexDirection='row' marginTop={(sizeModel.breakpointSize === 'mobile' || sizeModel.breakpointSize === 'tablet') ? '40px' : '80px'}>
                    <Margin viewportEl={containerRef}/>
                    <Box role='outerSpaceLeft' width={layoutContainerSpace.left * sizeModel.columnWidth + layoutContainerSpace.left * sizeModel.gutterWidth}/>
                      <Box role='content' display='flex' flexDirection='column' width={layoutContainerSpace.center * sizeModel.columnWidth + (layoutContainerSpace.center - 1) * sizeModel.gutterWidth}>
                          <FaqPageContentCategories categories={categories} currentCategory={category as GFaqCategory} />
                          {questionAnswers && <FaqPageContentQuestionsAnswers questionsAnswers={questionAnswers} />}
                      </Box>
                    <Box role='outerSpaceRight' width={layoutContainerSpace.right * sizeModel.columnWidth + layoutContainerSpace.right * sizeModel.gutterWidth}/>
                    <Margin viewportEl={containerRef}/>
                  </Box>
                </>
            )}
        >
           {headerContentSection}
        </NewMainBrandLayout>
    )
}
const FaqPageTemplate = (props: PageProps<IPropFaqPageTemplate>) => {
    const { data: { contentfulFaqPage, allContentfulKeyValue, allContentfulFaqPage }, pageContext } = props;
    const {
        seo, header, footer, title, background, categoriesCollection, questionAnswers, category,
        backgroundMobile
    } = contentfulFaqPage;
    const keyValues = mapKeyValues(allContentfulKeyValue.nodes);

    const mappedShowCategories = mapPageToCategories(allContentfulFaqPage.nodes, categoriesCollection.categories)

    return (
        <App
            pageContext={pageContext as any}
            seoContext={seo}
            header={header}
            footerContext={footer}
            keyValuesContext={keyValues}
        >
            <PageContent
                background={background}
                backgroundMobile={backgroundMobile}
                logo={header.logo}
                title={title}
                header={header}
                categories={mappedShowCategories}
                questionAnswers={questionAnswers}
                category={category}
                pageTemplateName="faq"
            />
        </App>
    )
}

export default FaqPageTemplate

export const queryPage = graphql`
query NewContentfulFaqPageBySlugAndLocale($slug: String, $locale: String) {
  allContentfulKeyValue(filter: {node_locale: {eq:$locale}}) {
    nodes {
      key {
        key
      }
      locale: node_locale
      value {
        value
      }
    }
  },
  allContentfulFaqPage {
    nodes {
      category {
        id
      }
      slug
    }
  }
  contentfulFaqPage(slug: {eq: $slug}, node_locale: {eq: $locale}) {
    locale: node_locale,
    slug,
    title,
    category {
      id
    }
    categoriesCollection {
      categories {
        id
        title
      }
    }
    questionAnswers {
      question {
        question
      }
      answer {
        children {
          internal {
            content
          }
        }
      }
    }
    background {
      ...ImageFragment
    }
    backgroundMobile {
      ...ImageFragment
    }
    footer {
      ...FooterFragment
    }
    seo {
      ...SeoFragment
    }
    header {
      ...HeaderFragment
    }
  }
}
`
