import React, { PropsWithChildren, useContext, useEffect, useLayoutEffect, useState } from 'react';
import { graphql, PageProps } from 'gatsby';
import { Box, Grid, Theme, Typography, useMediaQuery, useTheme } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';

import { GFooter } from '../../interface/graphql/GFooter';
import { GSeo } from '../../interface/graphql/GSeo';
import { GHeader } from '../../interface/graphql/GHeader';
import { getImageUrl } from '../../functions/getImageUrl';
import { App } from '../../components/App';

import { GImage } from '../../interface/graphql/GImage';
import { GSection } from '../../interface/graphql/GSection';
import { PageContext } from '../../layouts/MainLayout';
import { mapKeyValues } from '../../functions/mapKeyValues';
import { NewWhoWeAreContent } from './components/ContentWhoWeAre/NewWhoWeAreContent';
import {  BREAKPOINTS, useForceUpdate, useOnResize, useViewport } from '@cpyou/shared/dist';
import { NewMainBrandLayout } from '../../layouts/NewMainBrandLayout';
import {getPageHeaderBgImageUrl, PageTemplateName} from '../../functions/getPageHeaderBgImageUrl'
import { KeyValuesContext } from '../../components/KeyValuesContext';

export interface IPropNewWhoWeArePageTemplate {
  allContentfulKeyValue: any;
  contentfulWhoWeArePage: {
    title: string;
    locale: string;
    background: GImage;
    backgroundMobile: GImage;
    section: Array<GSection>;
    slug: string;
    header: GHeader;
    footer: GFooter;
    seo: GSeo;
  };
}

const PageContent = (
    props: PropsWithChildren<{
    logo: GImage;
    title: string;
    section: Array<GSection>;
    header: GHeader;
    pageTemplateName: PageTemplateName;
  }>,
) => {
    const {
        logo, title, section, header, pageTemplateName
    } = props;

    const { images } = useContext(PageContext);
    const forceUpdate = useForceUpdate();

    useLayoutEffect(() => {
      // eslint-disable-next-line
      return useOnResize(() => {
          forceUpdate();
      }, BREAKPOINTS, containerRef)
  })

    const [containerRef, ] = useState<Element>();
    const sizeModel = useViewport(BREAKPOINTS, containerRef);

    const backgroundURL = getPageHeaderBgImageUrl(pageTemplateName, sizeModel.breakpointSize, images)

    const titleMarginLeft=  sizeModel.isMobile ? 0 : (sizeModel.columnWidth + sizeModel.gutterWidth)
    const subtitleMarginRight=  6* (sizeModel.columnWidth + sizeModel.gutterWidth) - sizeModel.gutterWidth
    const theme = useTheme()

    const keyValues = useContext(KeyValuesContext)

    return (
        <NewMainBrandLayout
            backgroundUrl={backgroundURL}
            logoUrl={getImageUrl(logo)}
            header={{ mainSlug: header.mainSlug }}
            headerBgShadeColor={theme.palette.primary.main}
            childrenUnderBackground={(
                <div>
                    {section.map((sec, index) => (
                        <NewWhoWeAreContent
                            key={`whoWeAre${sec.title}`}
                            isLastSection={index === section.length -1}
                            section={sec}
                        />
                    ))}
                </div>
            )}
        >
          <Box marginLeft={`${titleMarginLeft}px`}>
            <Typography variant={ 'h1'} >{title}</Typography>
            <Typography variant={ 'h1'} >&mdash;</Typography>
            {pageTemplateName === "whoWeAreTransparency"
            ?
              <Box marginRight={`${subtitleMarginRight}px`}>
                <Typography variant={ 'body1'} >
                  { keyValues.transparencyPageSubtitle }
                </Typography>
             </Box>
            : null
            }

          </Box>
        </NewMainBrandLayout>
    );
};
const NewWhoWeArePageTemplate = (props: PageProps<IPropNewWhoWeArePageTemplate>) => {
    const {
        data: { contentfulWhoWeArePage, allContentfulKeyValue },
        pageContext,
    } = props;
    const {
        seo,
        header,
        footer,
        title,
        section,
        slug,
    } = contentfulWhoWeArePage;
    const keyValues = mapKeyValues(allContentfulKeyValue.nodes);
    const [pageTemplateName, setPageTemplateName] = useState<PageTemplateName>('whoWeAre')

    useEffect(()=> {

      //TODO: To be refactored => a possible way is to introduce a new field in the data model of content pages
      // for pageTemplateName

      switch (slug) {
        case 'about/transparency':
        case 'ueber-uns/transparenz':
        case 'b2b/ueber-die-foundation/transparenz':
        case 'b2b/about-the-foundation/transparency':
          setPageTemplateName('whoWeAreTransparency')
          break;
        default:
          setPageTemplateName('whoWeAre')
          break;
      }
    }, [slug])

    return (
        <App
            pageContext={pageContext as any}
            seoContext={seo}
            header={header}
            footerContext={footer}
            keyValuesContext={keyValues}

        >

              <PageContent
                logo={header.logo}
                title={title}
                section={section}
                header={header}
                pageTemplateName={pageTemplateName}
            />
            </App>

    );
};

export default NewWhoWeArePageTemplate;

export const queryPage = graphql`
  query ContentfulNewWhoWeArePageBySlugAndLocale($slug: String, $locale: String) {
    allContentfulKeyValue(filter: { node_locale: { eq: $locale } }) {
      nodes {
        key {
          key
        }
        locale: node_locale
        value {
          value
        }
      }
    }
    contentfulWhoWeArePage(slug: { eq: $slug }, node_locale: { eq: $locale }) {
      locale: node_locale
      slug
      title
      section {
        title
        text: childContentfulSectionNewTextRichTextNode {
          id
          nodeType
          children {
            id
            internal {
              type
              content
            }
          }
        }
        image {
          ...ImageFragment
        }
      }
      background {
        ...ImageFragment
      }
      backgroundMobile {
        ...ImageFragment
      }
      footer {
        ...FooterFragment
      }
      seo {
        ...SeoFragment
      }
      header {
        ...HeaderFragment
      }
    }
  }
`;
