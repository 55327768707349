import React, { CSSProperties, useContext, useLayoutEffect } from 'react'
import { graphql, Link, PageProps } from 'gatsby';
import { Typography, Box, } from '@material-ui/core';
import { BREAKPOINTS, Margin, useForceUpdate, useOnResize, useViewport } from '@cpyou/shared/dist';
import { GFooter } from '../../interface/graphql/GFooter';
import { GImage } from '../../interface/graphql/GImage';
import { GSeo } from '../../interface/graphql/GSeo';
import { MainLayout, PageContext } from '../../layouts/MainLayout';
import { GHeader } from '../../interface/graphql/GHeader';
import { getImageUrl } from '../../functions/getImageUrl';
import { App } from '../../components/App';
import { GRichText } from '../../interface/graphql/GRichText';
import { mapKeyValues } from '../../functions/mapKeyValues';
import { IPageContext } from '../../interface/PageContext';
import { pageToLink } from '../../functions/pageToLink';
import { getClasses } from './NewNotFoundPageTemplate.jss';
import { LayoutContext } from '../../context/layoutContext';
import Image  from "../../components/image/Image"
import { Key } from '../../components/Key';

export interface IPropsNotFoundPageTemplate {
  allContentfulKeyValue: any;
  contentfulNotFoundPage: {
    locale: string;
    header: GHeader
    background: GImage
    footer: GFooter
    seo: GSeo
    title: GRichText;
    body: GRichText;
    notFoundImage: GImage;
    backJumpButtonText: GRichText;
  }
}

const NewNotFoundPageContent = () => {
    const { language, coBrand, images } = useContext(PageContext) as IPageContext;
    const { containerRef } = useContext(LayoutContext);
    const sizeModel = useViewport(BREAKPOINTS, containerRef);
    const forceUpdate = useForceUpdate();
    const classes = getClasses();

    useLayoutEffect(() => {
      // eslint-disable-next-line
      return useOnResize(() => {
          forceUpdate();
      }, BREAKPOINTS, containerRef)
  })

  const linkToHomePage = (
    <Link
      className={classes.link}
      to={pageToLink(language, coBrand, {
          slug: '/',
      })}
    >
      ←&nbsp;&nbsp;
      {' '}
      <Key>To the homepage</Key>
    </Link>
  )

  const bodyTypography = (
    <Typography
      variant='body1'
    >
      <Key>Crap, something went wrong here. This page does not exist.</Key>
    </Typography>
  )

  interface IBackgroundImage {
    style: CSSProperties
  }

  const BackgroundImage = ({style}: IBackgroundImage) => {
    return (
      <Image
        src={images['blueHairNoSee'].url}
        alt='blueHairNoSee'
        style={style}
      />
    )
  }

    return (
        <Box role="row" display="flex" flex={1} flexWrap="nowrap" flexDirection='row'>
          <Margin viewportEl={containerRef} />
          {sizeModel.isDesktopXXL && (
            <>
              <Box role='content' width={ sizeModel.columnWidth + sizeModel.gutterWidth}/>
              <Box role="row" display='flex' flex={1} flexWrap='nowrap' flexDirection='row' width={6 * sizeModel.columnWidth + 5 * sizeModel.gutterWidth}>
                <Box role='column' display='flex' flexDirection='column' width={3 * sizeModel.columnWidth + 3 * sizeModel.gutterWidth}>
                  <Box role='content' width={2 * sizeModel.columnWidth}>
                    <Typography variant='h1'>404</Typography>
                  </Box>
                  <Box role='seperator' marginBottom='40px' marginTop='40px' height="6px" width={sizeModel.columnWidth / 2} bgcolor="black"/>
                  <Box role='content' display='flex' width={3 * sizeModel.columnWidth + 3 * sizeModel.gutterWidth}>
                    {bodyTypography}
                  </Box>
                  <Box role='link' width={3 * sizeModel.columnWidth}>
                    {linkToHomePage}
                  </Box>
                </Box>
                <Box role='background' display='flex'>
                  <BackgroundImage
                    style={{
                      width: 7 * sizeModel.columnWidth + 6 * sizeModel.gutterWidth,
                      marginTop: '20px'
                    }}
                  />
                </Box>
              </Box>
              <Box role="outerSpace" width={3 * sizeModel.columnWidth + 3 * sizeModel.gutterWidth}/>
            </>
          )}
          {sizeModel.isDesktopXL && (
            <>
              <Box role='content' width={ sizeModel.columnWidth + sizeModel.gutterWidth}/>
              <Box role="row" display='flex' flex={1} flexWrap='nowrap' flexDirection='row' width={10 * sizeModel.columnWidth + 9 * sizeModel.gutterWidth}>
                <Box role='column' display='flex' flexDirection='column' width={4 * sizeModel.columnWidth + 4 * sizeModel.gutterWidth}>
                  <Box role='content' width={1.5 * sizeModel.columnWidth}>
                    <Typography variant='h1'>404</Typography>
                  </Box>
                  <Box role='seperator' marginBottom='40px' marginTop='40px' height="6px" width={ sizeModel.columnWidth / 1.7} bgcolor="black"/>
                  <Box role='content' display='flex' width={4.5 * sizeModel.columnWidth + 4 * sizeModel.gutterWidth}>
                    {bodyTypography}
                  </Box>
                  <Box role='link' width={1.5 * sizeModel.columnWidth + sizeModel.gutterWidth}>
                    {linkToHomePage}
                  </Box>
                </Box>
                <Box role='background' display='flex'>
                  <BackgroundImage
                    style={{
                      width: 7 * sizeModel.columnWidth + 6 * sizeModel.gutterWidth,
                      marginTop: '-75px'
                    }}
                  />
                </Box>
              </Box>
              <Box role="outerSpace" width={sizeModel.columnWidth + sizeModel.gutterWidth}/>
            </>
          )}
          {sizeModel.isDesktop && (
            <>
              <Box role='content' width={ sizeModel.columnWidth + sizeModel.gutterWidth}/>
              <Box role="row" display='flex' flex={1} flexWrap='nowrap' flexDirection='row' width={11 * sizeModel.columnWidth + 10 * sizeModel.gutterWidth}>
                <Box role='column' display='flex' flexDirection='column' width={5 * sizeModel.columnWidth + 5 * sizeModel.gutterWidth}>
                  <Box role='content' width={2 * sizeModel.columnWidth}>
                    <Typography variant='h1'>404</Typography>
                  </Box>
                  <Box role='seperator' marginBottom='40px' marginTop='40px' height="6px" width={ sizeModel.columnWidth } bgcolor="black"/>
                  <Box role='content' display='flex' width={6 * sizeModel.columnWidth + 5 * sizeModel.gutterWidth}>
                    {bodyTypography}
                  </Box>
                  <Box role='link' width={2 * sizeModel.columnWidth + sizeModel.gutterWidth}>
                    {linkToHomePage}
                  </Box>
                </Box>
                <Box role='background' display='flex'>
                  <BackgroundImage
                    style={{
                      width: 8 * sizeModel.columnWidth + 7 * sizeModel.gutterWidth,
                      marginTop: '-40px'
                    }}
                  />
                </Box>
              </Box>
            </>
          )}
          {sizeModel.isTablet && (
            <>
              <Box role='content' width={ sizeModel.columnWidth}/>
              <Box role="row" display='flex' flex={1} flexWrap='nowrap' flexDirection='column' width={11 * sizeModel.columnWidth + 11 * sizeModel.gutterWidth}>
                <Box role='column' display='flex' flexDirection='column' width={10 * sizeModel.columnWidth + 11 * sizeModel.gutterWidth}>
                  <Box role='content' width={sizeModel.columnWidth + 2 * sizeModel.gutterWidth}>
                    <Typography variant='h1'>404</Typography>
                  </Box>
                  <Box role='seperator' marginBottom='24px' marginTop='24px' height="6px" width={ sizeModel.columnWidth + sizeModel.gutterWidth } bgcolor="black"/>
                </Box>
                <Box role="content" width={10 * sizeModel.columnWidth + 11 * sizeModel.gutterWidth} flexDirection="row" display="flex">
                  <Box role="content" flexDirection='column' width={4 * sizeModel.columnWidth + 4 * sizeModel.gutterWidth}>
                    <Box role='content' display='flex' width={5 * sizeModel.columnWidth + 5 * sizeModel.gutterWidth}>
                      {bodyTypography}
                    </Box>
                    <Box role='link' width={3 * sizeModel.columnWidth + 3 * sizeModel.gutterWidth}>
                      {linkToHomePage}
                    </Box>
                  </Box>
                  <Box role='background' display='flex'>
                    <BackgroundImage
                      style={{
                        width: 9 * sizeModel.columnWidth + 8 * sizeModel.gutterWidth,
                      }}
                    />
                  </Box>
                </Box>
              </Box>
            </>
          )}
          {sizeModel.isMobile && (
            <>
              <Box role="row" display='flex' flex={1} flexWrap='nowrap' flexDirection='column' width={12 * sizeModel.columnWidth + 12 * sizeModel.gutterWidth}>
                <Box role='column' display='flex' flexDirection='column' width={12 * sizeModel.columnWidth + 12 * sizeModel.gutterWidth}>
                  <Box role='content' width={3 * sizeModel.columnWidth + 2 * sizeModel.gutterWidth}>
                    <Typography variant='h1'>404</Typography>
                  </Box>
                  <Box role='seperator' marginBottom='24px' marginTop='24px' height="6px" width={ 2 * sizeModel.columnWidth + 2 * sizeModel.gutterWidth} bgcolor="black"/>
                  <Box role='content' display='flex' width={10 * sizeModel.columnWidth + 9 * sizeModel.gutterWidth}>
                    {bodyTypography}
                  </Box>
                  <Box role='link' width={6 * sizeModel.columnWidth + 5 * sizeModel.gutterWidth}>
                    {linkToHomePage}
                  </Box>
                </Box>
                <Box display='flex' flexDirection='row'>
                  <Box role='outerSpace' width={2 * sizeModel.columnWidth + sizeModel.gutterWidth}/>
                  <Box role='background' display='flex'>
                    <BackgroundImage
                      style={{
                        width: 11 * sizeModel.columnWidth + 12 * sizeModel.gutterWidth,
                      }}
                    />
                  </Box>
                </Box>
              </Box>
            </>
          )}
          {sizeModel.isMobile || sizeModel.isTablet ? '' : (
            <Margin viewportEl={containerRef} />
          )}
        </Box>
    )
}

const NewNotFoundPageTemplate = (props: PageProps<IPropsNotFoundPageTemplate>) => {

    const { data: { contentfulNotFoundPage, allContentfulKeyValue }, pageContext } = props;
    const {
        seo, header, footer
    } = contentfulNotFoundPage;

    const keyValues = mapKeyValues(allContentfulKeyValue.nodes);

    return (
        <App
            pageContext={pageContext as any}
            seoContext={seo}
            header={header}
            keyValuesContext={keyValues}
            footerContext={footer}
        >
            <MainLayout
                header={{ mainSlug: header.mainSlug }}
                logoUrl={getImageUrl(header.logo)}
                bgShadow
                bgShadeColor='#F2F9F7'
            >
                <NewNotFoundPageContent />
            </MainLayout>
        </App>
    )
}

export default NewNotFoundPageTemplate

export const queryPage = graphql`
query ContentfulNewNotFoundPageByLocale($locale: String, $slug: String) {
  allContentfulKeyValue(filter: {node_locale: {eq:$locale}}) {
    nodes {
      key {
        key
      }
      locale: node_locale
      value {
        value
      }
    }
  },
  contentfulNotFoundPage(node_locale: {eq: $locale}, slug: {eq: $slug}) {
    locale: node_locale
    title: childContentfulNotFoundPageTitleRichTextNode {
      childContentfulRichText {
        html
      }
    }
    backJumpButtonText
    notFoundImage {
      ...ImageFragment
    }
    body: childContentfulNotFoundPageContextRichTextNode {
      childContentfulRichText {
        html
      }
    }
    background {
      ...ImageFragment
    }
    footer {
      ...FooterFragment
    }
    seo {
      ...SeoFragment
    }
    header {
      ...HeaderFragment
    }
  }
}
`
