import React, {
    PropsWithChildren,
    useContext,
    useLayoutEffect,
    useState,
  } from 'react';

import { graphql, PageProps } from 'gatsby';

import { BREAKPOINTS, useForceUpdate, useOnResize, useTwoPassRender } from '@cpyou/shared/dist';
import { headerHeights } from './homepageComponents/HomePageHeader';
import { HomePageMainBrandLayout } from '../../layouts/HomePageMainBrandLayout';
import { GFooter } from '../../interface/graphql/GFooter';
import { GSeo } from '../../interface/graphql/GSeo';
import { GHeader } from '../../interface/graphql/GHeader';
import { getImageUrl } from '../../functions/getImageUrl';
import { App } from '../../components/App';
import { GImage } from '../../interface/graphql/GImage';
import { mapKeyValues } from '../../functions/mapKeyValues';
import { GRichTextForRenderer } from '../../interface/graphql/GRichTextForRenderer';
import { useTheme} from "@material-ui/core";

import { GBlogPost } from '../../interface/graphql/GBlogPost';
import {
    BlogPosts,
} from './homepageComponents/NewContentBlogPostsSelector/NewHomePageContentBlogPostsSelector';
import { GLink } from '../../interface/graphql/GLink';

import { KeyValuesContext } from '../../components/KeyValuesContext';
import { HomePageHeader } from './homepageComponents/HomePageHeader';
import { NewHomePageContent } from './homepageComponents/NewHomePageContent/NewHomePageContent';
import { GKeyValue } from '../../interface/graphql/GKeyValue';
import { Project, Projects } from './homepageComponents/NewContentProjectSelector/NewHomePageContentProjectSelector';
import { GClimateOffsetProject } from '../../interface/graphql/GClimateOffsetProject';


export type OffsetProjects = Array<GClimateOffsetProject>;
export interface IPropNewHomePageTemplate {
  allContentfulKeyValue: any;
  contentfulHomePage: {
    title: string;
    subTitle: string;
    locale: string;
    slug: string;
    header: GHeader;
    footer: GFooter;
    seo: GSeo;
    projectsHeadline: GRichTextForRenderer;
    projects: Array<Project>;
    blogPostsHeadline: GRichTextForRenderer;
    viewAllBlogPostsLink: GLink;
    ctaLink: GLink;
    conlyLink: GLink;
    fonlyLink: GLink;
    offsetAmountLink: GLink;
    aboutPageLink: GLink;

  };
  allContentfulBlogPost: {
    nodes: Array<GBlogPost>;
  };
}
export type IBulletPointContext = [{ [index: number]: number }, () => void];

const PageContent = (
    props: PropsWithChildren<{
    logo: GImage;
    title: string;
    subTitle: string;
    header: GHeader;
    projects: Projects;
    blogPosts: BlogPosts;
    ctaLink: GLink;
    conlyLink: GLink;
    fonlyLink: GLink;
    offsetAmountLink: GLink;
    aboutPageLink: GLink;
  }>,
) => {
    const {
        logo,
        header,
        title,
        subTitle,
        blogPosts,
        projects,
        ctaLink,
        conlyLink,
        fonlyLink,
        aboutPageLink,
        offsetAmountLink
    } = props;

    const theme = useTheme()
    const keyValues: GKeyValue = useContext(KeyValuesContext);
    const [containerRef, ] = useState<Element>();
    const forceUpdate = useForceUpdate();
    const hasBeenTwoPassRendered = useTwoPassRender();

    useLayoutEffect(() => {

      // eslint-disable-next-line
      return useOnResize(() => {
          forceUpdate();
      }, BREAKPOINTS, containerRef)
    })

    if (!hasBeenTwoPassRendered) {
        return null;
    }
    return (
        <HomePageMainBrandLayout
            backgroundUrl={undefined}
            logoUrl={getImageUrl(logo)}
            header={{ mainSlug: header.mainSlug }}
            headerBgShadeColor={theme.palette.primary.main}
            headerHeights={headerHeights}
            childrenUnderBackground={(
                <>
                  <NewHomePageContent
                    blogPosts={blogPosts}
                    projects={projects}
                    conlyLink={conlyLink}
                    fonlyLink={fonlyLink}
                    aboutLink={aboutPageLink}
                    offsetAmountLink={offsetAmountLink}
                  />
                </>
            )}
            specialHomePageHeader={
              <HomePageHeader
                title={title}
                highlightedTitle={keyValues.homePageHighLitghtedTitle}
                subTitle={subTitle}
                ctaLink={ctaLink}
              />
            }
          />
    );
};
const NewHomePageTemplate = (props: PageProps<IPropNewHomePageTemplate>) => {
    const {
        data: { contentfulHomePage, allContentfulKeyValue, allContentfulBlogPost },
        pageContext,
    } = props;
    const {
        seo,
        header,
        footer,
        title,
        subTitle,
        projects,
        projectsHeadline,
        blogPostsHeadline,
        viewAllBlogPostsLink,
        ctaLink,
        conlyLink,
        fonlyLink,
        aboutPageLink,
        offsetAmountLink
    } = contentfulHomePage;
    const { nodes: blogPosts } = allContentfulBlogPost;

    const keyValues = mapKeyValues(allContentfulKeyValue.nodes);

    const projectsMapped = {
        headline: projectsHeadline,
        children: projects,
    };

    const blogPostsMapped: BlogPosts = {
        headline: blogPostsHeadline,
        children: blogPosts,
        viewAllBlogPostsLink,
    };

    return (
        <App
            pageContext={pageContext as any}
            seoContext={seo}
            header={header}
            footerContext={footer}
            keyValuesContext={keyValues}
        >
            <PageContent
              ctaLink={ctaLink}
              conlyLink={conlyLink}
              fonlyLink={fonlyLink}
              aboutPageLink={aboutPageLink}
              offsetAmountLink={offsetAmountLink}
              logo={header.logo}
              title={title}
              subTitle={subTitle}
              header={header}
              projects={projectsMapped}
              blogPosts={blogPostsMapped}
            />
        </App>
    );
};

export default NewHomePageTemplate;

export const queryPage = graphql`
  query ContentfulNewHomePageBySlugAndLocale($locale: String, $featureFlagsQuery: StringQueryOperatorInput) {
    allContentfulKeyValue(filter: { node_locale: { eq: $locale } }) {
      nodes {
        key {
          key
        }
        locale: node_locale
        value {
          value
        }
      }
    }
    contentfulHomePage(node_locale: { eq: $locale }, featureFlags: $featureFlagsQuery) {
      name
      locale: node_locale
      slug
      title
      subTitle
      ctaLink {
        ...LinkFragment
      }
      ctaLink {
        ...LinkFragment
      }
      conlyLink {
        ...LinkFragment
      }
      fonlyLink {
        ...LinkFragment
      }
      offsetAmountLink {
        ...LinkFragment
      }
      aboutPageLink {
        ...LinkFragment
      }
      projectsHeadline {
        children {
          id
          internal {
            content
          }
        }
      }
      projects {
        projectName
        heroImage {
          ...ImageFragment
        }
        heroCertificateImage {
          ...ImageFragment
        }
        places {
          title
          url
        }
        calculatorPage {
          slug
        }
        howItCompensates {
          childContentfulRichText {
            html
          }
        }
        projectPage{
          ...LinkFragment
        }
      }
      blogPostsHeadline {
        children {
          id
          internal {
            content
          }
        }
      }
      viewAllBlogPostsLink {
        ...LinkFragment
      }
      footer {
        ...FooterFragment
      }
      seo {
        ...SeoFragment
      }
      header {
        ...HeaderFragment
      }
      featureFlags
    }
    allContentfulBlogPost(filter: { node_locale: { eq: $locale } }) {
      nodes {
        id
        title
        rawDate: date
        date(formatString: "DD. MMMM YYYY", locale: $locale)
        category
        blogpostpage {
          slug
        }
        teaserText {
          internal {
            content
          }
        }
        shortTeaserForHomepage
        shortTitleForHomepage
        mainImage {
          ...ImageFragment
        }
        listImage {
          ...ImageFragment
        }
        body {
          children {
            id
            internal {
              content
            }
          }
        }
      }
    }
  }
`;
