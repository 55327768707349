import { makeStyles } from '@material-ui/styles';
import { Theme } from '@material-ui/core';
import { FeatureFlags, IFeatureFlags } from '@cpyou/shared/dist';

export const getClasses = (featureFlags: IFeatureFlags) => makeStyles(({ breakpoints }: Theme) => ({
    background: {
        minHeight: '50vh !important',
    },
    title: {
        color: featureFlags[FeatureFlags.NEW_BRAND_DESIGN] ? '#000' : 'white',
        textAlign: 'left',
        fontSize: 66,
        fontWeight: 'bold',
        lineHeight: '67px',
        paddingTop: '10vh',
        [breakpoints.down('sm')]: {
            fontSize: 40,
            lineHeight: '52px',
        },
    },
    titleContainer: {
        minHeight: '40vh',
    },
}));
