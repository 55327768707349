import { IFeatureFlags, getColorPalette } from '@cpyou/shared/dist';
import { createMuiTheme, darken, lighten, Theme } from '@material-ui/core/styles';
import { Typography } from '@material-ui/core/styles/createTypography';
import { ICoBrand } from '@cpyou/shared/dist/interface/ICoBrand';
import { FeatureFlags, getTypography, getBreakpoints, lato, proximaNovaRegular, proximaNovaBold, proximaNovaBoldItalic, proximaNovaBlack, proximaNovaItalic, proximaNovaLight, proximaNovaLightItalic, proximaNovaMedium } from '@cpyou/shared/dist';

export interface CustomTypographyTheme extends Theme {
    typography: Typography & {
        fontFamilyLinks?: Array<string>
    }
}

const muiTheme = createMuiTheme();

export const ciTheme: (featureFlags: IFeatureFlags, coBrand: ICoBrand) => CustomTypographyTheme = (featureFlags: IFeatureFlags, coBrand: ICoBrand) => createMuiTheme({
    palette: {
        primary: {
            main: getColorPalette(featureFlags, coBrand, lighten, darken).primary.fullColor,
            contrastText: '#fff',
        },
        secondary: {
            main: getColorPalette(featureFlags, coBrand, lighten, darken).secondary.fullColor,
            contrastText: '#fff',
        },
        background: {
            default: getColorPalette(featureFlags, coBrand, lighten, darken).backgroundColor
        },
    },
    typography: getTypography(featureFlags, coBrand),
    breakpoints: getBreakpoints(featureFlags).breakpoints,
    overrides: {
        MuiCssBaseline: {
            '@global': {
                html: {

                    // we never want to exceed horizontally
                    // necessary on Safari: https://climatepartner.atlassian.net/browse/YOU-2071
                    overflowX: 'hidden'
                },
                body: {
                    // we never want to exceed horizontally
                    overflowX: 'hidden'
                },
                '@font-face': featureFlags[FeatureFlags.NEW_BRAND_DESIGN] ?
                    [proximaNovaLightItalic, proximaNovaLight, proximaNovaItalic, proximaNovaRegular, proximaNovaMedium, proximaNovaBold, proximaNovaBoldItalic, proximaNovaBlack]
                    : [lato],
                a: {
                    color: getColorPalette(featureFlags, coBrand, lighten, darken).primary.fullColor,
                },
                // TODO: can be removed after switch to newBrandDesign!
                'video::-webkit-media-controls, video::-moz-media-controls, video::-o-media-controls, video::-ms-media-controls': {
                    display: 'none !important',
                },
                'video::-webkit-media-controls-start-playback-button': {
                    display: 'none !important',
                },
            },
        },
        MuiTableHead: {
            root: {
                backgroundColor: muiTheme.palette.action.hover,
            },
        },
        MuiDialogTitle: {
            root: {
                padding: muiTheme.spacing(1.5, 3),
            },
        },
        MuiDialogContent: {
            dividers: {
                padding: muiTheme.spacing(4, 3, 1, 3),
            },
        },
        MuiDialogActions: {
            root: {
                padding: muiTheme.spacing(3),
            },
        },
        MuiFormHelperText: {
            root: {
                marginTop: muiTheme.spacing(0.5),
                marginBottom: muiTheme.spacing(1),
                lineHeight: muiTheme.typography.caption.fontSize,
            },
        },
        MuiButton: {
            root: {
                fontWeight: 'bold',
                paddingBottom: 10,
                paddingTop: 10,
                textTransform: 'unset'
            },
        },
    },
} as unknown as CustomTypographyTheme) as CustomTypographyTheme;

if (process.env.NODE_ENV !== 'production') {
    console.groupCollapsed('Theme');
    console.info(muiTheme);
    console.groupEnd();
}
