import React, { useContext, useLayoutEffect } from "react";
import { useViewport, BREAKPOINTS, useForceUpdate, useOnResize } from "@cpyou/shared/dist";
import { getClasses } from "./NewHomePageContentAbout.jss";
import { LayoutContext } from "../../../../context/layoutContext";
import { Box, Typography } from "@material-ui/core";
import { IPageContext } from "../../../../interface/PageContext";
import { PageContext } from "../../../../layouts/MainLayout";
import { KeyValuesContext } from "../../../../components/KeyValuesContext";
import { GLink } from "../../../../interface/graphql/GLink";
import { Link } from "gatsby"
import { pageToLink } from "../../../../functions/pageToLink";

interface IPropsHomePageContentAbout {
  link: GLink,
}

export const NewHomePageContentAbout = ({
  link,
}: IPropsHomePageContentAbout) => {

    const classes = getClasses();
    const keyValues: any = useContext(KeyValuesContext);
    const { language, coBrand } = useContext(PageContext) as IPageContext;

    const { containerRef } = useContext(LayoutContext);
    const sizeModel = useViewport(BREAKPOINTS, containerRef);
    const { images } = useContext(PageContext);
    const forceUpdate = useForceUpdate();

    useLayoutEffect(() => {

      // eslint-disable-next-line
      return useOnResize(() => {
          forceUpdate();
      }, BREAKPOINTS, containerRef)
    })
    const aboutImage =
      sizeModel.isMobile
        ? images.homePageAboutMobile
        : sizeModel.isTablet
          ? images.homePageAboutTablet
          : images.homePageAboutDesktop

    const paddingRight =
      sizeModel.isDesktopXXL
        ? sizeModel.gutterWidth + sizeModel.marginWidth + sizeModel.columnWidth
        : sizeModel.marginWidth


    const imageMarginRight =
      sizeModel.isDesktopXXL
        ? -(sizeModel.gutterWidth + sizeModel.marginWidth + sizeModel.columnWidth)
        : -sizeModel.marginWidth

    const imageMarginLeft =
      sizeModel.isMobile || sizeModel.isTablet
        ? -sizeModel.marginWidth
        : undefined

    const paddingLeft =
      sizeModel.isDesktopXXL
        ? sizeModel.gutterWidth + sizeModel.marginWidth + sizeModel.columnWidth
        : sizeModel.marginWidth
    return (
      <Box className={classes.contentAbout} paddingRight={`${paddingRight}px`} paddingLeft={`${paddingLeft}px`}>
        <Box className={classes.textContent} paddingRight={`${sizeModel.columnWidth}px`}>
            <Typography variant='h2' className={classes.title}>{keyValues.homePageAboutTitle}</Typography>
            <Typography variant='body1' className={classes.teaser}>{keyValues.homePageAboutTeaser}</Typography>
            <Link
              to={(pageToLink(
              language,
              coBrand,
              {slug: link.page?.slug}))}>
                <Typography variant='body1'>{keyValues.toWhoWeArePage}</Typography>
            </Link>
        </Box>
        {/*
            TODO: Replace maybe the image in the future
        */}
        {/* <img
          src={aboutImage.url}
          className={classes.image}
          style={{
            marginLeft: imageMarginLeft,
            marginRight: imageMarginRight}}>
        </img> */}
      </Box>
    );
};
