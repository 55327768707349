import React, { useEffect, ReactElement } from 'react';

import CssBaseline from '@material-ui/core/CssBaseline';
import { ThemeProvider } from '@material-ui/core/styles';

import { ciTheme } from './ciTheme';
import { ICoBrand, IFeatureFlags } from '@cpyou/shared/dist';

export const CIThemeProvider = ({ children, featureFlags, coBrand }: IThemeProps) => {
    useEffect(() => {
        const { fontFamilyLinks } = ciTheme(featureFlags, coBrand).typography;
        if (Array.isArray(fontFamilyLinks) && fontFamilyLinks.length > 0) {
            fontFamilyLinks.forEach((fontFamilyLink) => {
                const link = document.createElement('link');

                link.rel = 'stylesheet';
                link.href = fontFamilyLink;

                document.head.appendChild(link);
            });
        }
    }, [featureFlags, coBrand]);

    return (
        <ThemeProvider theme={ciTheme(featureFlags, coBrand)}>
            <CssBaseline />

            {children}
        </ThemeProvider>
    );
}

export interface IThemeProps {
    featureFlags: IFeatureFlags,
    children: ReactElement,
    coBrand: ICoBrand,
}
