"use strict";
exports.__esModule = true;
exports.formatNumber = void 0;
var formatNumber = function (language, number, maximumFractionDigits, minimumFractionDigits) {
    if (maximumFractionDigits === void 0) { maximumFractionDigits = 2; }
    if (minimumFractionDigits === void 0) { minimumFractionDigits = 2; }
    return (typeof number === 'undefined' ? 0 : number).toLocaleString(language.toLowerCase(), {
        maximumFractionDigits: maximumFractionDigits,
        minimumFractionDigits: minimumFractionDigits
    });
};
exports.formatNumber = formatNumber;
