export const getLanguageAgnosticPathname = (coBrand?) => {
    const path = decodeURIComponent(document.location.pathname).split('/');

    // drop the /$coBrandSlug/ first
    if (coBrand) {
        path.shift();
    }

    // drop the language of the path, e.g. /en/
    path.shift(); path.shift();

    return path.join('/')
}
