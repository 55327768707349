import React, { memo, PropsWithChildren, ReactNode, useContext, useState, useLayoutEffect } from 'react';
import Seo from '../components/Seo';
import { Header } from '../components/Header';
import { Footer } from '../components/Footer';
import { getClasses as defaultGetClasses } from './DefaultMainBrandLayout.jss';
import { joinClasses } from '../functions/join';
import { FeatureFlagsContext } from '../components/App';
import { FeatureFlags, IFeatureFlags, BREAKPOINTS, YOULayout, useViewport, useOnResize, useForceUpdate } from '@cpyou/shared/dist';
import { LayoutContext } from '../context/layoutContext';
import { defaultHeaderHeights, HeaderHeights } from './headerHeights';

export interface IPropsNewMainBrandLayout {
  getClasses?: Function;
  backgroundUrl?: string;
  classes?: { background?: string; backgroundRect?: string };
  logoUrl?: string;
  childrenUnderBackground?: ReactNode;
  header: {
    show?: boolean;
    mainSlug: string;
  };
  bgShadow?: boolean;
  bgShadeColor?: false | string;
  headerBgShadeColor?: string;
  positionBackground?:boolean;
  headerHeights?: HeaderHeights;
}

export const MAIN_LAYOUT_ID = 'main-layout';
export const MAIN_LAYOUT_HEADER_ID = 'main-layout-header';
export const NewMainBrandLayout = memo((props: PropsWithChildren<IPropsNewMainBrandLayout>) => {

    const [containerRef, setContainerRef] = useState<Element>();
    const {
        backgroundUrl,
        children,
        logoUrl,
        classes,
        childrenUnderBackground,
        header: { show, mainSlug },
        bgShadow,
        bgShadeColor,
        headerBgShadeColor,
        positionBackground,
        getClasses = defaultGetClasses,
        headerHeights = defaultHeaderHeights,
    } = props;

    const [headerHeight, setHeaderHeight] = useState(360);
    const sizeModel = useViewport(BREAKPOINTS, containerRef);
    const forceUpdate = useForceUpdate();

    useLayoutEffect(() => {
        // eslint-disable-next-line
        return useOnResize(() => {
            forceUpdate();
        }, BREAKPOINTS, containerRef)
    })

    useLayoutEffect(() => {
      setHeaderHeight(headerHeights[sizeModel.breakpointSize])
    })

    const ownClasses = getClasses(sizeModel, backgroundUrl, positionBackground, headerHeight, headerBgShadeColor ? headerBgShadeColor : 'transparent');
    const featureFlags = useContext(FeatureFlagsContext) as IFeatureFlags;
    const [newBrandDesignEnabled] = useState(featureFlags[FeatureFlags.NEW_BRAND_DESIGN]);


    return (
      <LayoutContext.Provider value={{ containerRef }}>
          <Seo />
          <div
              ref={ref => setContainerRef(ref)}
              id={MAIN_LAYOUT_ID}
              className={joinClasses([ownClasses.background, classes?.background])}
          >
              {headerBgShadeColor && <div className={ownClasses.headerBgContainer} />}

              <div
                  className={joinClasses([
                      ownClasses.backgroundRect,
                      classes?.backgroundRect,
                  ])}
                  style={{
                      background: bgShadow === false ? 'transparent' : bgShadeColor || 'transparent',
                  }}
              >
                {(typeof show === 'undefined' || show) && (
                    <div className={newBrandDesignEnabled && ownClasses.header} id={MAIN_LAYOUT_HEADER_ID}>
                        {/* defines the menu only (logo, menu entries, mobile drawer) */}
                        <Header logoUrl={logoUrl} mainSlug={mainSlug} containerRef={containerRef} />
                    </div>
                )}

                <YOULayout viewportEl={containerRef} noMargin={false} marginBottom={"50px"}>
                  {children}
                </YOULayout>
              </div>
          </div>
          {childrenUnderBackground}
          <Footer />
      </LayoutContext.Provider>
  );

});
