import { Link } from "gatsby";
import React, { EventHandler, SyntheticEvent, useContext } from "react";
import { IPageContext } from "../interface/PageContext";
import { PageContext } from "../layouts/MainLayout";
import { ArrowLeftIcon } from "./icon/ArrowLeftIcon";
import { getClasses } from "./BackTo.jss";
import { joinClasses } from "../functions/join";

export interface BackToProps {
  href?: string,
  text: string,
  className?: string,
  onClick?: EventHandler<SyntheticEvent>
}

export const BackTo = ({ href, text, onClick, className }: BackToProps) => {
  const { coBrand } = useContext(PageContext) as IPageContext;
  const classes = getClasses();
  return (
    <Link to={href} onClick={onClick} className={joinClasses(classes.backLink, className)}>
        <ArrowLeftIcon color={coBrand ? coBrand.primaryColor : undefined} style={{height: 10, width: 15}} />
        &nbsp;
        {text}
    </Link>
  )
}
