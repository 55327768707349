import { IContentfulMediaMap } from "../interface/IContentfulMediaMap"

const ucfirst = (text: string) => `${text[0].toUpperCase()}${text.substring(1)}`

// IMPORTANT NOTICE: image names in Contentful *must* correlate 1:1 with the names constructed here
export type PageTemplateName =
  'whoWeAre' |
  'whoWeAreTransparency' |
  'faq' |
  'home'

export const getPageHeaderBgImageUrl = (
  pageTemplateName: PageTemplateName, breakpointName: string, images: IContentfulMediaMap
) => {
  // e.g. faqPage                      DesktopXxl
  return images[`${pageTemplateName}PageHeader${ucfirst(breakpointName)}`]?.url
}
