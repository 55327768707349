import { getColorPalette, ICoBrand, IFeatureFlags, ISizeModel } from "@cpyou/shared/dist"
import { darken, lighten, Theme } from "@material-ui/core"
import {makeStyles} from "@material-ui/styles"

export const getClasses = (featureFlags: IFeatureFlags, coBrand: ICoBrand, size: ISizeModel) => makeStyles((theme: Theme)=> {

  let locationIconStyle: any = {
    float: 'left'
  }
  if (!(size.isTablet || size.isMobile)) {
    locationIconStyle = {
      ...locationIconStyle,
      top: 6,
      position: 'relative'
    }
  }

  return ({
    root: {
    },
    descriptionRoot: {
      marginTop: size.columnWidth
    },
    backLink: {
      display: 'inline-block',
      fontSize: 14,
      marginTop: size.isDesktopXL ? 64 : size.isDesktopXXL ? 164 : size.isDesktop ? 64 : 0, /* mobile and tablet */
      marginBottom: (size.isDesktopXXL) ? 40 : size.isDesktop ? 32 : 24
    },
    location: {
        color: getColorPalette(featureFlags, coBrand, lighten, darken).grey,
        marginTop: 10,
        marginBottom: size.isMobile ? 60 : size.isTablet ?  80 : 0,
        display: 'flex',
        flexDirection: 'column',
        "& svg": {
          ...locationIconStyle
        }
    },
    locationEntry: {
      display: 'block',
      marginTop: 12 /* 16 - 4 */,
      clear: 'right'
    },
    locationName: {
      float: 'left',
      marginLeft: 8,
    },
    locationSpanMobile: {
        margin: '0 0 14px 0',
    },
    hrefStyle: {
        color: 'unset',
        textDecoration: 'none',
    },
    sdgRoot: {
      marginTop: 80,
      backgroundColor: getColorPalette(featureFlags, coBrand, lighten, darken).primary.tint90Percent,
      display: 'flex',
      flexDirection: 'column',
      paddingBottom: 64, // Math.abs(-48) / 2
    },
    sdgImage: {
      display: 'flex',
      width: 112,
      height: 62,
      margin: 'auto',
      marginTop: -33,
    },
    sdgHeader: {
      textAlign: 'center',
      fontSize: 28,
      marginTop: 28,
      marginBottom: 28,
    },
    sdgAvatarRoot: {
      display: 'flex',
      flexDirection: 'row',
      justifyContent: size.isMobile ? 'center': 'space-evenly',
      flexWrap: 'wrap'
    },
    sdgAvatarContainer: {
      display: 'flex',
      maxWidth: 250,
      flex: size.isMobile ? '100%' : size.isTablet ? '50%' : 1,
      flexDirection: 'column',
      marginTop: 64,
    },
    sdgAvatar: {
      display: 'flex',
      alignSelf: 'center',
      flexDirection: 'row',
          borderRadius: 100,
    },
    sdgAvatarImage: {
        height: 100,
        width: 100,
        borderRadius: 100,
        "& img": {
          width: 70,
          height: 70,
        }
    },
    // TODO: define all other colors for full
    // flexible COP / SDG support
    sdg3: {
        backgroundColor: '#279B48',
    },
    sdg4: {
        backgroundColor: '#C31F33',
    },
    sdg5: {
        backgroundColor: '#EF402B',
    },
    sdg6: {
        backgroundColor: '#00AED9',
    },
    sdg7: {
        backgroundColor: '#FDB713',
    },
    sdg12: {
        backgroundColor: '#CF8D2A',
    },
    sdg13: {
      backgroundColor: '#497635',
    },
    sdg15: {
        backgroundColor: '#3EB049',
    },
    sdgAvatarsText: {
        textAlign: 'center',
        marginTop: 15,
    },
    qualityBadgeProjectDeveloperImage: {
      height: (size.isMobile || size.isTablet) ? 58 : 70
    },
    badgeContainer: {
      marginTop: 30,
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center'
      // justifyContent: 'space-between',
      // flex: size.isMobile ? '100%' : (size.isTablet || size.isDesktop || size.isDesktopXL) ? '50%' : 1,
      // flexWrap: (size.isTablet || size.isDesktop || size.isDesktopXL) ? 'wrap' : 'nowrap',
    },
    badgeContainerTitles: {
      display: 'flex',
      flexDirection: (size.isMobile || size.isTablet) ? 'column' : 'row',
      justifyContent: 'space-between'
    },
    badgeBox: {
      marginTop: 50,
      textAlign: 'center',
      width: size.isDesktopXL || size.isDesktop ? 3 * size.columnWidth + 2 * size.gutterWidth :
        size.isTablet ? 4 * size.columnWidth + 3 * size.gutterWidth : 'auto',
    },
    badgeHead: {
      display: 'block',
      paddingBottom: 10,
      minHeight: size.isDesktopXXL ? 85 : 40,
    },
    projectSelectButtonContainer: {
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      marginTop: 60,
    },
    footerBackLink: {
      marginTop: '35px !important'
    },
    selectButton: {
      backgroundColor: '#f64b54 !important',
      '&:hover': {
        backgroundColor: 'rgba(246, 75, 84, 0.7) !important'
      },
      width:
        size.isMobile
          ? "100%"
          : "300px"
    }
  })

})
