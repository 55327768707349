import React, { PropsWithChildren, useContext } from 'react'
import { useMount, useSessionStorage } from 'react-use';
import { graphql, PageProps } from 'gatsby';
import { Grid, Typography, useTheme } from '@material-ui/core';

import { getClasses } from './StaticPageTemplate.jss';
import { GFooter } from '../../interface/graphql/GFooter';
import { GSeo } from '../../interface/graphql/GSeo';
import { MainLayout } from '../../layouts/MainLayout';
import { GHeader } from '../../interface/graphql/GHeader';
import { getImageUrl } from '../../functions/getImageUrl';
import { App } from '../../components/App';
import { GRichText } from '../../interface/graphql/GRichText';
import { GRichTextForRenderer } from '../../interface/graphql/GRichTextForRenderer';
import { SessionStorageKeys } from '../../interface/SessionStorageKeys';
import { BusinessType } from '../../interface/BusinessType';
import { detectBusinessType } from '../../functions/detectBusinessType';
import { mapKeyValues } from '../../functions/mapKeyValues';
import { RichTextRenderer } from '../../components/contentful/RichtTextRenderer';

export interface IPropStaticPageTemplate {
  allContentfulKeyValue: any;
  contentfulStaticPage: {
    title: string;
    locale: string;
    slug: string;
    header: GHeader
    footer: GFooter
    seo: GSeo;
    content: GRichText;
    htmlOne?: { htmlOne: string };
    contentForRenderer: GRichTextForRenderer
  }
}

const PageContent = ({ children }: PropsWithChildren<any>) => (
    <Grid
        container
        justify="center"
        style={{
            paddingTop: 60, paddingBottom: 60, paddingLeft: 20, paddingRight: 14,
            fontSize: 'initial', fontWeight: 'normal'
        }}
    >
        <Grid
            item
            xs={12}
            sm={10}
            md={8}
            lg={6}
            style={{
                display: 'block',
            }}
        >
            {children}
        </Grid>
    </Grid>
)

const StaticPageTemplate = (props: PageProps<IPropStaticPageTemplate>) => {
    const { data: { contentfulStaticPage, allContentfulKeyValue }, pageContext } = props;
    const {
        seo, header, footer, title, contentForRenderer, htmlOne
    } = contentfulStaticPage;

    const [, setBusinessType] = useSessionStorage(SessionStorageKeys.BUSINESS_TYPE, BusinessType.B2C);
    const classes = getClasses();
    const theme = useTheme()


    useMount(() => {
        setBusinessType(detectBusinessType());
    });

    const keyValues = mapKeyValues(allContentfulKeyValue.nodes);

    return (
        <App
            pageContext={pageContext as any}
            seoContext={seo}
            header={header}
            footerContext={footer}
            keyValuesContext={keyValues}
        >
            <MainLayout
                header={{ mainSlug: header.mainSlug }}
                logoUrl={getImageUrl(header.logo)}
            >
                <PageContent>
                    <Typography
                        variant="h1"
                        style={{
                            fontSize: '45px',
                            overflow: 'hidden',
                            whiteSpace: 'nowrap',
                            textOverflow: 'ellipsis',
                        }}
                    >
                        {title}
                    </Typography>
                    {htmlOne &&
                      <div dangerouslySetInnerHTML={{ __html: htmlOne.htmlOne }} />
                    }
                    <RichTextRenderer embeddedAssetImageClass={classes.imageClass} linkClass={classes.linkClass}>
                        {contentForRenderer}
                    </RichTextRenderer>
                </PageContent>
            </MainLayout>
        </App>
    )
}

export default StaticPageTemplate

export const queryPage = graphql`
query ContentfulStaticPageBySlugAndLocale($slug: String, $locale: String) {
  allContentfulKeyValue(filter: {node_locale: {eq:$locale}}) {
    nodes {
      key {
        key
      }
      locale: node_locale
      value {
        value
      }
    }
  },
  contentfulStaticPage(slug: {eq: $slug}, node_locale: {eq: $locale}) {
    locale: node_locale,
    slug,
    title,
    contentForRenderer: childContentfulStaticPageContentRichTextNode {
      id
      nodeType
      children {
        id
        internal {
          type
          content
        }
      }
    },
    content: childContentfulStaticPageContentRichTextNode{
      childContentfulRichText {
        html
      }
    }
    htmlOne {
      htmlOne
    }
    footer {
      ...FooterFragment
    }
    seo {
      ...SeoFragment
    }
    header {
      ...HeaderFragment
    }
  }
}
`
