import React,
{ memo, PropsWithChildren, ReactNode, useContext, useState, useLayoutEffect } from 'react';
import Seo from '../components/Seo';
import { Header } from '../components/Header';
import { Footer } from '../components/Footer';
import { getClasses} from './HomePageMainBrandLayout.jss';
import { FeatureFlagsContext } from '../components/App';
import { FeatureFlags, IFeatureFlags, BREAKPOINTS, useViewport, useOnResize, useForceUpdate } from '@cpyou/shared/dist';
import { LayoutContext } from '../context/layoutContext';
import { defaultHeaderHeights, HeaderHeights } from './headerHeights';

export interface HomePageLayoutProps {
  backgroundUrl?: string;
  logoUrl?: string;
  childrenUnderBackground?: ReactNode;
  header: {
    show?: boolean;
    mainSlug: string;
  };
  headerBgShadeColor?: string;
  headerHeights?: HeaderHeights;
  specialHomePageHeader?: JSX.Element
}

export const HOMEPAGE_MAIN_LAYOUT_ID = 'homepage-main-layout';
export const HOMEPAGE_MAIN_LAYOUT_HEADER_ID = 'homepage-main-layout-header';
export const HomePageMainBrandLayout = memo((props: PropsWithChildren<HomePageLayoutProps>) => {

  const [containerRef, setContainerRef] = useState<Element>();
  const {
        backgroundUrl,
        logoUrl,
        childrenUnderBackground,
        header: { show, mainSlug },
        headerBgShadeColor,
        headerHeights = defaultHeaderHeights,
        specialHomePageHeader= undefined
    } = props;

    const [headerHeight, setHeaderHeight] = useState(360);
    const sizeModel = useViewport(BREAKPOINTS, containerRef);
    const forceUpdate = useForceUpdate();


    useLayoutEffect(() => {
      setHeaderHeight(headerHeights[sizeModel.breakpointSize])
    })

    useLayoutEffect(() => {
        setHeaderHeight(headerHeights[sizeModel.breakpointSize])

        // eslint-disable-next-line
        return useOnResize(() => {
            forceUpdate();
        }, BREAKPOINTS, containerRef)
    })

    const ownClasses = getClasses(
      sizeModel,
      backgroundUrl,
      headerHeight,
      headerBgShadeColor ? headerBgShadeColor : 'transparent'
    );
    const featureFlags = useContext(FeatureFlagsContext) as IFeatureFlags;
    const [newBrandDesignEnabled] = useState(featureFlags[FeatureFlags.NEW_BRAND_DESIGN]);

    return (
      <LayoutContext.Provider value={{ containerRef }}>
          <Seo />
          <div
              ref={ref => setContainerRef(ref)}
              id={HOMEPAGE_MAIN_LAYOUT_ID}
              className={ownClasses.background}
          >
            <div >
              {(typeof show === 'undefined' || show) && (
                  <div className={newBrandDesignEnabled && ownClasses.header} id={HOMEPAGE_MAIN_LAYOUT_HEADER_ID}>
                      {/* defines the menu only (logo, menu entries, mobile drawer) */}
                      <Header logoUrl={logoUrl} mainSlug={mainSlug} containerRef={containerRef} />
                  </div>
              )}
              <div className={ownClasses.homePageHeader}>
                {specialHomePageHeader}
              </div>
            </div>
          </div>
          {childrenUnderBackground}
          <Footer />
      </LayoutContext.Provider>
  );

});
