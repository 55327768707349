import React from 'react'
import { Typography } from '@material-ui/core';

import { GQuestionAnswer } from '../../../../interface/graphql/GQuestionAnswer'
import { RichTextRenderer } from '../../../../components/contentful/RichtTextRenderer';

import { getClasses } from './FaqPageContentQuestionsAnswers.jss'

const QuestionAndAnswers = ({ questionAndAnwers }: { questionAndAnwers: GQuestionAnswer }) => {
    const classes = getClasses();

    return (
        <div className={classes.questionContainer}>
            <Typography variant='h5' className={classes.question}>{questionAndAnwers.question.question}</Typography>
            {questionAndAnwers.answer.map((a) => (<div key={`${a.children.toString()}`} className={classes.answerText}><RichTextRenderer>{a}</RichTextRenderer></div>))}
        </div>
    )
}

const FaqPageContentQuestionsAnswers = ({ questionsAnswers }: { questionsAnswers: Array<GQuestionAnswer> }) => {
    const classes = getClasses();

    return (
        <div>
            <ul className={classes.questionLinkContainer}>
                {questionsAnswers.map((qa, i) => <li key={`question-${qa.question.question}`} className={classes.questionLinkItem}><a href={`#${i}`}>{qa.question.question}</a></li>)}
            </ul>
            {questionsAnswers.map((qa, i) => (
                <div className={classes.rootContainer} id={`${i}`} key={`question-answers-${qa.question.question}`}>
                    <QuestionAndAnswers questionAndAnwers={qa} />
                </div>
            ))}
        </div>
    )
}

export default FaqPageContentQuestionsAnswers;
