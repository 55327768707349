export enum StepsSupported {
    STEP_INITIAL = 'STEP_INITIAL',
    STEP_DESIRED_QUANTITY_INPUT_FORM = 'STEP_DESIRED_QUANTITY_INPUT_FORM',
    STEP_SELECT_OFFSET_PROJECT = 'STEP_SELECT_OFFSET_PROJECT',
    STEP_PAYMENT_FORM = 'STEP_PAYMENT_FORM',

    // Journey PERSONAL_CARBON_FOOTPRINT
    STEP_SELECT_CALCULATION_FLOW = 'STEP_SELECT_CALCULATION_FLOW',
    STEP_SELECT_PERSON_GROUP = 'STEP_SELECT_PERSON_GROUP',
    STEP_SELECT_PERSONAL_CARBON_FOOTPRINT_JOURNEY_TYPE = 'STEP_SELECT_PERSONAL_CARBON_FOOTPRINT_JOURNEY_TYPE',

    STEP_ELECTRICITY_FOOTPRINT = 'STEP_ELECTRICITY_FOOTPRINT',
    STEP_HEATING_FOOTPRINT = 'STEP_HEATING_FOOTPRINT',
    STEP_ROAD_TRAVEL_FOOTPRINT = 'STEP_ROAD_TRAVEL_FOOTPRINT',
    STEP_FOOD_FOOTPRINT = 'STEP_FOOD_FOOTPRINT',
    STEP_SHOPPING_FOOTPRINT = 'STEP_SHOPPING_FOOTPRINT',
    STEP_FLIGHT_FOOTPRINT = 'STEP_FLIGHT_FOOTPRINT',
    STEP_CONSUMPTION_FOOTPRINT = 'STEP_CONSUMPTION_FOOTPRINT',

    STEP_UNDERSTAND = 'STEP_UNDERSTAND',
    STEP_UNDERSTAND_RESULTS = 'STEP_UNDERSTAND_RESULTS',
    STEP_UNDERSTAND_COMPARE = 'STEP_UNDERSTAND_COMPARE',
    STEP_UNDERSTAND_ZERO_COMPARE = 'STEP_UNDERSTAND_ZERO_COMPARE',
    STEP_UNDERSTAND_REDUCE = 'STEP_UNDERSTAND_REDUCE',
    STEP_UNDERSTAND_OFFSET = 'STEP_UNDERSTAND_OFFSET',
    STEP_UNDERSTAND_SELECT_PATH = 'STEP_UNDERSTAND_SELECT_PATH',

    STEP_REDUCTION = 'STEP_REDUCTION',
    STEP_REDUCTION_RESULTS = 'STEP_REDUCTION_RESULTS',

    STEP_SELECT_CARBON_OFFSET = 'STEP_SELECT_CARBON_OFFSET',
    STEP_CARBON_OFFSET_REFORESTATION = 'STEP_CARBON_OFFSET_REFORESTATION',
    STEP_CARBON_OFFSET_WATER_IS_LIFE = 'STEP_CARBON_OFFSET_WATER_IS_LIFE',
    STEP_DEFINE_AMOUNT_DONATION = 'STEP_DEFINE_AMOUNT_DONATION',
    STEP_CHECKOUT_PAYMENT = 'STEP_CHECKOUT_PAYMENT',

    //Journey PERSONAL_CARBON_FOOTPRINT_FAST_TRACK
    STEP_FLIGHT_FOOTPRINT_FAST_TRACK = 'STEP_FLIGHT_FOOTPRINT_FAST_TRACK',
    STEP_ELECTRICITY_FOOTPRINT_FAST_TRACK = "STEP_ELECTRICITY_FOOTPRINT_FAST_TRACK",
    STEP_CONSUMPTION_FOOTPRINT_FAST_TRACK = "STEP_CONSUMPTION_FOOTPRINT_FAST_TRACK",
    STEP_ROAD_TRAVEL_FOOTPRINT_FAST_TRACK = "STEP_ROAD_TRAVEL_FOOTPRINT_FAST_TRACK",
    STEP_FOOD_FOOTPRINT_FAST_TRACK = "STEP_FOOD_FOOTPRINT_FAST_TRACK",
    STEP_HEATING_FOOTPRINT_FAST_TRACK = "STEP_HEATING_FOOTPRINT_FAST_TRACK",

    // Journey CAR_ONLY
    STEP_CAR_ONLY_TRANSPORTATION = 'STEP_CAR_ONLY_TRANSPORTATION',
    STEP_CAR_ONLY_UNDERSTAND = 'STEP_CAR_ONLY_UNDERSTAND',
    STEP_CAR_ONLY_SELECT_CARBON_OFFSET = 'STEP_CAR_ONLY_SELECT_CARBON_OFFSET',
    STEP_CAR_ONLY_SELECT_DESIRED_AMOUNT = 'STEP_CAR_ONLY_SELECT_DESIRED_AMOUNT',
    STEP_CAR_ONLY_CHECKOUT_PAYMENT = 'STEP_CAR_ONLY_CHECKOUT_PAYMENT',

    STEP_CAR_ONLY_UNDERSTAND_RESULT = 'STEP_CAR_ONLY_UNDERSTAND_RESULT',
    STEP_CAR_ONLY_UNDERSTAND_COMPARE = 'STEP_CAR_ONLY_UNDERSTAND_COMPARE',

    // Journey FLIGHT_ONLY
    STEP_FLIGHTS_ONLY_TRANSPORTATION = 'STEP_FLIGHTS_ONLY_TRANSPORTATION',
    STEP_FLIGHTS_ONLY_UNDERSTAND = 'STEP_FLIGHTS_ONLY_UNDERSTAND',
    STEP_FLIGHTS_ONLY_SELECT_CARBON_OFFSET = 'STEP_FLIGHTS_ONLY_SELECT_CARBON_OFFSET',
    STEP_FLIGHTS_ONLY_SELECT_DESIRED_AMOUNT = 'STEP_FLIGHTS_ONLY_SELECT_DESIRED_AMOUNT',
    STEP_FLIGHTS_ONLY_CHECKOUT_PAYMENT = 'STEP_FLIGHTS_ONLY_CHECKOUT_PAYMENT',

    STEP_FLIGHTS_ONLY_UNDERSTAND_RESULT = 'STEP_FLIGHTS_ONLY_UNDERSTAND_RESULT',
    STEP_FLIGHTS_ONLY_UNDERSTAND_COMPARE = 'STEP_FLIGHTS_ONLY_UNDERSTAND_COMPARE',

    // Journey JUST_DONATE
    STEP_JUST_DONATE_AMOUNT = 'STEP_JUST_DONATE_AMOUNT',
    STEP_JUST_DONATE_CHECKOUT = 'STEP_JUST_DONATE_CHECKOUT',

    // Journey DESIRED_QUANTITY
    STEP_DESIRED_QUANTITY_AMOUNT = 'STEP_DESIRED_QUANTITY_AMOUNT',
    STEP_DESIRED_QUANTITY_SELECT_PROJECT = 'STEP_DESIRED_QUANTITY_SELECT_PROJECT',
    STEP_DESIRED_QUANTITY_PAYMENT = 'STEP_DESIRED_QUANTITY_PAYMENT',
    STEP_DESIRED_QUANTITY_CERTIFICATE = 'STEP_DESIRED_QUANTITY_CERTIFICATE',

     // Journey DESIRED_QUANTITY_B2B
     STEP_DESIRED_QUANTITY_B2B_AMOUNT = 'STEP_DESIRED_QUANTITY_B2B_AMOUNT',
     STEP_DESIRED_QUANTITY_B2B_SELECT_PROJECT = 'STEP_DESIRED_QUANTITY_B2B_SELECT_PROJECT',
     STEP_DESIRED_QUANTITY_B2B_PAYMENT = 'STEP_DESIRED_QUANTITY_B2B_PAYMENT',
     STEP_DESIRED_QUANTITY_B2B_CERTIFICATE = 'STEP_DESIRED_QUANTITY_B2B_CERTIFICATE'
}
