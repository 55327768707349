
// returns the step configuration for a slug and language provided
export const getStepForSlug = (config: any, slug: string) => {
    if (!config) return undefined;

    let currentStep;
    const journeysOfCurrentLanguage = config.userJourneyMap[config.language];
    const userJourneyNames = Object.keys(journeysOfCurrentLanguage);

    userJourneyNames.forEach(userJourneyName => {

        const currentJourney = journeysOfCurrentLanguage[userJourneyName];
        const flowNames = Object.keys(currentJourney);

        flowNames.forEach(flowName => {

            if (flowName === 'successPage') return;

            const currentFlow = currentJourney[flowName];
            const stepNames = Object.keys(currentFlow);

            stepNames.forEach(stepName => {

                if (currentFlow[stepName].slug &&
                    currentFlow[stepName].slug.replace(/\/$/, '') === slug.replace(/\/$/, '')) {
                    currentStep = currentFlow[stepName];
                }
            })
        })
    })
    return currentStep;
}
