"use strict";
exports.__esModule = true;
exports.ColorPalette = void 0;
var ColorPalette;
(function (ColorPalette) {
    ColorPalette["PRIMARY"] = "#41B574";
    ColorPalette["NEW_BRAND_PRIMARY"] = "#50A999";
    ColorPalette["SECONDARY"] = "#46725a";
    ColorPalette["NEW_SECONDARY"] = "#EF4F4F";
    ColorPalette["BACKGROUND"] = "#fafafa";
    ColorPalette["UNIVERSE_VIOLET"] = "#111137";
    ColorPalette["HEATING_RED"] = "#f48a8a";
    ColorPalette["ENERGY_YELLOW"] = "#f3bd5e";
    ColorPalette["FOOD_GREEN"] = "#73c07c";
    ColorPalette["CONSUMPTION_MINT"] = "#A5D4CC";
    ColorPalette["TRANSPORT_BLUE"] = "#68C8E4";
    ColorPalette["FLIGHT_PURPLE"] = "#5F61A3";
    ColorPalette["DARK_GREY"] = "#2B2D34";
    // full non-alpha: 255 (dec) ->54% (of 255 per alpha channel) -> 137.7 (dec) -> 8A (hex)
    ColorPalette["GREY"] = "#0000008A";
    ColorPalette["LIGHT_GREY"] = "#E9E9E9";
    ColorPalette["NEGATIVE_SPACE"] = "#FAFAFA";
})(ColorPalette = exports.ColorPalette || (exports.ColorPalette = {}));
