import React, { useContext } from "react";
import { darken, lighten, useTheme } from "@material-ui/core/styles";
import MobileStepper from "@material-ui/core/MobileStepper";
import SwipeableViews from "react-swipeable-views";
import { useStyles } from "./ImageStepper.jss";
import { joinClasses } from "../functions/join";
import { StepperRight } from "./icon/StepperRight";
import { StepperLeft } from "./icon/StepperLeft";
import { FeatureFlagsContext } from "./App";
import { PageContext } from "../layouts/MainLayout";
import { Typography } from "@material-ui/core";
import { KeyValuesContext } from "./KeyValuesContext";
import { BREAKPOINTS, getColorPalette, useViewport } from "@cpyou/shared/dist";
import { LayoutContext } from "../context/layoutContext";

const AutoPlaySwipeableViews = SwipeableViews;
interface IImageStepper {
    height?: number | string;
    projectCertImage?: string;
    projectImages: Array<{
        file: {
            url: string;
        };
    }>;
    projectType?: string | undefined;
}

export const ImageStepper = ({
    projectCertImage,
    projectImages,
    projectType = "wood",
    height,
}: IImageStepper) => {
    const theme = useTheme();
    const { containerRef } = useContext(LayoutContext);
    const sizeModel = useViewport(BREAKPOINTS, containerRef);
    const [activeStep, setActiveStep] = React.useState(0);
    const maxSteps = projectImages?.length;
    const featureFlags = useContext(FeatureFlagsContext);
    const { coBrand } = useContext(PageContext);
    const classes = useStyles(featureFlags, coBrand, sizeModel)();
    const keyValues = useContext(KeyValuesContext);

    const handleNext = () => {
        if (activeStep !== maxSteps - 1) {
            setActiveStep((prevActiveStep) => prevActiveStep + 1);
        }
    };

    const handleBack = () => {
        if (activeStep > 0) {
            setActiveStep((prevActiveStep) => prevActiveStep - 1);
        }
    };

    const handleStepChange = (step: number) => {
        setActiveStep(step);
    };

    return (
        <div
            className={joinClasses({
                [classes.root]: true,
            })}
        >
            {projectCertImage && (
                <div
                    className={joinClasses({
                        [classes.projectCertImage]: true,
                        [classes.certificationImage]: projectType === "water",
                        [classes.partnerImage]: projectType === "wood",
                    })}
                >
                    <div className={classes.divProject}>
                        <Typography
                            style={{ whiteSpace: "nowrap" }}
                            variant="subtitle1"
                        >
                            {projectType === "wood"
                                ? keyValues["Project developer"]
                                : keyValues["Project certification"]}
                        </Typography>
                    </div>
                    <img
                        src={projectCertImage}
                        alt="projectCertImage"
                        className={joinClasses({
                            [classes.imagePartner]: projectType === "wood",
                            [classes.certificationImage]:
                                projectType === "water",
                        })}
                    />
                </div>
            )}
            <AutoPlaySwipeableViews
                axis={theme.direction === "rtl" ? "x-reverse" : "x"}
                index={activeStep}
                onChangeIndex={handleStepChange}
                enableMouseEvents
            >
                {projectImages && projectImages.map((step, index) => (
                    <div key={step.file.url}>
                        {Math.abs(activeStep - index) <= 2 ? (
                            <img
                                style={{ height }}
                                className={classes.img}
                                src={step.file.url}
                                alt="Images"
                            />
                        ) : null}
                    </div>
                ))}
            </AutoPlaySwipeableViews>
            <MobileStepper
                steps={maxSteps}
                position="static"
                activeStep={activeStep}
                nextButton={
                    !(sizeModel.isMobile || sizeModel.isTablet) ? (
                        <StepperRight
                            onClick={handleNext}
                            color={
                                getColorPalette(
                                    featureFlags,
                                    coBrand,
                                    lighten,
                                    darken
                                ).primary.tint30Percent
                            }
                            disabled={activeStep === maxSteps - 1}
                        />
                    ) : (
                        ""
                    )
                }
                backButton={
                    !(sizeModel.isMobile || sizeModel.isTablet) ? (
                        <StepperLeft
                            onClick={handleBack}
                            color={
                                getColorPalette(
                                    featureFlags,
                                    coBrand,
                                    lighten,
                                    darken
                                ).primary.tint30Percent
                            }
                            disabled={activeStep === 0}
                        />
                    ) : (
                        ""
                    )
                }
                classes={{
                    root: joinClasses({
                        [classes.rootButtons]: true,
                        [classes.rootDotsMobile]:
                            sizeModel.isMobile || sizeModel.isTablet,
                    }),
                    dots: joinClasses({
                        [classes.dots]: true,
                        [classes.dotsMobile]:
                            sizeModel.isMobile || sizeModel.isTablet,
                    }),
                    dot: classes.dot,
                    dotActive: classes.dotActive,
                }}
            />
        </div>
    );
};
