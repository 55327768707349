import { IFeatureFlags } from '@cpyou/shared/dist';
import { GLink } from '../interface/graphql/GLink';
import { GMenu } from '../interface/graphql/GMenu';

export const getLinkDisplayStyle = (entry: GLink|GMenu, featureFlags: IFeatureFlags) => {

  // no featureName set on entry; show by default
  const style: any = {
    display: 'inherit'
  }

  // featureName set
  if (entry.featureName) {
    // should explicitly not be shown
    if (entry.featureName[0] === "!" && featureFlags[entry.featureName.replace('!', '')] === false) {
      style.display = 'inherit'
    } else if (entry.featureName[0] === "!" && featureFlags[entry.featureName.replace('!', '')] === true) {
      style.display = 'none'
    } else {
      // when featureName is set (and not a negation), must match the featureFlags set
      if (featureFlags[entry.featureName]) {
        style.display = 'inherit'
      } else {
        style.display = 'none'
      }
    }
  }
  return style;
};
