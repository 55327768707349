import { brandLink, ICoBrand } from '@cpyou/shared/dist';
import { GClimateOffsetProjectPage } from '../interface/graphql/GClimateOffsetProjectPage';
import { IGOffsetProjects } from '../interface/IGOffsetProjects';
import { IProjectPrice } from '../interface/IProjectPrice';
import { getImageUrl } from './getImageUrl';
import { getRichTextHtml } from './getRichTextHtml';
import { getSlugLanguageName } from './getSlugLanguageName';

export const mapCalculatorProjects = (offsetProjects: Array<IGOffsetProjects>, offsetProjectsPrice: Array<IProjectPrice>, offsetProjectPages: Array<{ node: GClimateOffsetProjectPage }>, calculatorCmpEnv: 'production' | 'development' = 'development', coBrand: ICoBrand) => {
    const filteredOffsetProjects = offsetProjects
        // filter projects not matching environment
        .filter((p) => (calculatorCmpEnv || 'development') === p.environment)
        .filter(p => !p.isStandaloneProject)

    const priceMap: { [projectId: string]: IProjectPrice } = offsetProjectsPrice.reduce((prev, curr) => ({
        ...prev,
        [curr.projectId]: curr,
    }), {});

    const getProjectPageSlugForProjectIdAndEnv = (projectId: string, env: string) => {
        let projectPageSlug = '#';
        offsetProjectPages.forEach((projectPage) => {

            if (projectPage.node.climateOffsetProject.projectId === projectId &&
                projectPage.node.environment === env) {
                    projectPageSlug = `${getSlugLanguageName(projectPage.node.locale)}/${projectPage.node.slug}`
                }
        })
        return brandLink(projectPageSlug, coBrand);
    }

    return filteredOffsetProjects.map((v) => {
        const { projectId } = v;
        const projectPrice = priceMap[projectId];
        const heroImgUrl = getImageUrl(v.heroImage)
        const price = projectPrice?.price;
        const currency = projectPrice?.currency;
        let projectChips = [heroImgUrl]

        if (v.isPortfolioProject) {
            projectChips = filteredOffsetProjects.filter((o) => !o.isPortfolioProject).map((o) => getImageUrl(o.heroImage))
        }

        return {
            projectId,
            price,
            currency,
            projectName: v.projectName,
            projectChips,
            heroImgUrl,
            heroCertImgUrl: getImageUrl(v.heroCertificateImage),
            body: getRichTextHtml(v.howItCompensates),
            effectChainText: getRichTextHtml(v.effectChainText),
            effectChainTextContent: v.effectChainText?.internal?.content,
            additionalBenefits: getRichTextHtml(v.additionalBenefits),
            additionalBenefitsContent: v.additionalBenefits?.internal?.content,
            startYearOfProject: v.startYearOfProject,
            projectDeveloperImage: v.projectDeveloperImage,
            certificateImages: v.certificateImages,
            totalCompensationVolumePerYear: v.totalCompensationVolumePerYear,
            sustainableDevelopmentGoals: v.sustainableDevelopmentGoals,
            partnerImages: v.partnerImages,
            projectImages: v.projectImages,
            title: v.title,
            places: v.places,
            environment: v.environment,
            primaryImpactUnit: v.primaryUnitIcon,
            isPortfolioProject: v.isPortfolioProject,
            sustainableDevelopmentGoalList: v.sustainableDevelopmentGoalList,
            projectPageSlug: getProjectPageSlugForProjectIdAndEnv(projectId, calculatorCmpEnv)
        }
    })
        // // filter projects with no price or currency
        // .filter((v) => v.price && v.currency);
}
