"use strict";
exports.__esModule = true;
exports.proximaNovaLightItalic = exports.proximaNovaLight = exports.proximaNovaItalic = exports.proximaNovaMedium = exports.proximaNovaRegular = exports.proximaNovaBoldItalic = exports.proximaNovaBold = exports.proximaNovaBlack = exports.lato = exports.getTypography = exports.getBreakpoints = void 0;
var core_1 = require("@material-ui/core");
var FeatureFlags_1 = require("../enum/FeatureFlags");
var BREAKPOINTS_1 = require("./BREAKPOINTS");
var getBreakpoints = function (featureFlags) { return ({ breakpoints: featureFlags[FeatureFlags_1.FeatureFlags.NEW_BRAND_DESIGN] ? {
        values: {
            xs: BREAKPOINTS_1.BREAKPOINTS.mobile,
            sm: BREAKPOINTS_1.BREAKPOINTS.tablet,
            md: BREAKPOINTS_1.BREAKPOINTS.desktop,
            lg: BREAKPOINTS_1.BREAKPOINTS.desktopXl,
            xl: BREAKPOINTS_1.BREAKPOINTS.desktopXxl
        }
    } : {}
}); };
exports.getBreakpoints = getBreakpoints;
var getTypography = function (featureFlags, coBrand) {
    var _a, _b, _c, _d, _e, _f, _g, _h, _j, _k, _l;
    if (featureFlags[FeatureFlags_1.FeatureFlags.NEW_BRAND_DESIGN]) {
        return {
            fontFamily: (coBrand === null || coBrand === void 0 ? void 0 : coBrand.customFontName) ? coBrand === null || coBrand === void 0 ? void 0 : coBrand.customFontName : [
                "'Proxima Nova Regular'",
                "'sans-serif'",
            ].join(','),
            h1: (_a = {
                    fontFamily: "'Proxima Nova Black'"
                },
                // mobile and down
                _a[core_1.createMuiTheme(exports.getBreakpoints(featureFlags)).breakpoints.up('xs')] = {
                    fontSize: '34px'
                },
                /*
                // tablet
                [createMuiTheme(getBreakpoints(featureFlags)).breakpoints.up('sm')]: {
                    fontSize: '0.06785', // huge de
                },
                // desktop
                [createMuiTheme(getBreakpoints(featureFlags)).breakpoints.up('md')]: {
                    fontSize: '0.06785', // huge de
                },
                // desktopXL
                [createMuiTheme(getBreakpoints(featureFlags)).breakpoints.up('lg')]: {
                    fontSize: '0.06785', // huge de
                },
                */
                // desktop and up
                _a[core_1.createMuiTheme(exports.getBreakpoints(featureFlags)).breakpoints.up('md')] = {
                    fontSize: '72px'
                },
                _a),
            h2: (_b = {
                    fontFamily: "'Proxima Nova Black'"
                },
                // mobile and down
                _b[core_1.createMuiTheme(exports.getBreakpoints(featureFlags)).breakpoints.up('xs')] = {
                    fontSize: '24px',
                    lineHeight: '32px'
                },
                // desktop and up
                _b[core_1.createMuiTheme(exports.getBreakpoints(featureFlags)).breakpoints.up('md')] = {
                    fontSize: '48px',
                    lineHeight: '56px'
                },
                _b),
            h3: (_c = {
                    fontFamily: "'Proxima Nova Bold'"
                },
                // mobile and down
                _c[core_1.createMuiTheme(exports.getBreakpoints(featureFlags)).breakpoints.up('xs')] = {
                    fontSize: '24px'
                },
                _c),
            h4: (_d = {
                    fontFamily: "'Proxima Nova Regular'"
                },
                // mobile and down
                _d[core_1.createMuiTheme(exports.getBreakpoints(featureFlags)).breakpoints.up('xs')] = {
                    fontSize: '24px'
                },
                // desktopXXL and down
                _d[core_1.createMuiTheme(exports.getBreakpoints(featureFlags)).breakpoints.up('md')] = {
                    fontSize: '32px'
                },
                _d),
            h5: (_e = {
                    fontFamily: "'Proxima Nova Bold'"
                },
                // mobile and down
                _e[core_1.createMuiTheme(exports.getBreakpoints(featureFlags)).breakpoints.up('xs')] = {
                    fontSize: '18px'
                },
                // desktopXXL and down
                _e[core_1.createMuiTheme(exports.getBreakpoints(featureFlags)).breakpoints.up('md')] = {
                    fontSize: '28px'
                },
                _e),
            h6: (_f = {
                    fontFamily: "'Proxima Nova Black'"
                },
                // mobile and down
                _f[core_1.createMuiTheme(exports.getBreakpoints(featureFlags)).breakpoints.up('xs')] = {
                    fontSize: '16px'
                },
                // desktopXXL and down
                _f[core_1.createMuiTheme(exports.getBreakpoints(featureFlags)).breakpoints.up('md')] = {
                    fontSize: '24px'
                },
                _f),
            subtitle1: (_g = {
                    fontFamily: "'Proxima Nova Light Italic'"
                },
                // mobile and down
                _g[core_1.createMuiTheme(exports.getBreakpoints(featureFlags)).breakpoints.up('xs')] = {
                    fontSize: '12px'
                },
                // desktopXXL and down
                _g[core_1.createMuiTheme(exports.getBreakpoints(featureFlags)).breakpoints.up('md')] = {
                    fontSize: '16px'
                },
                _g),
            body1: (_h = {
                    fontFamily: "'Proxima Nova Medium'"
                },
                // mobile and down
                _h[core_1.createMuiTheme(exports.getBreakpoints(featureFlags)).breakpoints.up('xs')] = {
                    fontSize: '16px'
                },
                // desktopXXL and down
                _h[core_1.createMuiTheme(exports.getBreakpoints(featureFlags)).breakpoints.up('md')] = {
                    fontSize: '24px'
                },
                _h),
            body2: (_j = {
                    fontFamily: "'Proxima Nova Regular'"
                },
                // mobile and down
                _j[core_1.createMuiTheme(exports.getBreakpoints(featureFlags)).breakpoints.up('xs')] = {
                    fontSize: '14px'
                },
                // desktopXXL and down
                _j[core_1.createMuiTheme(exports.getBreakpoints(featureFlags)).breakpoints.up('md')] = {
                    fontSize: '16px'
                },
                _j),
            button: (_k = {
                    fontFamily: "'Proxima Nova Bold'",
                    fontSize: '16px',
                    textTransform: 'none'
                },
                // mobile and down
                _k[core_1.createMuiTheme(exports.getBreakpoints(featureFlags)).breakpoints.up('xs')] = {
                    fontFamily: "'Proxima Nova Regular'"
                },
                _k),
            overline: {
                fontFamily: "'Proxima Nova Medium'",
                textTransform: 'uppercase',
                fontSize: '14px'
            },
            inherit: (_l = {
                    fontFamily: "'Proxima Nova Regular'"
                },
                // mobile and down
                _l[core_1.createMuiTheme(exports.getBreakpoints(featureFlags)).breakpoints.up('xs')] = {
                    fontSize: '10px'
                },
                // desktopXXL and down
                _l[core_1.createMuiTheme(exports.getBreakpoints(featureFlags)).breakpoints.up('md')] = {
                    fontSize: '12px'
                },
                _l)
        };
    }
    else {
        return {
            fontFamily: (coBrand === null || coBrand === void 0 ? void 0 : coBrand.customFontName) ? coBrand === null || coBrand === void 0 ? void 0 : coBrand.customFontName : [
                'Lato',
                'sans-serif',
            ].join(','),
            // fontFamilyLinks: [
            //     'https://fonts.googleapis.com/css2?family=Lato&display=swap',
            // ],
            button: {
                textTransform: 'none'
            },
            overline: {
                textTransform: 'none'
            },
            h1: {
                fontSize: '86px',
                marginBottom: '86px'
            },
            h2: {
                fontSize: '70px',
                marginBottom: '70px'
            },
            h3: {
                fontSize: '45px',
                marginBottom: '45px'
            },
            h4: {
                fontSize: '32px',
                marginBottom: '32px'
            },
            h5: {
                fontSize: '21px',
                marginBottom: '21px'
            },
            h6: {
                fontSize: '16px',
                marginBottom: '16px'
            }
        };
    }
};
exports.getTypography = getTypography;
exports.lato = {
    fontFamily: "'Lato'",
    fontStyle: 'normal',
    fontDisplay: 'swap',
    fontWeight: 400
};
exports.proximaNovaBlack = {
    fontFamily: "'Proxima Nova Black'",
    src: "url(\"https://use.typekit.net/af/e3ed45/00000000000000007735e602/30/l?subset_id=2&fvd=n9&v=3\") format(\"woff2\"),url(\"https://use.typekit.net/af/e3ed45/00000000000000007735e602/30/d?subset_id=2&fvd=n9&v=3\") format(\"woff\"),url(\"https://use.typekit.net/af/e3ed45/00000000000000007735e602/30/a?subset_id=2&fvd=n9&v=3\") format(\"opentype\")",
    fontStyle: 'normal',
    fontWeight: 900
};
exports.proximaNovaBold = {
    fontFamily: "'Proxima Nova Bold'",
    src: "url(\"https://use.typekit.net/af/2555e1/00000000000000007735e603/30/l?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n7&v=3\") format(\"woff2\"),url(\"https://use.typekit.net/af/2555e1/00000000000000007735e603/30/d?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n7&v=3\") format(\"woff\"),url(\"https://use.typekit.net/af/2555e1/00000000000000007735e603/30/a?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n7&v=3\") format(\"opentype\")",
    fontStyle: 'normal',
    fontWeight: 700
};
exports.proximaNovaBoldItalic = {
    fontFamily: "'Proxima Nova Bold Italic'",
    src: "url(\"https://use.typekit.net/af/4de20a/00000000000000007735e604/30/l?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=i7&v=3\") format(\"woff2\"),url(\"https://use.typekit.net/af/4de20a/00000000000000007735e604/30/d?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=i7&v=3\") format(\"woff\"),url(\"https://use.typekit.net/af/4de20a/00000000000000007735e604/30/a?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=i7&v=3\") format(\"opentype\")",
    fontStyle: 'italic',
    fontWeight: 700
};
exports.proximaNovaRegular = {
    fontFamily: "'Proxima Nova Regular'",
    src: "url(\"https://use.typekit.net/af/23e139/00000000000000007735e605/30/l?subset_id=2&fvd=n5&v=3\") format(\"woff2\"),url(\"https://use.typekit.net/af/23e139/00000000000000007735e605/30/d?subset_id=2&fvd=n5&v=3\") format(\"woff\"),url(\"https://use.typekit.net/af/23e139/00000000000000007735e605/30/a?subset_id=2&fvd=n5&v=3\") format(\"opentype\")",
    fontStyle: 'normal',
    fontWeight: 500
};
exports.proximaNovaMedium = {
    fontFamily: "'Proxima Nova Medium'",
    src: "url(\"https://use.typekit.net/af/23e139/00000000000000007735e605/30/l?subset_id=2&fvd=n5&v=3\") format(\"woff2\"),url(\"https://use.typekit.net/af/23e139/00000000000000007735e605/30/d?subset_id=2&fvd=n5&v=3\") format(\"woff\"),url(\"https://use.typekit.net/af/23e139/00000000000000007735e605/30/a?subset_id=2&fvd=n5&v=3\") format(\"opentype\")",
    fontStyle: 'normal',
    fontWeight: 600
};
exports.proximaNovaItalic = {
    fontFamily: "'Proxima Nova Italic'",
    src: "url(\"https://use.typekit.net/af/79862c/00000000000000007735e60e/30/l?subset_id=2&fvd=i5&v=3\") format(\"woff2\"),url(\"https://use.typekit.net/af/79862c/00000000000000007735e60e/30/d?subset_id=2&fvd=i5&v=3\") format(\"woff\"),url(\"https://use.typekit.net/af/79862c/00000000000000007735e60e/30/a?subset_id=2&fvd=i5&v=3\") format(\"opentype\")",
    fontStyle: 'italic',
    fontWeight: 500
};
exports.proximaNovaLight = {
    fontFamily: "'Proxima Nova Light'",
    src: "url(\"https://use.typekit.net/af/efe4a5/00000000000000007735e609/30/l?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n4&v=3\") format(\"woff2\"),url(\"https://use.typekit.net/af/efe4a5/00000000000000007735e609/30/d?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n4&v=3\") format(\"woff\"),url(\"https://use.typekit.net/af/efe4a5/00000000000000007735e609/30/a?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n4&v=3\") format(\"opentype\")",
    fontStyle: 'normal',
    fontWeight: 400
};
exports.proximaNovaLightItalic = {
    fontFamily: "'Proxima Nova Light Italic'",
    src: "url(\"https://use.typekit.net/af/3322cc/00000000000000007735e616/30/l?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=i4&v=3\") format(\"woff2\"),url(\"https://use.typekit.net/af/3322cc/00000000000000007735e616/30/d?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=i4&v=3\") format(\"woff\"),url(\"https://use.typekit.net/af/3322cc/00000000000000007735e616/30/a?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=i4&v=3\") format(\"opentype\")",
    fontStyle: 'italic',
    fontWeight: 400
};
