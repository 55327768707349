import * as React from 'react';
import { memo, PropsWithChildren, ReactNode, useContext, useState } from 'react';
import Seo from '../components/Seo';
import { Header } from '../components/Header';
import { Footer } from '../components/Footer';
import { getClasses } from './MainLayout.jss';
import { IPageContext } from '../interface/PageContext';
import { joinClasses } from '../functions/join';
import { FeatureFlagsContext } from '../components/App';
import { FeatureFlags, getColorShades, IFeatureFlags } from '@cpyou/shared/dist';
import { LayoutContext } from '../context/layoutContext';
import { darken, lighten, useTheme } from '@material-ui/core';

export interface IPropsMainLayout {
  backgroundUrl?: string;
  classes?: { background?: string; backgroundRect?: string };
  logoUrl?: string;
  hideLogo?: boolean;
  childrenUnderBackground?: ReactNode;
  header: {
    show?: boolean;
    mainSlug: string;
  };
  bgShadow?: boolean;
  bgShadeColor?: false | string;
}

export const PageContext = React.createContext<IPageContext | null>(null);
export const MAIN_LAYOUT_ID = 'main-layout';
export const MAIN_LAYOUT_HEADER_ID = 'main-layout-header';
export const MainLayout = memo((props: PropsWithChildren<IPropsMainLayout>) => {
    const {
        backgroundUrl,
        children,
        logoUrl,
        classes,
        childrenUnderBackground,
        header: { show, mainSlug },
        bgShadow,
        bgShadeColor,
        hideLogo
    } = props;

    const [containerRef, setContainerRef] = useState<Element>();
    const ownClasses = getClasses(backgroundUrl);
    const featureFlags = useContext(FeatureFlagsContext) as IFeatureFlags;
    const [newBrandDesignEnabled] = useState(featureFlags[FeatureFlags.NEW_BRAND_DESIGN]);
    const theme = useTheme();
    const overrideStyles: any = {};

    if (featureFlags[FeatureFlags.NEW_BRAND_DESIGN] && childrenUnderBackground) {
        overrideStyles.backgroundImage = 'none'
        overrideStyles.backgroundColor = getColorShades(theme.palette.primary.main, lighten, darken).tint90Percent
    }

    return (
        <LayoutContext.Provider value={{ containerRef }}>
            <Seo />
            <div
                ref={ref => setContainerRef(ref)}
                id={MAIN_LAYOUT_ID}
                className={joinClasses([ownClasses.background, classes?.background])}
                style={overrideStyles}
            >
                <div
                    className={joinClasses([
                        ownClasses.backgroundRect,
                        classes?.backgroundRect,
                    ])}
                    style={{
                        background: bgShadow === false ? 'transparent' : bgShadeColor || 'inherit',

                    }}
                >
                    {/* Layout does not have ci */}
                    {(typeof show === 'undefined' || show) && (
                        <div className={newBrandDesignEnabled && ownClasses.header} id={MAIN_LAYOUT_HEADER_ID}>
                            <Header logoUrl={logoUrl} mainSlug={mainSlug} containerRef={containerRef} hideLogo={hideLogo} />
                        </div>
                    )}
                    <h1 style={{margin: 0}}>{children}</h1>
                </div>
            </div>
            {childrenUnderBackground}
            <Footer />
        </LayoutContext.Provider>
    );
});
