import { useLayoutEffect, useState } from 'react';

export const useLocationOrigin = () => {
    const [origin, setOrigin] = useState('');

    useLayoutEffect(() => {
        if (typeof window !== 'undefined') {
            setOrigin(window.location.origin);
        }
    }, [setOrigin])
    return origin;
}
