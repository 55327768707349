import globalHook from 'use-global-hook';
import React from 'react';
import { initialState, IStoreState } from './initialState';
import { actions, IActions } from './actions';

export const useGlobal: () => [IStoreState, IActions] = globalHook(
    React,
    initialState,
    actions,
);
