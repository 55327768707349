import * as React from "react";
import { useEffect, useRef, useState } from "react";

import { graphql, PageProps } from "gatsby";
import {
    useEffectOnce,
    useLatest,
    useMount,
    useSessionStorage,
    useTimeoutFn,
} from "react-use";
import { Button, Grid } from "@material-ui/core";
import { GFooter } from "../../interface/graphql/GFooter";
import { GImage } from "../../interface/graphql/GImage";
import { GSeo } from "../../interface/graphql/GSeo";
import { MainLayout, PageContext } from "../../layouts/MainLayout";
import { GHeader } from "../../interface/graphql/GHeader";
import { getImageUrl } from "../../functions/getImageUrl";
import { App } from "../../components/App";
import { SessionStorageKeys } from "../../interface/SessionStorageKeys";
import { BusinessType } from "../../interface/BusinessType";
import { detectBusinessType } from "../../functions/detectBusinessType";
import { mapKeyValues } from "../../functions/mapKeyValues";
import { getClasses } from "./SuccessPageTemplate.jss";
import { Key } from "../../components/Key";
import { useLocalStorage } from "../../hook/useLocalStorage";
import { useIsNativeShare } from "../../hook/useIsNativeShare";
import { useIsMobile } from "../../hook/useIsMobile";
import { useKeyFunction } from "../../hook/useKeyFunction";
import { NativeShareButton } from "../../components/share-button/NativeShareButton";
import { FacebookShareButton } from "../../components/share-button/FacebookShareButton";
import { LinkedInShareButton } from "../../components/share-button/LinkedInShareButton";
import { TwitterShareButton } from "../../components/share-button/TwitterShareButton";
import { EmailShareButton } from "../../components/share-button/EmailShareButton";
import { WhatsAppShareButton } from "../../components/share-button/WhatsappShareButton";
import { FacebookMessengerShareButton } from "../../components/share-button/FacebookMessengerShareButton";
import { CopyLinkButton } from "../../components/share-button/CopyLinkButton";
import { useLocationOrigin } from "../../hook/useLocationOrigin";
import { dispatchCustomEvent } from "../../functions/dispatchCustomEvent";
import { gtmTrackEvent } from "../../functions/gtmTrackEvent";
import { IPageContext } from "../../interface/PageContext";
import { Helmet } from "react-helmet";
import { cleanStorage, persist, formatNumber } from "@cpyou/shared/dist";
import { JourneySupported } from "@cpyou/component/src/interface/JourneySupported";
import { useGlobal } from "../../store";
import { getLocationSearchParameters } from "../../functions/getLocationSearchParameters";
import { ISelectedAmountDonationInputValues } from "@cpyou/component/src/interface/ISelectedAmountDonationInputValues";
import { Trans, useTranslation } from "react-i18next";
import { IPaymentTypeCreateTransactionRequest } from "@cpyou/component/src/client/interface/request/enumeration/i-payment-type-create-transaction-request";

export interface IPropsSuccessPageTemplate {
    allContentfulKeyValue: any;
    contentfulSuccessPage: {
        locale: string;
        slug: string;
        name: string;
        header: GHeader;
        background: GImage;
        backgroundVideo?: GImage;
        footer: GFooter;
        seo: GSeo;
        canonicalSuccessPage: { slug: string };
    };
}

export enum SuccessPageJourneyName {
    SUCCESS_JUST_DONATE = "Success compensated Just Donate",
    SUCCESS_DESIRED_QUANTITY_B2B = "Success compensated Desired Quantity B2B",
}

const SuccessPageTemplate = (props: PageProps<IPropsSuccessPageTemplate>) => {
    const [, setBusinessType] = useSessionStorage(
        SessionStorageKeys.BUSINESS_TYPE,
        BusinessType.B2C
    );

    useMount(() => {
        setBusinessType(detectBusinessType());
    });

    const {
        data: { contentfulSuccessPage, allContentfulKeyValue },
        pageContext,
    } = props;
    const {
        seo,
        background,
        backgroundVideo,
        header,
        footer,
        name,
        canonicalSuccessPage,
    } = contentfulSuccessPage;
    const keyValues = mapKeyValues(allContentfulKeyValue.nodes);

    console.log(header);

    return (
        <App
            pageContext={pageContext as any}
            seoContext={seo}
            header={header}
            footerContext={footer}
            keyValuesContext={keyValues}
            borderMobileMenu
        >
            <SuccessContent
                background={{
                    imageUrl: background.file.url,
                    videoUrl: backgroundVideo?.file.url,
                }}
                headerSlug={header.mainSlug}
                name={name}
            />
            {canonicalSuccessPage && canonicalSuccessPage.slug && (
                <Helmet>
                    <link rel="canonical" href={canonicalSuccessPage.slug} />
                </Helmet>
            )}
        </App>
    );
};

export interface ISuccessContent {
    background: { imageUrl: string; videoUrl?: string };
    headerSlug: string;
    name: string;
}

const SuccessContent = ({
    background: { imageUrl: backgroundImageUrl, videoUrl: backgroundVideoUrl },
    headerSlug,
    name,
}: ISuccessContent) => {
    const [dimension, setDimension] = useState({
        left: 0,
        width: 0,
        height: 0,
        sloganHeight: 0,
    });
    const isNativeShare = useIsNativeShare();
    const origin = useLocationOrigin();
    const isMobile = useIsMobile();
    const keyF = useKeyFunction();
    const { gtmId, language } = React.useContext(PageContext) as IPageContext;
    const [{ componentConfig }] = useGlobal();
    const latestComponentConfig = useLatest(componentConfig);
    const divRef = useRef<HTMLDivElement>(null);
    const refHeight = useRef<HTMLDivElement>(null);
    const [showFeedbackButton, setShowFeedbackButton] = useState(false);
    const t = useKeyFunction();

    // const onFeedbackButtonClick = React.useCallback(
    //     () => () => {
    //         if (
    //             latestComponentConfig.current &&
    //             latestComponentConfig.current.currentJourneyName ===
    //                 JourneySupported.PERSONAL_CARBON_FOOTPRINT
    //         ) {
    //             gtmTrackEvent("openSurveyDialogueInApp", gtmId);
    //             dispatchCustomEvent("you.component.feedback", {
    //                 showFeedbackButton: false,
    //                 showFeedBackModal: true,
    //                 websiteMode: true,
    //             });
    //             setShowFeedbackButton(true);
    //         }
    //     },
    //     [latestComponentConfig, gtmId]
    // );

    // useTimeoutFn(() => {
    //     onFeedbackButtonClick()();
    // }, 5000);

    useEffectOnce(() => {
        window.addEventListener("beforeunload", () => {
            cleanStorage("LOCAL");
            persist(
                "journeyOrigin",
                JourneySupported.PERSONAL_CARBON_FOOTPRINT,
                "LOCAL"
            );
        });
    });

    useEffect(() => {
        const onResize = () => {
            if (divRef.current) {
                const boundingRec = divRef.current.getBoundingClientRect();
                setDimension((c) => ({
                    ...c,
                    left: boundingRec.left,
                    width: boundingRec.width,
                    sloganHeight: boundingRec.height,
                }));
            }
        };
        window.addEventListener("resize", onResize);
        onResize();
        return () => {
            window.removeEventListener("resize", onResize);
        };
    }, [divRef]);

    useEffect(() => {
        const onResize = () => {
            if (refHeight.current) {
                const boundingRec = refHeight.current.getBoundingClientRect();
                setDimension((c) => ({
                    ...c,
                    height: boundingRec.height,
                }));
            }
        };
        window.addEventListener("resize", onResize);
        onResize();
        return () => {
            window.removeEventListener("resize", onResize);
        };
    }, [refHeight]);
    const classes = getClasses(dimension);

    let locationData: any;
    let invoiceReference: string;
    if (typeof document !== "undefined") {
        locationData = getLocationSearchParameters(document.location.search);
        invoiceReference = `${locationData.projectId}-${locationData.transactionId}`;
    }

    const [data] = useLocalStorage("selectedAmountDonation");

    let selected: ISelectedAmountDonationInputValues = JSON.parse(data || "{}");

    if (!data && locationData && locationData.selectedAmountDonation) {
        selected = locationData.selectedAmountDonation;
    }

    const isWood = selected?.project === "wood";
    const isWater = selected?.project === "water";
    const isPortfolio = selected?.project === "portfolio";
    // const footprintCO2 = `${formatNumber(
    //     language,
    //     selected.footprintInTons || 0,
    //     2,
    //     2
    // )} t CO₂`;

    const url = keyF("{{origin}}/en/refer-friends", { origin });
    const title = keyF("Take climate action  | CP YOU");
    const description = keyF(
        "Global warming - do you want to be part of the solution? Then calculate your personal carbon footprint and find out how you can go net zero"
    );

    const approvedBodyName = React.useCallback(() => {
        let bodyName = "";
        switch (name) {
            case SuccessPageJourneyName.SUCCESS_JUST_DONATE:
                bodyName =
                    "Gain even more impact. Invite your friends to calculate their carbon footprint now!";
                break;
            case SuccessPageJourneyName.SUCCESS_DESIRED_QUANTITY_B2B:
                bodyName =
                    "Jetzt für noch mehr Impact sorgen. Laden Sie Ihre Freunde ein, ihren CO₂-Fußabdruck zu berechnen!";
                break;
            default:
                bodyName =
                    "Invite your friends now to calculate their carbon footprint.";
                break;
        }
        return bodyName;
    }, [name]);

    return (
        <div ref={refHeight}>
            <div className={classes.backgroundDivWrapper}>
                <video
                    autoPlay
                    muted
                    loop
                    className={classes.backgroundDiv}
                    poster={backgroundImageUrl}
                >
                    {!!backgroundVideoUrl && (
                        <source src={backgroundVideoUrl} type="video/mp4" />
                    )}
                </video>
            </div>
            <MainLayout header={{ mainSlug: headerSlug }}>
                <Grid container className={classes.mainContainer}>
                    <Grid item xs={12} lg={5} style={{ textAlign: "center" }}>
                        <div className={classes.approvedWrapper}>
                            <div className={classes.approvedIcon}>
                                <span role="img" aria-label="Emoji">
                                    🙌
                                </span>
                            </div>
                            <h1 className={classes.headlineCool}>
                                {" "}
                                <p
                                    dangerouslySetInnerHTML={{
                                        __html: t(
                                            "Thank you for your commitment"
                                        ),
                                    }}
                                />
                            </h1>
                            {name ===
                            SuccessPageJourneyName.SUCCESS_JUST_DONATE ? (
                                <div className={classes.approvedHeader}>
                                    <Key>
                                        With your donation, you help mobilizing
                                        more people against global warming
                                    </Key>
                                </div>
                            ) : locationData?.paymentType ===
                              IPaymentTypeCreateTransactionRequest.INVOICE ? (
                                <div className={classes.approvedHeader}>
                                    <p
                                        dangerouslySetInnerHTML={{
                                            __html: t(
                                                "Please use the following information when transferring the donation"
                                            ),
                                        }}
                                    />
                                    <br />
                                    <p>ClimatePartner Foundation gGmbH</p>
                                    <p>Hypovereinsbank München</p>
                                    <p>
                                        IBAN: DE38 7002 0270 0020 2949 61 BIC:
                                        HYVEDEMMXXX
                                    </p>
                                    <p>Referenz: {invoiceReference}</p>
                                </div>
                            ) : (
                                <>
                                    <div
                                        className={classes.approvedHeader}
                                        dangerouslySetInnerHTML={{
                                            __html: t(
                                                "successPageThankYouMessage",
                                                {
                                                    amount: formatNumber(
                                                        language,
                                                        selected.amountInEuro,
                                                        2,
                                                        2
                                                    ),
                                                    project:
                                                        selected.projectName,
                                                }
                                            ),
                                        }}
                                    ></div>
                                </>
                            )}

                            <div
                                className={classes.approvedBody}
                                dangerouslySetInnerHTML={{
                                    __html: t("sucessPageDontationReceiptInfo"),
                                }}
                            />
                            {showFeedbackButton && (
                                <Button
                                    id="feedbackButton"
                                    color="primary"
                                    variant="contained"
                                    className={classes.feedbackButton}
                                    onClick={() => {}}
                                >
                                    <Key>Feedback</Key>
                                </Button>
                            )}
                        </div>
                    </Grid>
                    <Grid
                        ref={divRef}
                        item
                        xs={12}
                        lg={7}
                        className={classes.sloganWrapper}
                    >
                        <video
                            autoPlay
                            muted
                            loop
                            playsInline
                            className={classes.mobileVideo}
                            poster={backgroundImageUrl}
                        >
                            {!!backgroundVideoUrl && (
                                <source
                                    src={backgroundVideoUrl}
                                    type="video/mp4"
                                />
                            )}
                        </video>
                        {/* Removed in YW-14 */}
                        {/* <div className={classes.sloganHeader}>
                            <Key>{approvedBodyName()}</Key>
                        </div>
                        {(!isMobile || (isMobile && !isNativeShare)) && (
                            <div className={classes.sloganBody}>
                                <CopyLinkButton url={url} />
                                <div className={classes.sharIconsWrapper}>
                                    {isMobile && !isNativeShare && <WhatsAppShareButton classes={{ root: classes.shareButton }} url={url} iconColor="white" title={title} summery={description} />}
                                    <FacebookShareButton classes={{ root: classes.shareButton }} url={url} iconColor="white" />
                                    {isMobile && !isNativeShare && <FacebookMessengerShareButton classes={{ root: classes.shareButton }} url={url} iconColor="white" />}
                                    <LinkedInShareButton classes={{ root: classes.shareButton }} url={url} iconColor="white" title={title} summery={description} />
                                    <TwitterShareButton classes={{ root: classes.shareButton }} url={url} iconColor="white" title={title} summery={description} />
                                    <EmailShareButton classes={{ root: classes.shareButton }} url={url} iconColor="white" title={title} summery={description} />
                                </div>
                            </div>
                        )}
                        {isMobile && isNativeShare && (
                            <div className={classes.nativeShare}>
                                <NativeShareButton url={url} title={title} text={description} />
                            </div>
                        )} */}
                    </Grid>
                </Grid>
            </MainLayout>
        </div>
    );
};
export default SuccessPageTemplate;

export const queryPage = graphql`
    query ContentfulSuccessPageBySlugAndLocale($slug: String, $locale: String) {
        allContentfulKeyValue(filter: { node_locale: { eq: $locale } }) {
            nodes {
                key {
                    key
                }
                locale: node_locale
                value {
                    value
                }
            }
        }
        contentfulSuccessPage(
            slug: { eq: $slug }
            node_locale: { eq: $locale }
        ) {
            approvedHeader
            approvedBody
            sloganHeader
            sloganBody
            locale: node_locale
            name
            slug
            background {
                ...ImageFragment
            }
            backgroundVideo {
                ...ImageFragment
            }
            footer {
                ...FooterFragment
            }
            seo {
                ...SeoFragment
            }
            header {
                ...HeaderFragment
            }
            canonicalSuccessPage {
                slug
            }
        }
    }
`;
