import React, { useContext, useEffect, useState } from 'react';
import { Link } from 'gatsby';
import { Button } from '@material-ui/core';
import { getClasses } from './DrawerMobileMenu.jss';
import { DrawerContext } from './Drawer';
import { pageToLink } from '../functions/pageToLink';
import { navigate } from '../functions/navigate';
import { PageContext } from '../layouts/MainLayout';
import { IPageContext } from '../interface/PageContext';
import { FeatureFlagsContext } from './App';
import { getLinkDisplayStyle } from '../functions/getLinkDisplayStyle';
import { FeatureFlags, IFeatureFlags } from '@cpyou/shared/dist';

export const DrawerMobileMenu = () => {
    const classes = getClasses();
    const { menu, justDonateButton } = useContext(DrawerContext);
    const { language, coBrand } = useContext(PageContext) as IPageContext;

    // e.g. featureFlags[FeatureFlags.JUST_DONATE_ENABLED]
    const featureFlags = useContext(FeatureFlagsContext) as IFeatureFlags;
    const [justDonateUserJourneyEnabled] = useState(featureFlags[FeatureFlags.JUST_DONATE_USER_JOURNEY_ENABLED]);

    const handleNavigationClick = async (page?: { slug: string }, search?: string) => {
        await navigate(pageToLink(language, coBrand, page, search));
    }

    return (
        <div className={classes.menu}>
            {!!menu && menu.map((m) => (
                <span key={m.title} style={getLinkDisplayStyle(m, featureFlags)}>
                    <div className={classes.title}>
                        {m.title}
                    </div>
                    <ul className={classes.list}>
                        {m.subTitle.map((subM) => (
                            <li style={getLinkDisplayStyle(subM, featureFlags)} className={classes.listItem} key={subM.title}>
                                <Link className={classes.subTitle} to={pageToLink(language, coBrand, subM.page, subM.search)}>
                                    {subM.title}
                                </Link>
                            </li>
                        ))}
                    </ul>
                </span>
            ))}
            {justDonateButton && !coBrand && (
                <Button
                    color="secondary"
                    variant="contained"
                    style={{ fontSize: 'inherit' }}
                    className={classes.buttonJustDonate}
                    onClick={() => handleNavigationClick(justDonateButton.page, justDonateButton.search)}
                >
                    <span>{justDonateButton?.title}</span>
                </Button>
            )}
        </div>
    )
}
