import { Box } from "@material-ui/core";
import React, { useContext, useLayoutEffect } from "react";
import { LayoutContext } from "../../../../context/layoutContext";
import { NewHomePageContentBlogPostsSelector } from "../NewContentBlogPostsSelector/NewHomePageContentBlogPostsSelector";
import { NewHomePageContentAbout } from "../NewContentAbout/NewHomePageContentAbout";
import { NewHomePageContentJourneySelector } from "../NewContentJourneySelector/NewHomePageContentJourneySelector";
import { NewHomePageContentProjectSelector, Projects } from "../NewContentProjectSelector/NewHomePageContentProjectSelector";
import { BlogPosts } from "../../../HomePage/components/ContentBlogPosts/HomePageContentBlogPosts";
import { GLink } from "../../../../interface/graphql/GLink";
import { BREAKPOINTS, useForceUpdate, useOnResize } from "@cpyou/shared/dist";

export interface IPropsHomePageContent {
  blogPosts: BlogPosts,
  projects: Projects,
  conlyLink: GLink;
  fonlyLink: GLink;
  aboutLink: GLink;
  offsetAmountLink: GLink;
}

export interface IPropNewHomePageContentProjects {
  projects: Projects
}

export type aboutImage = {
  title: string;
  url: string;
  fileName: string
}
export const NewHomePageContent = ({
  blogPosts,
  projects,
  conlyLink,
  fonlyLink,
  aboutLink,
  offsetAmountLink
}: IPropsHomePageContent) => {

  const forceUpdate = useForceUpdate();
  const { containerRef } = useContext(LayoutContext);

  useLayoutEffect(() => {

    // eslint-disable-next-line
    return useOnResize(() => {
        forceUpdate();
    }, BREAKPOINTS, containerRef)
    })

    {/* TODO: Uncomment the comment lines when necessary */}
    return (
      <Box display="flex" flexDirection="column">
        <NewHomePageContentJourneySelector conlyLink={conlyLink} fonlyLink={fonlyLink} offsetAmountLink={offsetAmountLink}/>
        {/* <NewHomePageContentBlogPostsSelector blogPosts={blogPosts} /> */}
        <NewHomePageContentAbout link={aboutLink}/>
        <NewHomePageContentProjectSelector projects={projects}/>
      </Box>
    );
};
