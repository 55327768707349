/* eslint-disable no-unused-vars */
/* eslint-disable no-shadow */
import React, {
    CSSProperties, memo, useCallback, useContext, useEffect, useState,
} from 'react';
import { navigate } from 'gatsby';
import { useLatest, usePrevious } from 'react-use';
import { useMixpanel } from '../../../../hook/useMixpanel';
import { pageToLink } from '../../../../functions/pageToLink';
import { dispatchCustomEvent } from '../../../../functions/dispatchCustomEvent';
import { useGlobal } from '../../../../store';
import { flattenObject } from '../../../../functions/flattenObject';
import { ComponentEventsSupported } from '@cpyou/shared/dist/enum/ComponentEventsSupported';
import { track } from '../../../../functions/trackMailingListEvent';
import { PageContext } from '../../../../layouts/MainLayout';
import { brandLink } from '@cpyou/shared/dist';
import { getSlugForPageConfig } from '../../../../functions/getSlugForPageConfig';
import { GLink } from "../../../../interface/graphql/GLink"

interface CalculatorPage {
  locale: string;
  slug: string;
  flowName: string;
  stepName: string;
}

export const CALCULATOR_COMPONENT_ID = 'calculator-integration';

export interface IPropsCalculatorManager {
  className?: string;
  language: string;
  mainSlug: { slug: string };
  privatePolicy: { slug: string };
  termsOfUse: { slug: string };
  nextPage: GLink;
}

export interface IStepState {
  previous?: CalculatorPage;
  current?: CalculatorPage;
  next?: CalculatorPage;
}

const DEFAULT_CALCULATOR_STYLE: CSSProperties = {
    flex: 1,
    display: 'flex',
};

export const CalculatorManager = memo(
    ({
        className,
        privatePolicy,
        termsOfUse,
        nextPage,
        mainSlug,
        language,
    }: IPropsCalculatorManager) => {
        const [{ componentConfig }, { setComponentConfig }] = useGlobal();
        const latestComponentConfig = useLatest(componentConfig);
        const { trackEvent, trackPageView } = useMixpanel();
        const { coBrand } = useContext(PageContext);
        const [previousTrackedEvent, setPreviousTrackedEvent] = useState<String>();
        const latestPreviousTrackedEvent = useLatest(previousTrackedEvent);

        // updates the browser URL shown as well, but doesn't reload the webiste
        const updateHistory = useCallback(
            (config: any) => {

                const stepSlug = getSlugForPageConfig(
                    config,
                    config.currentJourneyName,
                    config.currentFlowName,
                    config.currentStepName,
                    config.language
                );

                if (typeof window !== 'undefined') {
                    const state = window.history.state ? window.history.state : {};

                    if (typeof state.cursor === 'undefined') {
                        state.cursor = -1;
                    }

                    if (!state.history) {
                        state.history = [];
                    }

                    state.history.push({
                        stepSlug,
                        language,
                        flowName: config.currentFlowName,
                        stepName: config.currentStepName,
                    });
                    state.cursor += 1;

                    const slug = `/${language}/${stepSlug}`;

                    const newLocation = config.coBrand ? `/${config.coBrand.slug}${slug}` : slug;

                    // search should be added in case it has been there
                    // https://climatepartner.atlassian.net/browse/YOU-2100
                    window.history.pushState(state, '', newLocation + document.location.search);

                    // dispatch slug change for <iframe> integrators to receive a notification
                    dispatchCustomEvent(ComponentEventsSupported.COMPONENT_LOCATION_CHANGE, {
                        href: window.location.href,
                        slug: newLocation
                    })
                }
            },
            [language],
        );

        // TODO: refactor: use COMPONENT_EXTERNAL_LINK_CLICKED instead
        const onClickLegalNoticeListener = useCallback(() => {
            window.open(
                `${window.location.origin}${brandLink(`/${language}/${privatePolicy.slug}`, coBrand)}`,
            );
        }, [language, privatePolicy.slug, coBrand]);

        // TODO: refactor: use COMPONENT_EXTERNAL_LINK_CLICKED instead
        const onClickTermsOfUseListener = useCallback(() => {
            window.open(
                `${window.location.origin}${brandLink(`/${language}/${termsOfUse.slug}`, coBrand)}`
            );
        }, [language, termsOfUse.slug, coBrand]);

        // TODO: refactor: use COMPONENT_EXTERNAL_LINK_CLICKED instead
        const onClickMainSlug = useCallback(
            (evt: any) => {
                evt.stopPropagation();
                if (latestComponentConfig.current.currentFlowName === 'FLOW_SELECT') {
                    document.location.href = brandLink(`/${language}`, coBrand)
                } else {
                    // take care for b2b/b2c
                    navigate(pageToLink(language, coBrand, mainSlug));
                }
            },
            [language, mainSlug, coBrand, latestComponentConfig],
        );

        const onClickBackLandingPage = useCallback(()=> {
            // TODO: target should be provided by event parameter not determined here
            navigate(`${window.location.origin}${brandLink(`/${language}/${latestComponentConfig.current.backPage?.slug}`, coBrand)}`)
        },
            [language, latestComponentConfig, coBrand],
        );

        const onClickNextToAccountTeaserPage = useCallback((evt:any)=> {
            const { slug }: { slug: string} = evt.detail;
            document.location.href = brandLink(`/${language}/${slug}`, coBrand)
        },
            [language, coBrand],
        );

        const trackFlowEvent = useCallback(
            (
                eventType: string,
                currentLatestComponentConfig: any,
                overwrite?: { [key: string]: any },
            ) => {
                const {
                    currentFlowName,
                    currentStepName,
                    currentJourneyName,
                    footprint,
                    footprintReduced,
                    reductionTargets,
                    calculationInputValues,
                } = currentLatestComponentConfig;

                const trackingObject: { [key: string]: any } = {
                    journeyType: currentJourneyName,
                    flowType: currentFlowName,
                    stepType: currentStepName,
                    footprint,
                    footprintReduced:

                footprintReduced === 0 ? undefined : footprintReduced,
                    reductionMeasure: '',
                };

                const calculationInputValuesFlattened = flattenObject({
                    calculationInputValues,
                });

                const reductionMeasures: Array<any> = [];
                if (reductionTargets) {
                    Object.entries(reductionTargets).forEach(
                        ([targetCategoryKey, targets]) => {
                            Object.entries(targets as Array<any>).forEach(([target, value]) => {
                                // check if target is true
                                if (value === true) {
                                    reductionMeasures.push(`${targetCategoryKey}_${target}`);
                                } else if (value !== false && value !== 0) {
                                    // if value is filled (usally when previous is true)
                                    trackingObject[`${targetCategoryKey}_${target}`] = value;
                                }
                            });
                        },
                    );
                }

                if (reductionMeasures.length > 0) trackingObject.reductionMeasures = reductionMeasures;

                const eventToTrack = `flow${eventType}`;
                // prevent double-tracking
                if (latestPreviousTrackedEvent.current !== eventToTrack) {

                    /*
                    console.log('track flow', latestPreviousTrackedEvent, eventToTrack, {
                        ...Object.assign(trackingObject, overwrite),
                        ...calculationInputValuesFlattened,
                    })
                    */

                    trackEvent(eventToTrack, {
                        ...Object.assign(trackingObject, overwrite),
                        ...calculationInputValuesFlattened,
                    });
                    setPreviousTrackedEvent(eventToTrack)
                }
            },
            [trackEvent, latestPreviousTrackedEvent],
        );

        const [previousUserJourney, setPreviousUserJourney] = useState({});

         const handleTracking = useCallback(
            (current: any) => {

                const {
                    currentFlowName,
                    previousFlowName,
                    currentStepName,
                    currentJourneyName,
                    userJourneyMap,
                    previousStepName,
                    language
                } = current;

                const currentUserJourney = userJourneyMap[language][currentJourneyName];

                // track the page view on synthetic component page changes (in a standard way)
                trackPageView()

                // flow tracking
                // check if flow changed
                // can also happen when previousFlowName is undefined
                if (currentFlowName !== previousFlowName) {

                    const previousFlowInJourney: { index: number; [key: string]: any } = previousUserJourney[previousFlowName];
                    const currentFlowInJourney: { index: number; [key: string]: any } = currentUserJourney[currentFlowName];

                    // check if flow was started
                    if (currentFlowInJourney && currentFlowInJourney[currentStepName].index === 0) {
                        trackFlowEvent('Started', current);
                    }

                    // check if flow was completed
                    // when flow index has increased (also allows for bigger than 1 index jumps)
                    if (previousFlowInJourney && currentFlowInJourney &&
                        previousFlowInJourney.index < currentFlowInJourney.index) {

                        // As we check if the previous flow was completed, we need to track the prev event
                        trackFlowEvent('Completed', current, {
                            stepType: previousStepName,
                            flowType: previousFlowName,
                        });
                    }

                    // check if flow was exited
                    // when user leaves in another flow which is not the next
                    /*
                    if (previousFlowInJourney && previousFlowInJourney.index !== currentFlowInJourney.index - 1) {
                        // As we check if the previous flow was exited, we need to track the prev event
                        trackFlowEvent('Exited', current, {
                            stepType: previousStepName,
                            flowType: previousFlowName,
                        });
                    }
                    */
                }
                setPreviousUserJourney(currentUserJourney)
        }, [trackFlowEvent, trackPageView, previousUserJourney]);

        const onUpdateHistory = useCallback(
            (evt: any) => {
                evt.stopPropagation();
                updateHistory(latestComponentConfig.current);
                handleTracking(latestComponentConfig.current)
            },
            [latestComponentConfig, latestComponentConfig.current, updateHistory, handleTracking],
        );

        const onNavigate = useCallback(
            (evt: any) => {
                onUpdateHistory(evt);
            },
            [onUpdateHistory],
        );

        const onConfigChange = useCallback(
            (evt: any) => {
                setComponentConfig(evt.detail);
                updateHistory(evt.detail);
                handleTracking(evt.detail);
            },
            [setComponentConfig, updateHistory],
        );

        const onUserJourneyExited = useCallback(
            (evt: any) => {
                // journey exit also causes flow exit
                trackFlowEvent('Exited', latestComponentConfig.current);
                // track journey exit
                const { userJourneyName, stepName, flowName } = evt.detail;

                trackEvent('userJourneyExited', {
                    stepType: stepName,
                    flowType: flowName,
                    userJourneyType: userJourneyName,
                });
            },
            [latestComponentConfig, trackFlowEvent, trackEvent],
        );

        const onUserJourneyStarted = useCallback(
            (evt: any) => {
                const { journeyName, CTA } = evt.detail;

                trackEvent('userJourneyStarted', {
                    journeyType: journeyName,
                    CTA,
                });

                // fire flowStarted manually as it wouldn't be catched by history change handling
                trackFlowEvent('Started', latestComponentConfig.current);
            },
            [trackEvent, latestComponentConfig],
        );

        const onUserJourneyCompleted = useCallback(
            (evt: any) => {
                const {
                    contactForm,
                    response,
                    selectedAmountDonation,
                    selectedProjectId,
                    defineAmountDonation,
                    footprint,
                    reductionFootprint,
                    calculation,
                    type
                } = evt.detail;
                    // Track flowCompleted for last flow in userJourney (See commonents onUpdateHistory -> trackFlowCompleted)
                    trackFlowEvent('Completed', latestComponentConfig.current)

                    const compensationData = {
                        userJourneyType: latestComponentConfig.current.currentJourneyName,
                        orderPrice: type === 'b2b' ? calculation.amount : selectedAmountDonation.amountInEuro, // todo: rename variable when multiple currencies are supported
                        calculationFlowFootprint: footprint,
                        reductionFlowFootprint: reductionFootprint,
                        offsetFlowFootprint: defineAmountDonation.partialFootprintInKg,
                        projectId: selectedProjectId,
                        amountType: defineAmountDonation.type,
                        partialPercentage: defineAmountDonation.partialPercentage,
                        customPercentage: defineAmountDonation.customPercentage,
                        paymentType: response.payment_type,
                        isGifted: response.is_gifted,
                        currency: 'EUR', // todo: change when multiple currencies are supported
                        country: contactForm.country,
                    };

                    trackEvent('userJourneyCompleted', compensationData);

                    dispatchCustomEvent(ComponentEventsSupported.COMPONENT_COMPENSATED, compensationData);
            },
            [latestComponentConfig, trackEvent, trackFlowEvent],
        );

        // handle browser-native navigation user interactions
        const onPopState = useCallback((evt: PopStateEvent) => {
            evt.preventDefault();

            const { state } = window.history;
            const pos = state && state.cursor ? state.cursor - 1 : 0;
            const latestStep = state && state.history ? state.history[pos] : undefined;

            if (latestStep) {
                // local history change has happened
                // in this case, we're dispatching an event to inform
                // the component to change it's rendering state according
                // to the step that corresponds to the slug navigated to
                dispatchCustomEvent(ComponentEventsSupported.COMPONENT_CHANGE_STEP, {
                    stepName: latestStep.stepName,
                    flowName: latestStep.flowName,
                });
            }
        }, []);

        const onMailchimpSignUp = useCallback((evt:any) => {
            const { tags }: { tags: Array<string>} = evt.detail;
            track(tags)
        }, [])

        const onFeedback = useCallback(
            (evt: any) => {
                const { feedbackModalOpened, surveyOpened, surveyClosed, feedbackByMail, feedbackModalClosed } = evt.detail
                if (feedbackModalOpened) {
                    trackEvent('feedbackModalOpened')
                }
                if (feedbackModalClosed) {
                    trackEvent('feedbackModalClosed')
                }
                if (surveyOpened) {
                    trackEvent('feedbackFormStarted')
                }
                if (surveyClosed) {
                    trackEvent('feedbackFormClosed')
                }
                if (feedbackByMail) {
                    trackEvent('feedbackFormViaMailRequested')
                }
            }, [trackEvent]
        )

        const onTrackEvent = useCallback(
            (evt: any) => {
                const { name } = evt.detail
                if (name) {
                    console.log('onTrackEvent', name)
                    trackEvent(name)
                }
            }, [trackEvent]
        )

        useEffect(() => {
            window.document.addEventListener(
                ComponentEventsSupported.COMPONENT_STEP_NEXT,
                onNavigate,
            );

            window.document.addEventListener(
                ComponentEventsSupported.COMPONENT_STEP_BACK,
                onNavigate,
            );

            window.document.addEventListener(
                ComponentEventsSupported.COMPONENT_CHANGE_STEP,
                onNavigate,
            );
            window.document.addEventListener(
                ComponentEventsSupported.COMPONENT_INTERNAL_LINK_CLICKED,
                onClickNextToAccountTeaserPage,
            );
            // TODO: refactor: use COMPONENT_EXTERNAL_LINK_CLICKED instead
            window.document.addEventListener(
                ComponentEventsSupported.COMPONENT_PRESSED_TERMS_OF_USE,
                onClickTermsOfUseListener,
            );
            // TODO: refactor: use COMPONENT_EXTERNAL_LINK_CLICKED instead
            window.document.addEventListener(
                ComponentEventsSupported.COMPONENT_PRESSED_LEGAL_NOTICE,
                onClickLegalNoticeListener,
            );
            // TODO: refactor: use COMPONENT_EXTERNAL_LINK_CLICKED instead
            window.document.addEventListener(
                ComponentEventsSupported.COMPONENT_PRESSED_BACK_TO_MAIN_PAGE,
                onClickMainSlug,
            );
            window.document.addEventListener(
                ComponentEventsSupported.COMPONENT_BACK_TO_LANDING_PAGE,
                onClickBackLandingPage,
            )
            window.document.addEventListener(
                ComponentEventsSupported.COMPONENT_CONFIG_CHANGE,
                onConfigChange,
            );
            window.document.addEventListener(
                ComponentEventsSupported.COMPONENT_UPDATE_HISTORY,
                onUpdateHistory,
            );
            window.addEventListener('popstate', onPopState);

            window.document.addEventListener(
                ComponentEventsSupported.COMPONENT_USER_JOURNEY_EXIT,
                onUserJourneyExited,
            );

            window.document.addEventListener(
                ComponentEventsSupported.COMPONENT_USER_JOURNEY_STARTED,
                onUserJourneyStarted,
            );

            window.document.addEventListener(
                ComponentEventsSupported.COMPONENT_USER_JOURNEY_COMPLETED,
                onUserJourneyCompleted,
            );
            window.document.addEventListener(
                ComponentEventsSupported.COMPONENT_MAILCHIMP_SIGNUP,
                onMailchimpSignUp,
            );
            // TODO: refactor to use COMPONENT_TRACK_EVENT
            window.document.addEventListener(
                ComponentEventsSupported.COMPONENT_FEEDBACK,
                onFeedback,
            );
            window.document.addEventListener(
                ComponentEventsSupported.COMPONENT_TRACK_EVENT,
                onTrackEvent,
            );

            return () => {
                window.document.removeEventListener(
                    ComponentEventsSupported.COMPONENT_STEP_NEXT,
                    onNavigate,
                );
                window.document.removeEventListener(
                    ComponentEventsSupported.COMPONENT_BACK_TO_LANDING_PAGE,
                    onClickBackLandingPage,
                );
                window.document.removeEventListener(
                    ComponentEventsSupported.COMPONENT_INTERNAL_LINK_CLICKED,
                    onClickNextToAccountTeaserPage,
                );
                window.document.removeEventListener(
                    ComponentEventsSupported.COMPONENT_STEP_BACK,
                    onNavigate,
                );
                window.document.removeEventListener(
                    ComponentEventsSupported.COMPONENT_CHANGE_STEP,
                    onNavigate,
                );
                // TODO: refactor: use COMPONENT_EXTERNAL_LINK_CLICKED instead
                window.document.removeEventListener(
                    ComponentEventsSupported.COMPONENT_PRESSED_TERMS_OF_USE,
                    onClickTermsOfUseListener,
                );
                // TODO: refactor: use COMPONENT_EXTERNAL_LINK_CLICKED instead
                window.document.removeEventListener(
                    ComponentEventsSupported.COMPONENT_PRESSED_LEGAL_NOTICE,
                    onClickLegalNoticeListener,
                );
                // TODO: refactor: use COMPONENT_EXTERNAL_LINK_CLICKED instead
                window.document.removeEventListener(
                    ComponentEventsSupported.COMPONENT_PRESSED_BACK_TO_MAIN_PAGE,
                    onClickMainSlug,
                );
                window.document.removeEventListener(
                    ComponentEventsSupported.COMPONENT_CONFIG_CHANGE,
                    onConfigChange,
                );
                window.document.removeEventListener(
                    ComponentEventsSupported.COMPONENT_UPDATE_HISTORY,
                    onUpdateHistory,
                );
                window.removeEventListener('popstate', onPopState);
                window.document.removeEventListener(
                    ComponentEventsSupported.COMPONENT_USER_JOURNEY_EXIT,
                    onUserJourneyExited,
                );
                window.document.removeEventListener(
                    ComponentEventsSupported.COMPONENT_USER_JOURNEY_STARTED,
                    onUserJourneyStarted,
                );
                window.document.removeEventListener(
                    ComponentEventsSupported.COMPONENT_USER_JOURNEY_COMPLETED,
                    onUserJourneyCompleted,
                );
                window.document.removeEventListener(
                    ComponentEventsSupported.COMPONENT_MAILCHIMP_SIGNUP,
                    onMailchimpSignUp,
                );
                window.document.removeEventListener(
                    ComponentEventsSupported.COMPONENT_FEEDBACK,
                    onFeedback,
                );
                window.document.removeEventListener(
                    ComponentEventsSupported.COMPONENT_TRACK_EVENT,
                    onTrackEvent,
                );
            };
        }, [
            onNavigate,
            onUpdateHistory,
            onPopState,
            onClickTermsOfUseListener,
            onClickLegalNoticeListener,
            onClickMainSlug,
            onClickBackLandingPage,
            onClickNextToAccountTeaserPage,
            onConfigChange,
            onUserJourneyExited,
            onUserJourneyStarted,
            onUserJourneyCompleted,
            onMailchimpSignUp,
            onFeedback,
            onTrackEvent,
        ]);

        useEffect(() => {
            // inform the component about a website language change
            dispatchCustomEvent(ComponentEventsSupported.COMPONENT_CHANGE_LANGUAGE, {
                language,
            });
        }, [language]);

        return (
            <div
                id={CALCULATOR_COMPONENT_ID}
                className={className}
                style={DEFAULT_CALCULATOR_STYLE}
            />
        );
    },
);
