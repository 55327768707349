import React, { useEffect } from 'react';
import { graphql, useStaticQuery } from 'gatsby';
import { useDetectLanguage } from '../../hook/useDetectLanguage';
import { languageSwitch } from '../../functions/languageSwitch';

const SuccessRouterPage = () => {
    const language = useDetectLanguage();

    const page: { allContentfulSuccessPage: { nodes: Array<{ local: string; slug: string }> } } = useStaticQuery(graphql`
  {
  allContentfulSuccessPage(filter: {name: {eq: "(LandingPage) Success compensated"}}) {
     nodes {
      local: node_locale
      slug
    }
    }
  }
  `);
    useEffect(() => {
        languageSwitch(page.allContentfulSuccessPage.nodes, language)
    })

    // only for routing
    return (
        <>
        </>
    )
}

export default SuccessRouterPage
