import React, { useCallback, useContext, useEffect, useLayoutEffect, useState } from 'react';
import { Drawer } from './Drawer';
import { getClasses } from './Header.jss';
import {newGetClasses} from "./NewHeader.jss"
import { PageContext } from '../layouts/MainLayout';
import { IPageContext } from '../interface/PageContext';
import { navigate } from '../functions/navigate';
import { useLocalStorage } from '../hook/useLocalStorage';
import { IFeatureFlags, FeatureFlags, isInIFrame, brandLink, useViewport, BREAKPOINTS, useOnResize, useForceUpdate, getLocalStorageApi } from '@cpyou/shared/dist';
import { FeatureFlagsContext } from './App';
import Image from './image/Image';
import { useEffectOnce } from 'react-use';
import { useGlobal } from '../store';
import { ComponentEventsSupported } from '@cpyou/shared/dist/enum/ComponentEventsSupported';
import { dispatchCustomEvent } from '../functions/dispatchCustomEvent';
import { getContentLanguageName } from '../functions/getContentLanguageName';
import { StepsSupported } from '@cpyou/component/src/interface/StepsSupported';
import { JourneySupported } from '@cpyou/component/src/interface/JourneySupported';
import { getCurrentStep } from '../functions/getCurrentStep';

export interface IPropsHeader {
  logoUrl?: string;
  newLogoUrl?: string;
  mainSlug: string;
  containerRef: Element;
  hideLogo?: boolean
}

export const Header = (props: IPropsHeader) => {
    const { logoUrl, mainSlug, containerRef, hideLogo } = props;
    const [, setLanguage] = useLocalStorage('language')
    const forceUpdate = useForceUpdate();
    const featureFlags = useContext(FeatureFlagsContext) as IFeatureFlags;
    const [newBrandDesignEnabled] = useState(featureFlags[FeatureFlags.NEW_BRAND_DESIGN]);
    const { language, images, coBrand } = useContext(PageContext) as IPageContext;
    const [{ componentConfig }] = useGlobal();
    const sizeModel = useViewport(BREAKPOINTS, containerRef);
    const newLogoImageName = sizeModel.isMobile || sizeModel.isTablet ? 'NewLogoMobile' : 'NewLogoDesktop';

    useLayoutEffect(() => {
        // eslint-disable-next-line
        return useOnResize(() => {
            forceUpdate();
        }, BREAKPOINTS, containerRef)
    })

    const getDefaultLogoConfig = useCallback(() => ({
        url: brandLink(`/${language}/${mainSlug === '/' ? '' : mainSlug}`, coBrand),
        alt: "logo",
        imageSrc: coBrand ? coBrand.logoImageUrl : newBrandDesignEnabled ? images[newLogoImageName].url : logoUrl,
    }), [mainSlug, logoUrl, language, newBrandDesignEnabled, images, coBrand, newLogoImageName])

    const [localLogoConfig, setLocalLogoConfig] = useState(getDefaultLogoConfig())
    const classes = newBrandDesignEnabled ? newGetClasses() : getClasses();
    const [iFrameModeEnabled, setIFrameModeEnabled] = useState(true);
    const [showBackLinkLogo, setShowBackLinkLogo] = useState(false);

    // re-validate at runtime because server-side rendering throws silently and returns false
    useEffectOnce(() => {
        setIFrameModeEnabled(isInIFrame());
    })

    useEffect(() => {

        if (componentConfig?.logoConfig) {


            const slug = getCurrentStep(componentConfig, componentConfig.language).slug;
            const targetUrl = brandLink(`${getContentLanguageName(componentConfig.language)}/${slug}`, coBrand);

            setLocalLogoConfig({
                url: targetUrl,
                alt: componentConfig.logoConfig.logoAltText,
                imageSrc: componentConfig.logoConfig.logoImageUrl
            })
            setShowBackLinkLogo(true);

        } else {
            setLocalLogoConfig(getDefaultLogoConfig())
            setShowBackLinkLogo(false);
        }
    }, [componentConfig, getDefaultLogoConfig, coBrand])

    useEffect(() => {
        setLanguage(language);
    }, [language, setLanguage])

    getLocalStorageApi().setItem('MAIN_SLUG', brandLink(`/${language}/${mainSlug === '/' ? '' : mainSlug}`, coBrand));

    const resetLogoConfig = useCallback(() => {
        setLocalLogoConfig(getDefaultLogoConfig())

        dispatchCustomEvent(ComponentEventsSupported.COMPONENT_UPDATE_CONFIG, {
            logoConfig: undefined
        })
        setShowBackLinkLogo(false);
    }, [getDefaultLogoConfig]);

    const onLogoClick = useCallback(() => {

        if (coBrand && !showBackLinkLogo) {
            const slug = brandLink(`/${language}/${coBrand.entryPointLink.page.slug}`, coBrand)
            document.location.href = `${window.location.origin}${slug}`
            return;
        }

        // hard reload case
        if (!componentConfig) {
            const pathSplits = document.location.pathname.split('/');
            let resetPath = [];

            // coBrand case or b2b case
            if (pathSplits[1].length != 2 || pathSplits[2] === "b2b") {
                resetPath = [pathSplits[1], pathSplits[2]];
            } else { // non-cobrand, default b2c case
                resetPath = [pathSplits[1]];
            }
            document.location.href = `${window.location.origin}/${resetPath.join('/')}`
            return;
        }

        if (componentConfig.logoConfig &&
            componentConfig?.logoConfig?.target?.page &&
            componentConfig?.logoConfig?.target?.mode === 'STEP_CHANGE') {

            const journeyName = componentConfig.logoConfig.target.page.stepName === StepsSupported.STEP_CONSUMPTION_FOOTPRINT_FAST_TRACK ? JourneySupported.PERSONAL_CARBON_FOOTPRINT_FAST_TRACK 
                                : (componentConfig.logoConfig.target.page.stepName === StepsSupported.STEP_CAR_ONLY_TRANSPORTATION || componentConfig.logoConfig.target.page.stepName === StepsSupported.STEP_CAR_ONLY_UNDERSTAND_RESULT) ? JourneySupported.CAR_ONLY
                                : (componentConfig.logoConfig.target.page.stepName === StepsSupported.STEP_FLIGHTS_ONLY_TRANSPORTATION || componentConfig.logoConfig.target.page.stepName === StepsSupported.STEP_FLIGHTS_ONLY_UNDERSTAND_RESULT) ? JourneySupported.FLIGHTS_ONLY
                                : JourneySupported.PERSONAL_CARBON_FOOTPRINT

            dispatchCustomEvent(ComponentEventsSupported.COMPONENT_CHANGE_STEP, {
                stepName: componentConfig.logoConfig.target.page.stepName,
                flowName: componentConfig.logoConfig.target.page.flowName,
                journeyName
            });

            dispatchCustomEvent(ComponentEventsSupported.COMPONENT_UPDATE_CONFIG, {
                stepName: componentConfig.logoConfig.target.page.stepName,
                flowName: componentConfig.logoConfig.target.page.flowName,
                journeyName
            })

        } else {
            navigate(localLogoConfig.url, { replace: true });
        }

        if(componentConfig.logoConfig &&
            (componentConfig.logoConfig.target.page.stepName === StepsSupported.STEP_CONSUMPTION_FOOTPRINT
                || componentConfig.logoConfig.target.page.stepName === StepsSupported.STEP_CONSUMPTION_FOOTPRINT_FAST_TRACK
                || componentConfig.logoConfig.target.page.stepName === StepsSupported.STEP_FLIGHTS_ONLY_TRANSPORTATION
                || componentConfig.logoConfig.target.page.stepName === StepsSupported.STEP_CAR_ONLY_TRANSPORTATION)) {

            resetLogoConfig();
        }
    }, [componentConfig, localLogoConfig, resetLogoConfig]);

    if (hideLogo) return (
        <>
            <div />
            <Drawer />
        </>
    )

    return (
        <>
            {(!iFrameModeEnabled || showBackLinkLogo) && (
                <>
                    {coBrand &&  !showBackLinkLogo && <img src={localLogoConfig.imageSrc} alt={localLogoConfig.alt} className={classes.logo} onClick={onLogoClick} />}
                    { newBrandDesignEnabled  && showBackLinkLogo && <div style={{
                        backgroundImage: `url("https:${localLogoConfig?.imageSrc}")`,
                        backgroundPosition: 'center',
                        backgroundSize: 'cover',
                        height: '56px',
                        width: '80px',
                        marginLeft: '-20px',
                        cursor: 'pointer'
                    }}
                        onClick={onLogoClick}
                    />}
                    {!coBrand && newBrandDesignEnabled && !showBackLinkLogo && <img src={localLogoConfig.imageSrc} alt={localLogoConfig.alt} className={classes.logo} onClick={onLogoClick} />}
                    {!newBrandDesignEnabled && <Image src={localLogoConfig.imageSrc} alt={localLogoConfig.alt} className={classes.logo} onClick={onLogoClick} />}
                </>
            )}
            {iFrameModeEnabled && !showBackLinkLogo && (
                <img src={'data:image/gif;base64,R0lGODlhAQABAAAAACH5BAEKAAEALAAAAAABAAEAAAICTAEAOw=='} alt="logo" className={classes.logo} />
            )}
            <Drawer />
        </>
    )
}
