"use strict";
exports.__esModule = true;
exports.getColorShades = void 0;
var getColorShades = function (color, lighten, darken) { return ({
    shade50Percent: darken(color, 0.5),
    shade30Percent: darken(color, 0.3),
    shade10Percent: darken(color, 0.1),
    tint30Percent: lighten(color, 0.3),
    tint60Percent: lighten(color, 0.6),
    tint90Percent: lighten(color, 0.9),
    fullColor: color
}); };
exports.getColorShades = getColorShades;
