import React, {
    useCallback, useContext, useEffect, useState,
} from 'react'
import { Drawer as MaterialDrawer } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';
import { useMount, useSessionStorage } from 'react-use';
import { getClasses } from './Drawer.jss';
import { joinClasses } from '../functions/join';
import { GMenu } from '../interface/graphql/GMenu';
import { DrawerMobileMenu } from './DrawerMobileMenu';
import { DrawerDesktopMenu } from './DrawerDesktopMenu';
import { GImage } from '../interface/graphql/GImage';
import { IPageContext } from '../interface/PageContext';
import { PageContext } from '../layouts/MainLayout';
import { navigate } from '../functions/navigate';
import { GLink } from '../interface/graphql/GLink';
import { getStepForSlug } from '../functions/getStepForSlug';
import { getLanguageAgnosticPathname } from '../functions/getLanguageAgnosticPathname';
import { useGlobal } from '../store';
import { getSlugLanguageName } from '../functions/getSlugLanguageName';
import { detectBusinessType } from '../functions/detectBusinessType';
import { BusinessType } from '../interface/BusinessType';
import { SessionStorageKeys } from '../interface/SessionStorageKeys';
import {
    FooterContext, FooterCopyRight, FooterLink, FooterParent,
} from './Footer';
import { IFeatureFlags, FeatureFlags, brandLink, useViewport, BREAKPOINTS } from '@cpyou/shared/dist';
import { FeatureFlagsContext } from './App';
import { getImageUrl } from '../functions/getImageUrl';
import Image from './image/Image';
import { getCurrentStep } from '../functions/getCurrentStep';
import { LayoutContext } from '../context/layoutContext';

export interface IDrawerContext {
    isOnlyMobile: boolean;
    mobileBurgerButton?: GImage;
    mobileCloseButton?: GImage;
    desktopArrow?: GImage;
    showHeader?: boolean;
    menu: Array<GMenu>;
    translation?: {
        languages: Array<{ language: string, locale: string }>
        icon: GImage
    };
    justDonateButton: GLink;
    color: string;
    borderMobileMenu: boolean
}

export const DrawerContext = React.createContext<IDrawerContext>({
    isOnlyMobile: true,
    color: 'white',
    menu: [],
    justDonateButton: {},
    borderMobileMenu: false,
});

const MenuFooter = () => {
    const { copyright, partner, links } = useContext(FooterContext);
    const parentEl = (
        <FooterParent
            partners={partner.map((p) => ({
                externalUrl: p.externalUrl,
                image: getImageUrl(p.image),
            }))}
            isMobile
        />
    )
    const copyrightEl = <FooterCopyRight copyright={copyright} />;
    const linksEl = <FooterLink links={links} isMobile />;

    return (
        <footer style={{ width: 260, backgroundColor: '#F4F4F4', padding: 20 }}>
            {linksEl}
            {parentEl}
            {copyrightEl}
        </footer>
    )
}

export const Drawer = () => {
    const {
        isOnlyMobile, mobileBurgerButton, mobileCloseButton, translation, borderMobileMenu,
    } = useContext(DrawerContext);
    const { locale, localeUrlMap, coBrand } = useContext(PageContext) as IPageContext;
    const [drawerOpen, setDrawerOpen] = useState(false);
    const featureFlags = useContext(FeatureFlagsContext) as IFeatureFlags;
    const [newBrandDesignEnabled] = useState(featureFlags[FeatureFlags.NEW_BRAND_DESIGN]);
    const [{ componentConfig }] = useGlobal();
    const [businessType, setBusinessType] = useSessionStorage(SessionStorageKeys.BUSINESS_TYPE, BusinessType.B2C);
    const [isCalculatorPageActive, setIsCalculatorPageActive] = useState(false);
    const { containerRef } = useContext(LayoutContext);
    const sizeModel = useViewport(BREAKPOINTS, containerRef);
    const classes = getClasses(isOnlyMobile, borderMobileMenu, newBrandDesignEnabled, sizeModel);

    const mobileMenuBtn = newBrandDesignEnabled ? classes.mobileMenuButtonNew : classes.mobileMenuButton
    const desktopMenu = newBrandDesignEnabled ? classes.newDesktopMenu : classes.desktopMenu

    useMount(() => {
        setBusinessType(detectBusinessType());
    })

    const revalidateFooter = useCallback(() => {
        if (document.body.contains((window as any).$climatePartnerYouComponent)) {
            const step = getStepForSlug(componentConfig, getLanguageAgnosticPathname(coBrand));

            if (step && decodeURIComponent(document.location.href).indexOf(step.slug) > -1
                && step.stepName !== 'STEP_SELECT_CALCULATION_FLOW'
                && step.stepName !== 'STEP_SELECT_PERSON_GROUP') {
                setIsCalculatorPageActive(true)
            } else {
                setIsCalculatorPageActive(false)
            }
        } else {
            setIsCalculatorPageActive(false)
        }
    }, [componentConfig, setIsCalculatorPageActive])

    useEffect(() => {
        revalidateFooter();
    })

    const onMobileOpenClick = () => {
        if (!drawerOpen) {
            setDrawerOpen(true)
        }
    }
    const onMobileCloseClick = () => {
        if (drawerOpen) {
            setDrawerOpen(false)
        }
    }
    const handleLanguageChange = async (selectedLanguage: string) => {
        if (locale === selectedLanguage) {
            return;
        }

        if (document.body.contains((window as any).$climatePartnerYouComponent)) {
            const step = getCurrentStep(componentConfig, selectedLanguage)
            const targetPathname = brandLink(`/${getSlugLanguageName(selectedLanguage)}/${step.slug}`, coBrand);
            if (businessType === BusinessType.B2C && step.stepName === 'STEP_PAYMENT_FORM') {
                // hard reload because of PayPal integration
                document.location.href = targetPathname;
            } else {
                // soft navigation
                navigate(targetPathname);
            }
            return;
        }

        if (localeUrlMap && localeUrlMap[selectedLanguage]) {
            await navigate(brandLink(`/${localeUrlMap[selectedLanguage]}`, coBrand), { replace: true }, true);
        }
    }
    return (
        <>
            {/* mobile menu */}
            <Image
                className={joinClasses([classes.mobile, classes.mobileButton, mobileMenuBtn])}
                alt={mobileBurgerButton.alt}
                data-test-id="menu-burger-button"
                src={getImageUrl(mobileBurgerButton)}
                onClick={onMobileOpenClick}
            />
            <MaterialDrawer className={classes.mobile} anchor="right" open={drawerOpen} onClose={onMobileCloseClick}>
                <div>
                    <Image
                        className={joinClasses([classes.mobile, classes.mobileButton, classes.mobileMenuCloseButton])}
                        alt={mobileCloseButton.alt}
                        src={getImageUrl(mobileCloseButton)}
                        onClick={onMobileCloseClick}
                    />
                </div>
                <div className={classes.drawerContainer}>
                    <DrawerMobileMenu />
                    <div className={classes.stickyFooter}>
                        <div className={classes.mobileLanguage}>
                            {translation?.languages.map(
                                (l, index) => {
                                    const active = locale === l.locale;

                                    const languageClass = makeStyles(() => ({
                                        language: {
                                            color: active ? '' : 'grey',
                                            fontWeight: active ? 'bold' : 'normal',
                                            cursor: 'pointer',
                                        },
                                    }))().language;

                                    return (
                                        <span key={l.locale}>
                                            {index !== 0 && <span style={{ marginRight: 15, marginLeft: 15 }}>|</span>}
                                            <span className={languageClass} onClick={() => handleLanguageChange(l.locale)}>
                                                {l.language}
                                            </span>
                                        </span>
                                    )
                                },
                            )}
                        </div>
                        {!newBrandDesignEnabled && <div>
                            <MenuFooter />
                        </div>}
                    </div>
                </div>
            </MaterialDrawer>

            {/* desktop menu */}
            <DrawerDesktopMenu
                className={joinClasses([classes.desktop, desktopMenu])}
                handleLanguageChange={handleLanguageChange}
            />

        </>
    )
}
