import { makeStyles } from '@material-ui/styles';
import { Theme } from '@material-ui/core';
import { ISizeModel } from '@cpyou/shared/dist';

export const getClasses = (isMobileOnly: boolean, borderMobileMenu: boolean, newBrandDesignEnabled: boolean, sizeModel: ISizeModel) => makeStyles(({ breakpoints }: Theme) => {
    let mobile = {};
    let mobileMenuButton = {};
    let desktop = {};

    if (!isMobileOnly) {
        mobile = {
            [breakpoints.up('lg')]: {
                display: 'none',
            },
        };
        desktop = {
            display: 'none',
            [breakpoints.up('lg')]: {
                display: 'flex',
            },
        }

        if (newBrandDesignEnabled) {
            mobile = {
                display: (sizeModel.isMobile || sizeModel.isTablet) ? 'flex' : 'none'
            }

            desktop = {
                display: (sizeModel.isDesktop || sizeModel.isDesktopXL || sizeModel.isDesktopXXL) ? 'flex' : 'none'
            }
        }
    } else {
        desktop = {
            display: 'none',
        }
    }
    if (borderMobileMenu) {
        mobileMenuButton = {
            [breakpoints.up('md')]: {
                marginTop: 45,
                marginRight: 42,
                padding: 5,
                backgroundColor: 'rgba(255, 255, 255, 0.7)',
                borderRadius: 17,
            },
        }
    }
    return {
        mobile: {
            ...mobile,
        },
        desktop: {
            ...desktop,
        },
        desktopMenu: {
            marginTop: 44,
            marginRight: 50,
            float: 'right',
        },
        newDesktopMenu: {

        },
        mobileButton: {
            cursor: 'pointer',
        },
        mobileMenuButton: {
            marginTop: 30,
            marginRight: 10,
            [breakpoints.up('md')]: {
                marginTop: 50,
                marginRight: 47,
            },
            ...mobileMenuButton,
            float: 'right',
        },
        mobileMenuButtonNew: {

        },
        mobileMenuCloseButton: {
            top: 13,
            right: 15,
            position: 'fixed',
        },
        mobileLanguage: {
            marginBottom: 32,
            marginRight: 29,
            marginLeft: 29,
            fontSize: 13,
            cursor: 'pointer',
        },
        drawerContainer: {
            width: 260,
        },
        stickyFooter: {
            position: 'sticky',
            bottom: 0,
            width: '100%',
            backgroundColor: '#fff',
            paddingTop: 10,
        },
    }
})()
