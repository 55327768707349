import React, { useContext } from 'react';
import { Typography } from '@material-ui/core';
import { joinClasses } from '../../../../functions/join';
import { pageToLink } from '../../../../functions/pageToLink';
import { IPageContext } from '../../../../interface/PageContext';
import { PageContext } from '../../../../layouts/MainLayout';
import { getClasses } from './FaqPageContentCategories.jss';
import { navigate } from '../../../../functions/navigate';

export type GFaqCategory = {
    id: string;
    title: string
}

export const FaqPageContentCategories = ({ categories, currentCategory }: { categories: Array<GFaqCategory & { slug: string }>; currentCategory: GFaqCategory; }) => {
    const classes = getClasses();
    const { language, coBrand } = useContext(PageContext) as IPageContext

    return (
        <div className={classes.categoriesWrapper} data-test-id="faq-categories-wrapper">
            {/* <Typography variant='body1'>Einfach Oberbegriff wählen und los</Typography> */}
            <div className={classes.categoriesContainer}>
                {categories.map((c) => (
                    <div key={`category-item-${c.title}`} className={joinClasses({
                        [classes.categoryItem]: true,
                        [classes.activeCategoryItem]: currentCategory?.id === c.id
                    })}>
                        <a onClick={() => navigate(pageToLink(language, coBrand, c))} className={classes.categoryItemLink}><span dangerouslySetInnerHTML={{ __html: c.title }} /></a>
                    </div>
                ))}
            </div>
        </div>
    )
}
