import React from 'react'
import { IStepper } from './StepperLeft'

export const StepperRight = ({ onClick, disabled, color }: IStepper) => (
    <svg
        width={38}
        height={38}
        fill="none"
        cursor="pointer"
        viewBox="0 0 38 38"
        onClick={onClick}
        opacity={disabled ? 0.5 : 1}
    >
        <circle cx="19" cy="19" r="19" fill="white" />
        <path d="M16.625 25.3333L22.1667 19.7917L16.625 14.25" stroke={color} strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
    </svg>
)
