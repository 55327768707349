import { makeStyles } from "@material-ui/core";

export const getClasses = makeStyles(({ palette }) => ({
    image: {

    },
    description: {
        fontSize: 14,
        lineHeight: '14px',
        color: palette.grey[500],
    }
}))