import { makeStyles } from '@material-ui/styles';
import { Theme } from '@material-ui/core';

export const getClasses = makeStyles(({ breakpoints, palette }: Theme) => ({
    homePageHeader: {
        flex: 1,
        display: 'flex',
        [breakpoints.up('md')]: {
            flexDirection: 'row-reverse'
        },
        [breakpoints.down('sm')]: {
            flexDirection: 'column'
        },
        justifyContent: 'space-between'
    },
    image: {
        [breakpoints.down('sm')]: {
            width: '100%'
        },
        [breakpoints.up('md')]: {
            height: '100%'
        },
    },
    headerContent: {
        display: 'flex',
        flexDirection: 'column',
        [breakpoints.up('sm')]: {
            marginRight: "40px"
        },
        [breakpoints.only('xs')]: {
            marginRight: "10px"
        },
    },
    title: {
        [breakpoints.down('sm')]: {
            display: 'inline',
        },
    },
    highlightedTitle: {
        color: palette.primary.main,
        [breakpoints.down('sm')]: {
            display: 'inline',
        },
    },
    subTitle: {
        [breakpoints.up('md')]: {
            fontSize: 24,
            marginTop: "40px",
            marginBottom: "56px"
        },
        [breakpoints.down('sm')]: {
            fontSize: 20,
            marginTop: "16px",
            marginBottom: "16px"
        }
    },
    longTitle: {
        [breakpoints.down('sm')]: {
            display: 'inline',
        },

        [breakpoints.only('md')]: {
            fontSize: "60px"
        },
    },
    longHighlightedTitle: {
        color: palette.primary.main,
        [breakpoints.down('sm')]: {
            display: 'inline',
        },
        [breakpoints.only('md')]: {
            fontSize: "60px"
        },
    },
    calculateButton: {
        backgroundColor: '#f64b54 !important',
        color: 'white',
        marginBottom: '40px',
        width: "300px",
        '&:hover': {
            backgroundColor: 'rgba(246, 75, 84, 0.7) !important'
        }
    },

}));
