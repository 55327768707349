"use strict";
exports.__esModule = true;
exports.brandLink = void 0;
var brandLink = function (link, coBrand) {
    if (coBrand) {
        return "/" + coBrand.slug + (link.startsWith('/') ? '' : '/') + link;
    }
    return link;
};
exports.brandLink = brandLink;
