import { Box, Button, Typography } from "@material-ui/core";
import React, { useContext, useEffect, useLayoutEffect, useState } from "react";
import { IPageContext } from "../../../../interface/PageContext";
import { PageContext } from "../../../../layouts/MainLayout";
import { getClasses } from "./NewHomePageContentJourneySelector.jss";
import { KeyValuesContext } from "../../../../components/KeyValuesContext";
import { useViewport, BREAKPOINTS, useForceUpdate, useOnResize } from "@cpyou/shared/dist";
import { LayoutContext } from "../../../../context/layoutContext";
import { pageToLink } from "../../../../functions/pageToLink";
import { GLink } from "../../../../interface/graphql/GLink";
import { ClassNameMap } from "@material-ui/styles";
import { joinClasses } from "../../../../functions/join";
import Carousel from 'react-material-ui-carousel';
import { ArrowRightIcon } from "../../../../components/icon/ArrowRightIcon";

export interface JourneySelectorProps {
  conlyLink: GLink;
    fonlyLink: GLink;
    offsetAmountLink: GLink;
}

export interface ITile {
  title: string;
  imageUrl: string;
  link: GLink;
  classes: ClassNameMap
}

export interface GoToCalculationButtonProps {
  link?: GLink;
  text: string;
  buttonClass?: string;
  hasArrowRight?: boolean;
}

export const NewHomePageContentJourneySelector = (
  {conlyLink, fonlyLink, offsetAmountLink}: JourneySelectorProps) => {
    const classes = getClasses();
    const keyValues: any = useContext(KeyValuesContext);

    const { containerRef } = useContext(LayoutContext);
    const sizeModel = useViewport(BREAKPOINTS, containerRef);
    const { images } = useContext(PageContext);

    const forceUpdate = useForceUpdate();

    useLayoutEffect(() => {

      // eslint-disable-next-line
      return useOnResize(() => {
          forceUpdate();
      }, BREAKPOINTS, containerRef)
    })
    const tiles: Array<ITile> = [
      {title: keyValues.homePageSelectJourneyFonly, imageUrl: images.homePageJourneySelectFonly.url, link: fonlyLink, classes: classes},
      {title: keyValues.homePageSelectJourneyConly, imageUrl: images.homePageJourneySelectConly.url,  link: conlyLink, classes: classes},
    //   {title: keyValues.homePageSelectJourneyOffset, imageUrl: images.homePageJourneySelectOffsetOnly.url,  link: offsetAmountLink, classes: classes}
    ]

    const paddingHorizontal = sizeModel.isDesktopXXL
      ? sizeModel.gutterWidth + sizeModel.marginWidth + sizeModel.columnWidth
      : sizeModel.marginWidth

    return (
      <Box className={classes.contentJourneySelector} paddingX={`${paddingHorizontal}px`}>
        <Typography variant='h2' className={classes.title}>
          {keyValues.homePageJourneySelectTitle}
        </Typography>

        <Box className={classes.tileContainer} >
          {sizeModel.isTablet && <TabletCarousel tiles={tiles} classes={classes}/>}
          {sizeModel.isMobile && <MobileCarousel tiles={tiles} classes={classes}/>}
          {!sizeModel.isTablet && !sizeModel.isMobile &&
            <DesktopTiles tiles={tiles} classes={classes}/>
          }
        </Box>
      </Box>
    );
};

const Tile = ({tile, classes} : {tile: ITile, classes: ClassNameMap}) => {
  const keyValues: any = useContext(KeyValuesContext);
  return (
    <Box key={ tile.title } className={classes.tile}>
      <img className={classes.tileImage} src={ tile.imageUrl } />
      <Box className={classes.tileContent}>
        <Typography variant="h5" className={classes.tileTitle}>{ tile.title }</Typography>
        <GoToCalculationButton link= { tile.link } text={ keyValues.homePageLetsGo }></GoToCalculationButton>
      </Box>
    </Box>
  )
}

const TabletCarousel = ({tiles, classes}: {tiles: ITile[], classes: ClassNameMap}) => (
  <Carousel
    autoPlay={false}
    animation="slide"
    navButtonsAlwaysInvisible
    className={classes.carousel}
    activeIndicatorIconButtonProps={{ className: classes.carouselIndicator, style: {}}}
  >
    <Box className={classes.carouselTileGroup}>
      <Tile classes={classes} tile={tiles[0]}></Tile>
      <Tile classes={classes} tile={tiles[1]}></Tile>
    </Box>

    <Box className={classes.carouselTileGroup}>
      <Tile classes={classes} tile={tiles[2]}></Tile>
    </Box>
  </Carousel>
)

const MobileCarousel = ({tiles, classes}: {tiles: ITile[], classes: ClassNameMap}) => (
  <Carousel
    autoPlay={false}
    animation="slide"
    navButtonsAlwaysInvisible
    activeIndicatorIconButtonProps={{ className: classes.carouselIndicator, style: {}}}
  >
    {tiles.map(tile => (
      <Box className={classes.carouselTileGroup} key={tile.title}>
        <Tile classes={classes} tile={tile}></Tile>
      </Box>
    ))}
  </Carousel>
)

const DesktopTiles = ({tiles, classes}: {tiles: ITile[], classes: ClassNameMap}) => (
  <>
    {tiles.map(tile => (
      <Tile classes={classes} key={tile.title} tile={tile}></Tile>
    ))}
  </>
)


export const GoToCalculationButton = ({ link, text, buttonClass, hasArrowRight = false } : GoToCalculationButtonProps) => {
  const classes = getClasses();
  const { language, coBrand } = useContext(PageContext) as IPageContext;

  const [buttonPage, setButtonPage] = useState<GLink>();
  useEffect(() => {
      if (link) {
          setButtonPage(link)
      }
  }, [language, link]);

  return (
    <Button
      data-test-id="cta"
      variant="contained"
      className={joinClasses([buttonClass, classes.goToCalculationButton ])}
      href={pageToLink(language, coBrand, buttonPage?.page)
    }>
      <Box display="flex" justifyContent={hasArrowRight ? "space-between" : 'center'} flex={1} alignItems="center">
        <Typography variant="inherit" className={classes.goToCalculationButtonText}>
        {text}
        </Typography>
        {hasArrowRight && <ArrowRightIcon color='white' size={16} />}</Box>
    </Button>
  )
}
