import { makeStyles } from '@material-ui/styles';
import { Theme } from '@material-ui/core';

export const getClasses = makeStyles(({ palette, breakpoints }: Theme) => ({
    questionLinkContainer: {
        listStyleType: 'none',
        padding: 0,
        marginBottom: '80px'
    },
    questionLinkItem: {
        marginTop: 15,
    },
    question: {
        color: palette.primary.main,
        lineHeight: '32px',
        marginBottom: '16px'
    },
    rootContainer: {
        marginBottom: '16px',
        '&:last-child': {
            marginBottom: '0'
        },
        [breakpoints.only('xs')]: {
            margin: '0 -20px 16px'
        },
    },
    questionContainer: {
        backgroundColor: 'rgba(251, 208, 208, 0.2)',
        padding: '40px',
    },
    answerText: {
        overflowWrap: 'break-word',
        lineHeight: '36px'
    },
}));
