import { Theme } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';

export const getClasses = makeStyles(({ palette: { primary: { main } } } : Theme) => ({
    title: {
        display: 'flex',
        fontSize: 14,
        lineHeight: '24px',
        /* identical to box height, or 171% */
        letterSpacing: 0.15,
        color: '#FFFFFF',
        cursor: 'pointer',
    },
    titleArrow: {
        paddingTop: 4,
    },
    titleArrowImg: {
        paddingLeft: 2,
    },
    titleNotLast: {
        paddingRight: 19,
    },
    menu: {
        display: 'flex',
    },
    subTitleMenu: {
        paddingLeft: 12,
        paddingRight: 12,
        paddingTop: 5,
        paddingBottom: 5,
    },
    subTitleMenuList: {
        paddingTop: 5,
        paddingBottom: 5,
    },
    subTitle: {
        fontSize: 13,
        color: 'black',
        textDecoration: 'none',
    },
    translation: {
        paddingLeft: 20,
    },
    buttonJustDonate: {
        backgroundColor: 'transparent',
        border: '2px solid white',
        height: 44,
        marginTop: -10,
        borderRadius: 5,
        color: 'white',
        fontWeight: 700,
        '&:hover': {
            backgroundColor: 'transparent !important',
        },
    },
    buttonJustDonateGreen: {
        border: `2px solid ${main}`,
        color: main,
    },
}))
