"use strict";
exports.__esModule = true;
exports.useFormPersist = void 0;
var react_1 = require("react");
var persist_1 = require("../lib/persist");
var useFormPersist = function (name, _a, _b) {
    var watch = _a.watch, setValue = _a.setValue;
    var _c = _b === void 0 ? {} : _b, storage = _c.storage, _d = _c.exclude, exclude = _d === void 0 ? [] : _d, include = _c.include, onDataRestored = _c.onDataRestored;
    var values = watch(include);
    if (!storage) {
        storage = persist_1.getSessionStorageApi();
    }
    react_1.useEffect(function () {
        var str = storage.getItem(name);
        if (str) {
            var pValues_1 = JSON.parse(str);
            var dataRestored_1 = {};
            Object.keys(pValues_1).forEach(function (key) {
                var shouldSet = !exclude.includes(key);
                if (shouldSet) {
                    dataRestored_1[key] = pValues_1[key];
                    setValue(key, pValues_1[key]);
                }
            });
            if (onDataRestored) {
                onDataRestored(dataRestored_1);
            }
        }
    }, [name]);
    react_1.useEffect(function () {
        storage.setItem(name, JSON.stringify(values));
    });
    return {
        clear: function () { return storage.removeItem(name); }
    };
};
exports.useFormPersist = useFormPersist;
