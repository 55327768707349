import React, { useContext, useLayoutEffect } from "react";
import { PageContext } from "../../../../layouts/MainLayout";
import { useViewport, BREAKPOINTS, ICoBrand, useForceUpdate, useOnResize } from "@cpyou/shared/dist";
import { LayoutContext } from "../../../../context/layoutContext";
import { getClasses } from "./NewHomePageContentProjectSelector.jss";
import { Box, Typography } from "@material-ui/core";
import { KeyValuesContext } from "../../../../components/KeyValuesContext";
import { GLink } from "../../../../interface/graphql/GLink";
import { ClassNameMap } from "@material-ui/styles";
import Carousel from 'react-material-ui-carousel';
import { GImage } from "@cpyou/component/src/interface/Project";
import { GPlace } from "../../../../interface/graphql/GPlace";
import { PlaceMarkerIcon } from "./PlaceMarkerIcon";
import { GRichText } from "../../../../interface/graphql/GRichText";
import { GRichTextForRenderer } from "../../../../interface/graphql/GRichTextForRenderer";
import { IPageContext } from "../../../../interface/PageContext";
import { Link } from "gatsby";
import { pageToLink } from "../../../../functions/pageToLink";
import { GBlogPost } from '../../../../interface/graphql/GBlogPost';


export interface BlogPosts {
  headline: GRichTextForRenderer;
  children: Array<GBlogPost>;
  viewAllBlogPostsLink: GLink;
}

export interface ITile {
  title: string;
  imageUrl: string;
  link: GLink;
  classes: ClassNameMap
}


export interface HowItConpensates {
  childContentfulRichText: BlogPosts;
  projects: Projects;
}

export interface IPropsHomePageContent {
  blogPosts: BlogPosts;
  projects: Projects;
}

export interface Project {
projectName: string;
heroImage: GImage;
heroCertificateImage: GImage;
places: Array<GPlace>;
calculatorPage: { slug: string };
howItCompensates : GRichText;
projectPage: GLink;
}

export interface Projects {
headline: GRichTextForRenderer,
children: Array<Project>
}

export const NewHomePageContentProjectSelector = ({ projects }:{ projects: Projects }) => {
    const classes = getClasses();
    const keyValues: any = useContext(KeyValuesContext);
    const { containerRef } = useContext(LayoutContext);
    const sizeModel = useViewport(BREAKPOINTS, containerRef);
    const { language, coBrand } = useContext(PageContext) as IPageContext;
    const forceUpdate = useForceUpdate();

    useLayoutEffect(() => {

      // eslint-disable-next-line
      return useOnResize(() => {
          forceUpdate();
      }, BREAKPOINTS, containerRef)
    })

    const paddingHorizontal = sizeModel.isDesktopXXL
      ? sizeModel.gutterWidth + sizeModel.marginWidth + sizeModel.columnWidth
      : sizeModel.marginWidth

    return (
      <Box className={classes.contentJourneySelector} paddingX={`${paddingHorizontal}px`}>
        <Typography variant='h2' className={classes.title}>
          {keyValues.homePageProjectsTitle}
        </Typography>

        <Box className={classes.tileContainer} >
          {sizeModel.isTablet && <TabletCarousel language={language} coBrand={coBrand} tiles={projects} classes={classes}/>}
          {sizeModel.isMobile && <MobileCarousel language={language} coBrand={coBrand} tiles={projects} classes={classes}/>}
          {!sizeModel.isTablet && !sizeModel.isMobile &&
            <DesktopTiles language={language} coBrand={coBrand} tiles={projects} classes={classes}/>
          }
        </Box>
      </Box>
    );
};

const Tile = ({tile, classes, language, coBrand} : {tile: Project, classes: ClassNameMap, language: string, coBrand: ICoBrand}) => {
  const keyValues: any = useContext(KeyValuesContext);
  return (
    <Box key={ tile.projectName } className={classes.tile}>
      <Box display='flex' flexDirection="column" position="relative">
        <img className={classes.image} src={ tile.heroImage.file.url } />
        <img className={classes.certImage} src={ tile.heroCertificateImage?.file.url } />
      </Box>
      <Box className={classes.tileContent}>
        <Typography variant="h5" className={classes.tileTitle}>
          { tile.projectName }
        </Typography>
        <Box className={classes.tilePlace}>
        <PlaceMarkerIcon></PlaceMarkerIcon>
          <Typography variant="body2" className={classes.tilePlaceText}>
            { tile.places[0].title }
          </Typography>
        </Box>
        <Box className={classes.tileTeaser}>
          <Typography variant="body2" className={classes.tileTeaserText} dangerouslySetInnerHTML={{ __html: tile.howItCompensates.childContentfulRichText.html }}>
          </Typography>
          { tile.projectPage &&
            <Link
              to={(pageToLink(
                language,
                coBrand,
                {slug: tile.projectPage.page.slug}))}>
                  <Typography variant='body2' className={classes.linkText}>{keyValues.more}</Typography>
            </Link>
          }
        </Box>
      </Box>
    </Box>
  )
}

const TabletCarousel = ({tiles, classes, language, coBrand}: {tiles: Projects, classes: ClassNameMap, language: string, coBrand: ICoBrand}) => (
  <Carousel
    autoPlay={false}
    animation="slide"
    navButtonsAlwaysInvisible
    className={classes.carousel}
    activeIndicatorIconButtonProps={{ className: classes.carouselIndicator, style: {}}}
  >
    <Box className={classes.carouselTileGroup}>
      <Tile language={language} coBrand={coBrand} classes={classes} tile={tiles.children[0]}></Tile>
      <Tile language={language} coBrand={coBrand}  classes={classes} tile={tiles.children[1]}></Tile>
    </Box>

    <Box className={classes.carouselTileGroup}>
    <Tile language={language} coBrand={coBrand}  classes={classes} tile={tiles.children[2]}></Tile>
    </Box>
  </Carousel>
)

const MobileCarousel = ({tiles, classes, language, coBrand}: {tiles: Projects, classes: ClassNameMap, language: string, coBrand: ICoBrand}) => (
  <Carousel
    autoPlay={false}
    animation="slide"
    navButtonsAlwaysInvisible
    className={classes.carousel}
    activeIndicatorIconButtonProps={{ className: classes.carouselIndicator, style: {}}}
  >
    {tiles.children.map(tile => (
        <Box className={classes.carouselTileGroup} key={tile.projectName}>
          <Tile language={language} coBrand={coBrand} classes={classes} key={tile.projectName} tile={tile}></Tile>
        </Box>
    ))}
  </Carousel>
)

const DesktopTiles = ({tiles, classes, language, coBrand}: {tiles: Projects, classes: ClassNameMap, language: string, coBrand: ICoBrand}) => (
  <>
    {tiles.children.map(tile => (
      <Tile language={language} coBrand={coBrand} classes={classes} key={tile.projectName} tile={tile}></Tile>
    ))}
  </>
)
