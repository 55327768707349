"use strict";
exports.__esModule = true;
exports.colorizeLottieAnimation = exports.hexToRgb = void 0;
var hexToRgb = function (hexColor) {
    var result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hexColor);
    return result ? {
        r: parseInt(result[1], 16),
        g: parseInt(result[2], 16),
        b: parseInt(result[3], 16)
    } : null;
};
exports.hexToRgb = hexToRgb;
var exchangeColor = function (lottieValueObject, hexColor) {
    var rgbPrimaryColor = exports.hexToRgb(hexColor);
    var targetColor = {
        r: rgbPrimaryColor.r / 255,
        g: rgbPrimaryColor.g / 255,
        b: rgbPrimaryColor.b / 255
    };
    lottieValueObject.k = [targetColor.r, targetColor.g, targetColor.b];
};
var resolveColorLayer = function (unwrappedAnimation) {
    if (unwrappedAnimation &&
        unwrappedAnimation.layers &&
        Array.isArray(unwrappedAnimation.layers)) {
        var colorLayers = unwrappedAnimation.layers.filter(function (layer) {
            if (layer.nm && typeof layer.nm === 'string' &&
                layer.nm.toLowerCase().indexOf('color') > -1) {
                return true;
            }
        });
        // there should be only one color layer in any Lottie object
        return colorLayers[0] || {};
    }
    return {};
};
var resolveColorDefinition = function (colorLayer, identifier) {
    if (colorLayer && colorLayer.ef && Array.isArray(colorLayer.ef)) {
        var colorDefinitions = colorLayer.ef.filter(function (colorDefinition) {
            if (colorDefinition.nm && typeof colorDefinition.nm === 'string' &&
                colorDefinition.nm.toLowerCase().indexOf(identifier.toLowerCase()) > -1) {
                return true;
            }
        });
        return colorDefinitions[0] || {};
    }
    return {};
};
var resolveColorValueObjectRef = function (unwrappedAnimation, colorNameIdentifier) {
    var colorLayer = resolveColorLayer(unwrappedAnimation);
    var colorDefinitionLayer = resolveColorDefinition(colorLayer, colorNameIdentifier);
    var colorValueObject = resolveColorDefinition(colorDefinitionLayer, 'Color');
    if (colorValueObject) {
        return colorValueObject.v || {};
    }
    return {};
};
var colorizeLottieAnimation = function (unwrappedAnimation, hexColor) {
    // color is given as String, therefore primary and secondary color
    // are replaced by the same color
    if (hexColor && hexColor.length) {
        exchangeColor(resolveColorValueObjectRef(unwrappedAnimation, 'Primary'), hexColor);
        exchangeColor(resolveColorValueObjectRef(unwrappedAnimation, 'Secondary'), hexColor);
    }
    else {
        // allow for separated (duo-color independent changes)
        if (hexColor.primary && hexColor.secondary) {
            exchangeColor(resolveColorValueObjectRef(unwrappedAnimation, 'Primary'), hexColor.primary);
            exchangeColor(resolveColorValueObjectRef(unwrappedAnimation, 'Secondary'), hexColor.secondary);
        }
    }
    return unwrappedAnimation;
};
exports.colorizeLottieAnimation = colorizeLottieAnimation;
