
export type HeaderHeights = {
  mobile: number,
  tablet: number,
  desktop: number,
  desktopXl: number,
  desktopXxl: number
}

export const defaultHeaderHeights : HeaderHeights = {
  mobile: 400,
  tablet: 400,
  desktop: 572,
  desktopXl: 572,
  desktopXxl: 650,
}
