import { makeStyles } from '@material-ui/styles';
import { darken, lighten, Theme } from '@material-ui/core';
import { getColorShades, ISizeModel } from '@cpyou/shared/dist';

export const getClasses = (
    size: ISizeModel,
    backgroundUrl?: string,
    positionBackground?: boolean,
    headerBgContainerHeight?: number,
    headerBgContainerColor?: string
) => makeStyles(({ breakpoints, palette }: Theme) => {

    let image = {}
    let rect = {}


    if (backgroundUrl) {

        /**
         * changed displaying the images from covering the whole screen to just taking what they need,
         * defining width and height relative to the viewPort
         */
        image = {
            background: `url("${backgroundUrl}") no-repeat`,
            backgroundPosition: 'top right',
            backgroundSize: 'auto 100%'
        }
        rect = {
            backgroundColor: 'rgba(0,0,0,0.3)',
        }
    }
    return {
        backgroundRect: {
            width: '100%',
            // minHeight: '100vh',
            ...rect,
        },
        background: {
          width: '100%',
          minHeight: `${headerBgContainerHeight}px !important`,
          ...image,
        //   backgroundColor: 'red',
        /**
         * this isn't needed and was interfering with the display of the background
         * did't have any negative impact to other pages
         */
      },
      header: {
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
          /* left/right padding (12px) is different from margin value because the
             images have inner padding (can be fixed by changing images) */
          padding: size.isMobile || size.isTablet ? '24px 12px' :
            size.isDesktopXL || size.isDesktopXXL ? '47px 49px' : '44px 50px' /* desktop */,
      },
      headerBgContainer: {
          position: 'absolute',
          zIndex: -1,
          width: '100%',
          minHeight: `${headerBgContainerHeight}px`,
          backgroundColor: headerBgContainerColor === 'transparent' ?
            'transparent' : getColorShades(headerBgContainerColor, lighten, darken).tint90Percent
      },
      homePageHeaderInvisibleStretcher: {
          height: 'auto',
          '& > *': {
            height: 'auto !important'
          },
          visibility: 'hidden'
      },
      homePageHeader: {
        display: 'flex',
        position: "absolute",
        top:0,
        right: 0,
        left: 0,
        bottom: 0,
        zIndex: -1,
        backgroundColor: headerBgContainerColor === 'transparent' ?
        'transparent' : getColorShades(headerBgContainerColor, lighten, darken).tint90Percent      }
    }
})()
