import { makeStyles } from '@material-ui/styles';
import { Theme } from '@material-ui/core';

export const getClasses = makeStyles((theme: Theme) => ({
    backLink: {
        fontSize: 18,
        cursor: 'pointer',
        userSelect: 'none',
        textDecorationLine: 'none',
    },
    text: {
        paddingTop: 2,
        color: theme.palette.primary.main,
        fontFamily: theme.typography.fontFamily,
        fontWeight: 700,
    },
}));
