import { getLocalStorageApi } from "@cpyou/shared/dist";

export const useLocalStorage = (key: string): [string | null, (value: string) => void] => {
    const getValue = (): string | null => {
        return getLocalStorageApi().getItem(key);;
    }
    const setValue = (value: string): void => {
        return getLocalStorageApi().setItem(key, value);
    }
    return [getValue(), setValue]
}
