import { GKeyValuePair } from '../interface/graphql/GKeyValuePair';

export const mapKeyValues = (keyValuePair: GKeyValuePair): { [key: string]: string } => {
    const result: { [key: string]: string } = {};
    if (keyValuePair) {
        keyValuePair.forEach((kV) => {
            result[kV.key.key] = kV.value.value;
        });
    }
    return result;
}
