import { makeStyles } from '@material-ui/styles';

export const getClasses = () => makeStyles(() => ({
    linkClass: {
        textDecoration: 'none'
    },
    imageClass: {
        width: 'inherit !important',
        maxWidth: '100%'
    },
}))();
