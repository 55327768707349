import * as React from 'react'
import { PageProps } from 'gatsby';
import { navigate } from '../functions/navigate';
import { useDetectLanguage } from '../hook/useDetectLanguage';

const SLASH = '/'
const PAGE_SLUG_NAME_NOT_FOUND = '404'

const isValidLanguage = (language: string) => language === 'de' || language === 'en'

const NotFoundPage = (props: PageProps) => {
    const language = useDetectLanguage();
    const originalPath = props.location.pathname.split(SLASH)

    let targetPathNotFound = ''
    // TODO: fix me generically: copy over coBrandsList to src so we can require it...
    if (originalPath[1] === 'gestalterbank') {
        if (isValidLanguage(originalPath[1])) {
            targetPathNotFound = [originalPath[1], originalPath[2], PAGE_SLUG_NAME_NOT_FOUND].join(SLASH)
        } else {
            // auto-detect language
            targetPathNotFound = [originalPath[1], language, PAGE_SLUG_NAME_NOT_FOUND].join(SLASH)
        }
    } else if (isValidLanguage(originalPath[1])) {
        // non-cobrand, correct language
        targetPathNotFound = [originalPath[1], PAGE_SLUG_NAME_NOT_FOUND].join(SLASH)
    } else {
        // non-cobrand, incorrect language
        targetPathNotFound = [language, PAGE_SLUG_NAME_NOT_FOUND].join(SLASH)
    }

    navigate(`${SLASH}${targetPathNotFound}`, { replace: true })

    // only for routing
    return (
        <>
        </>
    )
}

export default NotFoundPage
