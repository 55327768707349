import { navigate } from '../functions/navigate';

export const languageSwitch = (nodes: Array<{ local: string; slug: string }>, language: string) => {
    const availablePages = nodes
        .map((n) => ({ language: n.local.substring(0, 2).toLowerCase(), slug: n.slug }))

    const link = availablePages.find((p) => p.language === language);
    if (link) {
        navigate(`/${language}/${link.slug}`, { replace: true }, true);
    }
}
