"use strict";
exports.__esModule = true;
exports.getColumnWidth = exports.ColumnCount = void 0;
var useViewport_1 = require("./useViewport");
exports.ColumnCount = 12;
var getColumnWidth = function (sizeModel, viewportEl) {
    var fullWidth = useViewport_1.getViewportWidth(viewportEl);
    // layout be like (model) -> 11 gutters (|) + 12 columns + margins left and right
    // MARGIN_LEFT C | C | C | C | C | C | C | C | C | C | C | C MARGIN_RIGHT
    var columnAvailableWidth = fullWidth - (exports.ColumnCount - 1) * sizeModel.gutterWidth - 2 * sizeModel.marginWidth;
    return columnAvailableWidth / exports.ColumnCount;
};
exports.getColumnWidth = getColumnWidth;
