import { makeStyles } from '@material-ui/styles';
import { Theme } from '@material-ui/core';

export const getClasses = makeStyles(({ breakpoints }: Theme) => ({
    infoRoot: {
        display:'flex',
        flexDirection: 'row',
        alignItems:'flex-start',
        marginBottom: '20px'
    },
    imageContainer: {
        minWidth: '60px',
        maxWidth: '60px',
        minHeight: '50px',
        maxHeight: '50px',
        display: 'flex',
        marginRight: '10px',
        alignItems: 'flex-start',
        justifyContent: 'center',
        [breakpoints.down('sm')]: {
            minWidth: '50px',
            maxWidth: '50px',
            minHeight: '40px',
            maxHeight: '40px',
        },
    },
    earthImageContainer: {
        minHeight: '60px',
        maxHeight: '60px',
        [breakpoints.down('sm')]: {
            minHeight: '50px',
            maxHeight: '50px',
        },
    },
    icons: {
        [breakpoints.down('sm')]: {
            width: '40px',
        },
        [breakpoints.up('md')]: {
            width: '50px',

        },    },
    earthIcon: {
        [breakpoints.down('sm')]: {
            width: '40px',
            height: '50px'

        },
         [breakpoints.up('md')]: {
            width: '50px',
            height: '60px'

        },
    },
    bannerButton: {
        width: '100%',
        maxHeight: 76,
        display: 'flex',
        justifyContent: 'space-between',
        color: 'white',
        padding: 22,
        marginTop: '24px',
        [breakpoints.down('xs')]: {
            fontSize: 16,
            padding: '22px 10px',
        },
        [breakpoints.down('sm')]: {
            fontSize: 16,
            padding: '22px 10px',
        },
        whiteSpace: 'nowrap',
    },
    imageContent: {
        background: '#FFFFFF',
        boxShadow: '0px 0px 40px rgba(195, 201, 214, 0.3)',
        borderRadius: '10px',
        [breakpoints.down('sm')]: {
            maxWidth: '360px'
        },
    },
    imageJourney: {
        height: '448',
        width: '100%'
    },
    imageContentSectionTwo: {
        padding: '40px',
        display: 'flex',
        flexDirection: 'column',
        [breakpoints.down('md')]: {
            padding: '24px',
        },
    }
}));
