export enum JourneySupported {
    NONE = '',
    PERSONAL_CARBON_FOOTPRINT = 'PERSONAL_CARBON_FOOTPRINT',
    PERSONAL_CARBON_FOOTPRINT_FAST_TRACK = 'PERSONAL_CARBON_FOOTPRINT_FAST_TRACK',
    CAR_ONLY = 'CAR_ONLY',
    FLIGHTS_ONLY = 'FLIGHTS_ONLY',
    JUST_DONATE = 'JUST_DONATE',
    DESIRED_QUANTITY = 'DESIRED_QUANTITY',
    DESIRED_QUANTITY_B2B = 'DESIRED_QUANTITY_B2B',
}
