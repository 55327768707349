import React from 'react';
import { useTheme } from '@material-ui/core/styles';

export interface IArrowRightIconProps {
  size?: number;
  color?: string;
  marginRight?: number
  marginLeft?: number
  marginTop?: number

}

export const ArrowRightIcon = ({ size=16, color, marginRight=0, marginLeft=0, marginTop=0}: IArrowRightIconProps) => {
    const theme = useTheme();

    return (
        <div style={{
            marginRight,
            marginLeft,
            marginTop,
            minWidth: size,
            minHeight: size,
            display: "flex",
        }}>
            <svg width={size} height={size} viewBox="0 0 16 11" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path
                    d="M12.4378 6.38242L1.37955 6.38242C0.925987 6.38242 0.558301 6.02906 0.558301 5.59316C0.558301 5.15727 0.925987 4.8039 1.37955 4.8039L12.4378 4.80391L9.7625 2.23287C9.42575 1.90924 9.42575 1.38452 9.7625 1.06089C10.0993 0.737254 10.6452 0.737254 10.982 1.06089L15.0883 5.00717C15.425 5.33081 15.425 5.85552 15.0883 6.17916L10.982 10.1254C10.6452 10.4491 10.0993 10.4491 9.7625 10.1254C9.42575 9.8018 9.42575 9.27709 9.7625 8.95345L12.4378 6.38242Z"
                    fill={color ? color : theme.palette.primary.main}
                />
            </svg>
        </div>

    )

}
