import React, { DetailedHTMLProps, HTMLProps, ImgHTMLAttributes } from 'react';
import { joinClasses } from '../../functions/join';
import type IImage from './interface/IImage';
import { getClasses } from './Image.jss'

const Image = ({src, className, alt, description, descriptionClassName, ...rest}: IImage & DetailedHTMLProps<ImgHTMLAttributes<HTMLImageElement>, HTMLImageElement>) => {
    const classes = getClasses()
    const desciptionId = description ? `img-${description.replace(' ', '-')}` : null;
    return (
        <>
            <img {...rest} aria-describedby={desciptionId} src={src} className={joinClasses([classes.image, className])} alt={alt}/>
            {description && (
                <span id={desciptionId} className={joinClasses([classes.description, descriptionClassName])}>description</span>
            )}
        </>
    )
}

export default Image