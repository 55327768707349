import React, { useContext, useState } from 'react';
import { useMediaQuery, useTheme } from '@material-ui/core';
import { Link } from 'gatsby';
import { getClasses } from './Footer.jss';
import { GFooter } from '../interface/graphql/GFooter';
import { getImageUrl } from '../functions/getImageUrl';
import { PageContext } from '../layouts/MainLayout';
import { IPageContext } from '../interface/PageContext';
import { pageToLink } from '../functions/pageToLink';
import Image from './image/Image';
import { GImage } from '../interface/graphql/GImage';
import { KeyValuesContext } from './KeyValuesContext';
import { joinClasses } from '@cpyou/component/src/functions/joinClasses';
import { BREAKPOINTS, ISizeModel, useViewport } from '@cpyou/shared/dist';
import { LayoutContext } from '../context/layoutContext';

export interface IPropsFooterLinks {
  links: Array<{ page?: { slug: string; }, title: string }>;
  isMobile?: boolean;
  sizeModel?: ISizeModel;
}

export interface IPropsFooterParent {
  partners: Array<{ externalUrl: string; image?: GImage }>;
  isMobile?: boolean;
  sizeModel?: ISizeModel;
}

export interface IPropsFooterCopyRight {
  copyright?: string;
  sizeModel?: ISizeModel;
}

export const FooterContext = React.createContext<GFooter>({ links: [], partner: [], copyright: '' });

export const FooterCopyRight = ({ copyright, sizeModel }: IPropsFooterCopyRight) => {
    const classes = getClasses(sizeModel)();
    return <div className={classes.copyright}>{copyright}</div>;
}

export const FooterParent = ({ partners, isMobile, sizeModel }: IPropsFooterParent) => {
    const classes = getClasses(sizeModel)();
    // Removed in YW-46
    return null
    return (
        <div className={classes.partners}>
            {partners.map((p) => (
                <a href={p.externalUrl} key={p.externalUrl} target="_blank" rel="noreferrer">
                    <Image
                        className={classes.partnerImg}
                        style={isMobile ? {
                            paddingRight: 20, marginTop: 20, paddingLeft: 0, marginBottom: 20,
                        } : {}}
                        src={getImageUrl(p.image)}
                        alt={p.image.alt}
                    />
                </a>
            ))}
        </div>
    )
}

export const FooterLink = ({ links, isMobile, sizeModel }: IPropsFooterLinks) => {
    const { language, coBrand } = useContext(PageContext) as IPageContext;
    const keyValues = useContext(KeyValuesContext)

    const openPrivacySettings = () => {
        if(typeof window !== 'undefined' && (window as any).UC_UI) {
            (window as any).UC_UI.showSecondLayer()
        }
    }

    const classes = getClasses(sizeModel)();
    return (
        <div className={classes.linkContainer}>
            {links.map((link) => (
                <Link data-test-id={`footer-link-${link.page.slug}`} className={classes.link} style={isMobile ? { fontWeight: 700, opacity: 0.6 } : {}} to={pageToLink(language, coBrand, link.page)} key={link.title}>
                    {link.title}
                </Link>
            ))}
            <button type="button" role={'link'} onClick={openPrivacySettings} className={joinClasses(classes.noButtonStyles, classes.link)} style={isMobile ? { fontWeight: 700, opacity: 0.6 } : {}}>{keyValues['Privacy Settings']}</button>
        </div>
    )
}

export const Footer = () => {
    const { containerRef } = useContext(LayoutContext);
    const [sizeModel] = useState(useViewport(BREAKPOINTS, containerRef));
    const classes = getClasses(sizeModel)();
    const { breakpoints } = useTheme();
    const isDesktop = useMediaQuery(breakpoints.up('md'))
    const isMobile = useMediaQuery(breakpoints.down('xs'))

    const { copyright, partner, links } = useContext(FooterContext);
    const parentEl = (
        <FooterParent sizeModel={sizeModel} partners={partner.map((p) => ({
            externalUrl: p.externalUrl,
            image: p.image,
        }))}
        />
    )
    const copyrightEl = <FooterCopyRight copyright={copyright} sizeModel={sizeModel} />;
    const linksEl = <FooterLink links={links} sizeModel={sizeModel} />;

    if (isDesktop) {
        return (
            <footer className={classes.main}>
                <div style={{ width: '50%' }}>
                    {linksEl}
                    {copyrightEl}
                </div>
                <div style={{ width: '50%' }}>
                    {parentEl}
                </div>
            </footer>
        )
    }
    return (
        <footer className={classes.main}>
            <div style={{width: isMobile ? '100%' : '50%'}}>
                {linksEl}
                {copyrightEl}
                {parentEl}
            </div>
        </footer>
    )
}
