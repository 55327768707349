import { isInIFrame } from "@cpyou/shared/dist";

export const dispatchCustomEvent = (name: string, data: any) => {
    const customEvent = document.createEvent('CustomEvent');
    customEvent.initCustomEvent(name, true, true, data);
    window.document.dispatchEvent(customEvent);

    // also transmit the data via iframe events
    if (isInIFrame() && window.parent) {
        window.parent.postMessage(JSON.stringify({
            eventName: name,
            eventData: data || null
        }), '*')
    }
}
