"use strict";
exports.__esModule = true;
exports.isInIFrame = void 0;
var isInIFrame = function () {
    try {
        return window.self !== window.top;
    }
    catch (e) {
        return false;
    }
};
exports.isInIFrame = isInIFrame;
