import { Mixpanel } from 'mixpanel-browser';

// always get the current mixpael window object. User could revoke his tracking permission.
export const getMixpanel = () => (typeof window !== 'undefined') ? (window as any).mixpanel as Mixpanel : undefined

// function to track an Event in Mixpanel (can later be extended to multiple tracking providers)
const trackEvent = (event: string, properties?: { [key: string]: any }) => {
  const mixpanel = getMixpanel();

  if (mixpanel) {
    mixpanel.track(event, properties)
  }
}


// track page view by sending normal event with window.location as properties. (Mixpanel´s default page tracking is disabled)
const trackPageView = () => trackEvent('pageViewed', JSON.parse(JSON.stringify(window.location)))

// Hook providing all important tracking functions
export const useMixpanel = () => {
  return {
    mixpanel: getMixpanel(),
    trackEvent,
    trackPageView
  }
}
