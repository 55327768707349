import { getMixpanel } from './useMixpanel';
import type { Mixpanel } from 'mixpanel-browser';
import { Md5 } from 'ts-md5/dist/md5';

export const useAlias = () => {

    const setAlias = (alias: string, aliasType: 'email' | 'uuid' | 'userId'): Mixpanel => {

        const mixpanel = getMixpanel();

        if (!mixpanel) return;

        let aliasToTrack = alias;
        if (aliasType === 'email') {
            // Mailchimp-compatible and GDPR conform user aliasing for email
            aliasToTrack = Md5.hashStr(alias.toLowerCase()) as string;
        }
        mixpanel.alias(aliasToTrack);

        mixpanel.people.set_once('initialAliasType', aliasType);
        return mixpanel;
    }

    return {
        setAlias,
    };
}
