"use strict";
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
exports.__esModule = true;
exports.getColorPalette = void 0;
var ColorPalette_1 = require("../enum/ColorPalette");
var getColorShades_1 = require("./getColorShades");
var getColorPalette = function (featureFlags, coBrand, lighten, darken) {
    var primaryColor = featureFlags.newBrandDesign ? ((coBrand === null || coBrand === void 0 ? void 0 : coBrand.primaryColor) ? coBrand === null || coBrand === void 0 ? void 0 : coBrand.primaryColor : ColorPalette_1.ColorPalette.NEW_BRAND_PRIMARY) : ColorPalette_1.ColorPalette.PRIMARY;
    var secondaryColor = featureFlags.newBrandDesign ? ((coBrand === null || coBrand === void 0 ? void 0 : coBrand.secondaryColor) ? coBrand === null || coBrand === void 0 ? void 0 : coBrand.secondaryColor : ColorPalette_1.ColorPalette.NEW_SECONDARY) : ColorPalette_1.ColorPalette.SECONDARY;
    return {
        primary: __assign(__assign({}, getColorShades_1.getColorShades(primaryColor, lighten, darken)), { fullColor: primaryColor }),
        secondary: __assign(__assign({}, getColorShades_1.getColorShades(secondaryColor, lighten, darken)), { fullColor: secondaryColor }),
        backgroundColor: ColorPalette_1.ColorPalette.BACKGROUND,
        heatingRed: ColorPalette_1.ColorPalette.HEATING_RED,
        energyYellow: ColorPalette_1.ColorPalette.ENERGY_YELLOW,
        foodGreen: ColorPalette_1.ColorPalette.FOOD_GREEN,
        consumptionMint: ColorPalette_1.ColorPalette.CONSUMPTION_MINT,
        flightPurple: ColorPalette_1.ColorPalette.FLIGHT_PURPLE,
        transportBlue: ColorPalette_1.ColorPalette.TRANSPORT_BLUE,
        darkGrey: ColorPalette_1.ColorPalette.DARK_GREY,
        grey: ColorPalette_1.ColorPalette.GREY,
        lightGrey: ColorPalette_1.ColorPalette.LIGHT_GREY,
        negativeSpace: ColorPalette_1.ColorPalette.NEGATIVE_SPACE
    };
};
exports.getColorPalette = getColorPalette;
