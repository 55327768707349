import React from 'react';
import { useKeyFunction } from '../hook/useKeyFunction';

export interface IKeyProps {
  children: string;
  options?: { [variableName: string]: string };
}

export const Key = ({ children, options = {} }: IKeyProps) => {
    const valueFun = useKeyFunction();
    return (
        <>
            {valueFun(children, options)}
        </>
    )
}
