import * as React from 'react';
import { useEffect, useLayoutEffect, useContext, useState } from 'react';

import { graphql, PageProps } from 'gatsby';
import {
    Button,
    Box,
    Typography,
} from '@material-ui/core';
import { GFooter } from '../../interface/graphql/GFooter';
import { GImage } from '../../interface/graphql/GImage';
import { GSeo } from '../../interface/graphql/GSeo';
import { PageContext } from '../../layouts/MainLayout';
import { GHeader } from '../../interface/graphql/GHeader';
import { getImageUrl } from '../../functions/getImageUrl';
import { App } from '../../components/App';
import { GRichText } from '../../interface/graphql/GRichText';
import { getClasses } from './LandingPageTemplate.jss';
import { mapKeyValues } from '../../functions/mapKeyValues';
import { IPageContext } from '../../interface/PageContext';
import { ArrowRightIcon } from '../../components/icon/ArrowRightIcon';
import { pageToLink } from '../../functions/pageToLink';
import { GLink } from '../../interface/graphql/GLink';
import Image from '../../components/image/Image';
import { NewMainBrandLayout } from '../../layouts/NewMainBrandLayout';
import { BREAKPOINTS, ISizeModel, useForceUpdate, useOnResize, useTwoPassRender, useViewport } from '@cpyou/shared/dist';
import { Key } from '../../components/Key';
import { joinClasses } from "../../functions/join";

export interface IPropsLandingPageTemplate {
  allContentfulKeyValue: any;
  contentfulLandingPage: {
    locale: string;
    label?: 'Flights Only' | 'Car Only';
    header: GHeader;
    background: GImage;
    backgroundMobile: GImage;
    backgroundCertificateImage: GImage;
    footer: GFooter;
    seo: GSeo;
    title: GRichText;
    body: GRichText;
    journeyButton: GLink;
  };
}

interface IPropsPageContent {
  header: GHeader;
  label: 'Flights Only' | 'Car Only' | 'Desired Quantity' | 'Desired Quantity B2B';
  background: GImage;
  backgroundMobile: GImage;
  backgroundCertificateImage: GImage;
  title: GRichText;
  body: GRichText;
  journeyButton: GLink;
}

const PageContent = (props: IPropsPageContent) => {
    const {
        header,
        backgroundCertificateImage,
        journeyButton,
        label,
    } = props;
    const classes = getClasses();
    const { language, coBrand, images } = useContext(PageContext) as IPageContext;

  const [containerRef, ] = useState<Element>();
  const sizeModel = useViewport(BREAKPOINTS, containerRef);

  const [layoutContainerSpace, setLayoutContainerSpace] = useState({left: 0, center: 0, right: 0})
  const [selectImagePerJourney, setSelectImagePerJourney] = useState<string>('')

  useEffect(()=> {
    switch (label) {
      case 'Desired Quantity B2B':
      case 'Desired Quantity':
        setSelectImagePerJourney(images['MixingBothProjects'].url)
        break;
      case 'Flights Only':
        if(sizeModel.isMobile || sizeModel.isTablet){
          setSelectImagePerJourney(images['flightsOnlyImageMobile'].url)
        } else {
          setSelectImagePerJourney(images['flightsOnlyImageDesktop'].url)
        }
        break;
        case 'Car Only':
        if(sizeModel.isMobile || sizeModel.isTablet){
          setSelectImagePerJourney(images['carOnlyImageMobile'].url)
        } else {
          setSelectImagePerJourney(images['carOnlyImageDesktop'].url)
        }
        break;
      default:
        break;
    }
  }, [label, sizeModel])

  const forceUpdate = useForceUpdate();

  const calcLayoutSpace = (sizeModel: ISizeModel) => {
    if (sizeModel.isMobile || sizeModel.isTablet || sizeModel.isDesktop) {
        setLayoutContainerSpace({left: 0, center: 12, right: 0})
    }
    else {
        setLayoutContainerSpace({left: 1, center: 10, right: 1})
    }
    forceUpdate();
  }
  const hasBeenTwoPassRendered = useTwoPassRender();

  useLayoutEffect(() => {
    // initial run
    calcLayoutSpace(sizeModel)

    // on resize run
    // eslint-disable-next-line
    return useOnResize(calcLayoutSpace, BREAKPOINTS)
  }, [sizeModel.viewportWidth]);

   if (!hasBeenTwoPassRendered) {
        return null;
    }
  const headerJourney = (
    <>
      <Typography variant='h1'>
        <Key>We all impact the Climate.</Key>
      </Typography>
      <Typography variant='h1' color='primary'>
        {label === 'Desired Quantity B2B'? (
          <Key>Sie ab jetzt zum Guten.</Key>
        ): (
          <Key>You do for the better from now on.</Key>
        )}
      </Typography>
    </>
  )

  const infoContent = (
    <>
      <div className={classes.infoRoot}>
        <div className={classes.imageContainer}>
          <Image
            src={images['iconStaticCalculator'].url}
            alt='iconStaticCalculator'
            className={classes.icons}
          />
        </div>

        <Typography variant='h5' >
          {label === 'Desired Quantity B2B' ? (
            <Key>Wählen Sie Ihre Wunschmenge CO₂</Key>
          ): (
            <Key>Choose your desired amount of carbon</Key>
          )}
        </Typography>
      </div>
      <div className={classes.infoRoot}>
        <div className={joinClasses(classes.imageContainer, classes.earthImageContainer)}>
          <Image
            src={images['iconStaticEarth'].url}
            alt='iconStaticCalculator'
            className={classes.earthIcon}
          />
        </div>
        <Typography variant='h5'>
          <Key>Secure carbon savings through certified projects</Key>
        </Typography>
      </div>
      <div className={classes.infoRoot}>
        <div className={classes.imageContainer}>
          <Image
            src={(label === 'Desired Quantity B2B' || label === 'Desired Quantity') ? images['iconStaticFunding'].url : images['iconStaticCommunication'].url}
            alt='iconStaticCalculator'
            className={classes.icons}
          />
        </div>
        <Typography variant='h5'>
          <Key>Charitable and transparent use of funds</Key>
        </Typography>
      </div>
    </>
  )

  const imageContent = (
    <div className={classes.imageContent}>
      <Image
        src={selectImagePerJourney}
        alt={backgroundCertificateImage.alt}
        className={classes.imageJourney}
      />
      <div className={classes.imageContentSectionTwo}>
        <Typography variant='h5'>
          {label === 'Desired Quantity B2B' ? (
            <Key>Gleichen Sie Ihre CO₂- Emissionen durch die Förderung von Klima-schutzprojekten aus</Key>
          ): label === 'Desired Quantity' ? (
            <Key>Offset your carbon emissions by promoting carbon offset projects</Key>
          ): label === 'Car Only' ?(
            <Key>Calculate your car emissions and become climate neutral</Key>
          ): (
            <Key>Calculate your flight emissions and become climate neutral</Key>
          )}
        </Typography>
        <Button
            color="secondary"
            variant="contained"
            className={classes.bannerButton}
            href={pageToLink(
                language,
                coBrand,
                journeyButton?.page,
                journeyButton.search,
            )}
        >

              <span>{journeyButton?.title}</span>
              <ArrowRightIcon color={coBrand ? coBrand.primaryColor : 'white'} />
        </Button>
      </div>
    </div>
  )

    // fetch an instance of the serviceNg client
    return (
        <NewMainBrandLayout
            header={{ mainSlug: header.mainSlug }}
            logoUrl={getImageUrl(header.logo)}
        >
            <Box role='mainContainer' display='flex' flexDirection='row' marginTop={(sizeModel.breakpointSize === 'mobile' || sizeModel.breakpointSize === 'tablet') ? '0' : '25px'}>
              {sizeModel.isDesktopXXL ? (
                <>
                  <Box role='outerSpaceLeft' width={layoutContainerSpace.left * sizeModel.columnWidth + layoutContainerSpace.left * sizeModel.gutterWidth}/>
                  <Box role='content' display='flex' flexDirection='row' width={layoutContainerSpace.center * sizeModel.columnWidth + (layoutContainerSpace.center - 1) * sizeModel.gutterWidth}>
                    <Box role='headerContent' display='flex' flexDirection='row' width={6 * sizeModel.columnWidth + 6 * sizeModel.gutterWidth}>
                      <Box role='headerContent' display='flex' flexDirection='column'>
                        {headerJourney}
                        <Box role='infoContent' display='flex' flexDirection='column' marginTop='40px' width={3 * sizeModel.columnWidth + 3 * sizeModel.gutterWidth}>
                          {infoContent}
                        </Box>
                        <Box marginTop='37px' role='backgroundCertificateImage' width={2 * sizeModel.columnWidth + 2 * sizeModel.gutterWidth}>
                          <Image src={getImageUrl(backgroundCertificateImage)} alt={backgroundCertificateImage.alt} />
                        </Box>
                      </Box>
                    </Box>
                    <Box role='outerSpaceCenter' width={sizeModel.columnWidth +  sizeModel.gutterWidth}/>
                    <Box role='imageContent' width={3 * sizeModel.columnWidth + 3 * sizeModel.gutterWidth }>
                      {imageContent}
                    </Box>
                  </Box>
                  <Box role='outerSpaceRight' width={layoutContainerSpace.right * sizeModel.columnWidth + layoutContainerSpace.right * sizeModel.gutterWidth}/>
                </>
              ): sizeModel.isDesktopXL ? (
                <>
                  <Box role='outerSpaceLeft' width={layoutContainerSpace.left * sizeModel.columnWidth + layoutContainerSpace.left * sizeModel.gutterWidth}/>
                  <Box role='content' display='flex' flexDirection='row' width={layoutContainerSpace.center * sizeModel.columnWidth + (layoutContainerSpace.center - 1) * sizeModel.gutterWidth}>
                    <Box role='headerContent' display='flex' flexDirection='row' width={6 * sizeModel.columnWidth + 6 * sizeModel.gutterWidth}>
                      <Box role='headerContent' display='flex' flexDirection='column'>
                        {headerJourney}
                        <Box role='infoContent' display='flex' flexDirection='column' marginTop='40px' width={4 * sizeModel.columnWidth + 4 * sizeModel.gutterWidth}>
                          {infoContent}
                        </Box>
                        <Box marginTop='37px' role='backgroundCertificateImage' width={2 * sizeModel.columnWidth + 2 * sizeModel.gutterWidth}>
                          <Image src={getImageUrl(backgroundCertificateImage)} alt={backgroundCertificateImage.alt} />
                        </Box>
                      </Box>
                    </Box>
                    <Box role='outerSpaceCenter' width={sizeModel.columnWidth +  sizeModel.gutterWidth}/>
                    <Box role='imageContent' width={4 * sizeModel.columnWidth + 4 * sizeModel.gutterWidth }>
                      {imageContent}
                    </Box>
                  </Box>
                  <Box role='outerSpaceRight' width={layoutContainerSpace.right * sizeModel.columnWidth + layoutContainerSpace.right * sizeModel.gutterWidth}/>
                </>
              ): sizeModel.isDesktop ? (
                <Box role='content' display='flex' flexDirection='row' width={layoutContainerSpace.center * sizeModel.columnWidth + (layoutContainerSpace.center - 1) * sizeModel.gutterWidth}>
                  <Box role='headerContent' display='flex' flexDirection='row' width={7 * sizeModel.columnWidth + 7 * sizeModel.gutterWidth}>
                    <Box role='headerContent' display='flex' flexDirection='column'>
                      {headerJourney}
                      <Box role='infoContent' display='flex' flexDirection='column' marginTop='40px' width={5 * sizeModel.columnWidth + 5 * sizeModel.gutterWidth}>
                        {infoContent}
                      </Box>
                      <Box marginTop='37px' role='backgroundCertificateImage' width={2 * sizeModel.columnWidth + 2 * sizeModel.gutterWidth}>
                        <Image src={getImageUrl(backgroundCertificateImage)} alt={backgroundCertificateImage.alt} />
                      </Box>
                    </Box>
                  </Box>
                  <Box role='outerSpaceCenter' width={sizeModel.columnWidth +  sizeModel.gutterWidth}/>
                  <Box role='imageContent' width={4 * sizeModel.columnWidth + 4 * sizeModel.gutterWidth }>
                    {imageContent}
                  </Box>
                </Box>
              ): (
                <Box role='content' display='flex' flexDirection='column' width={layoutContainerSpace.center * sizeModel.columnWidth + (layoutContainerSpace.center - 1) * sizeModel.gutterWidth}>
                  <Box role='headerContent' display='flex' flexDirection='column' width='100%'>
                    {headerJourney}
                    <Box role='imageContent' marginTop='24px' width='100%'>
                      {imageContent}
                    </Box>
                    <Box marginTop='24px' role='backgroundCertificateImage' width='100%'>
                      <Image src={getImageUrl(backgroundCertificateImage)} alt={backgroundCertificateImage.alt} />
                    </Box>
                    <Box role='infoContent' maxWidth='360px' display='flex' flexDirection='column' marginTop='24px' marginBottom='40px' width='100%'>
                      {infoContent}
                    </Box>
                  </Box>
                </Box>
              )}
            </Box>
        </NewMainBrandLayout>
    );
};

const LandingPageTemplate = (props: PageProps<IPropsLandingPageTemplate>) => {
    const {
        data: { contentfulLandingPage, allContentfulKeyValue },
        pageContext,
    } = props;

    const {
        seo,
        background,
        backgroundMobile,
        backgroundCertificateImage,
        header,
        footer,
        title,
        body,
        journeyButton,
        label,
    } = contentfulLandingPage;

    const keyValues = mapKeyValues(allContentfulKeyValue.nodes);

    return (
        <App
            pageContext={pageContext as any}
            seoContext={seo}
            header={header}
            keyValuesContext={keyValues}
            footerContext={footer}
        >
            <PageContent
                header={header}
                background={background}
                backgroundMobile={backgroundMobile}
                backgroundCertificateImage={backgroundCertificateImage}
                body={body}
                title={title}
                journeyButton={journeyButton}
                label={label!}
            />
        </App>
    );
};

export default LandingPageTemplate;

export const queryPage = graphql`
  query NewContentfulLandingPageByLocale($slug: String, $locale: String) {
    allContentfulKeyValue(filter: { node_locale: { eq: $locale } }) {
      nodes {
        key {
          key
        }
        locale: node_locale
        value {
          value
        }
      }
    }
    contentfulLandingPage(slug: { eq: $slug }, node_locale: { eq: $locale }) {
      locale: node_locale
      label
      title: childContentfulLandingPageTitleRichTextNode {
        childContentfulRichText {
          html
        }
      }
      body: childContentfulLandingPageContextRichTextNode {
        childContentfulRichText {
          html
        }
      }
      background {
        ...ImageFragment
      }
      backgroundMobile {
        ...ImageFragment
      }
      backgroundCertificateImage {
        ...ImageFragment
      }
      journeyButton {
        ...LinkFragment
      }
      footer {
        ...FooterFragment
      }
      seo {
        ...SeoFragment
      }
      header {
        ...HeaderFragment
      }
    }
  }
`;
