import { IActions } from './actions';

export interface IStoreState {
    componentConfig: any;
}

export interface IStore extends IStoreState {
    actions: IActions;
    setState(state: Partial<IStoreState>): IStoreState;
}

export const initialState: IStoreState = {
    componentConfig: undefined,
};
