import React, {
    CSSProperties,
    useContext,
    useEffect,
    useLayoutEffect,
    useState,
} from "react";
import { Box, Typography } from "@material-ui/core";

import { getImageUrl } from "../functions/getImageUrl";

import Image from "../components/image/Image";
import { BREAKPOINTS, useForceUpdate, useViewport } from "@cpyou/shared/dist";
import { LayoutContext } from "../context/layoutContext";
import { GImage } from "../interface/graphql/GImage";

export enum ContentImageType {
    "DEFAULT" = "default",
    "BLOG_POST_IMAGE" = "BLOG_POST_IMAGE",
}
function ContentImage({
    image,
    contentImageType,
    customClass
}: {
    image: GImage;
    contentImageType?: ContentImageType;
    customClass?: string
}) {
    const imageUrl = getImageUrl(image);
    const { containerRef } = useContext(LayoutContext);
    const sizeModel = useViewport(BREAKPOINTS, containerRef);
    const [imageStyle, setImageStyle] = useState<CSSProperties>({
        width: "360px",
        marginLeft: undefined,
        marginRight: undefined,
        objectFit: undefined,
    });

    useLayoutEffect(() => {
        if (contentImageType === ContentImageType.BLOG_POST_IMAGE)
            sizeModel.isMobile
                ? setImageStyle({
                      width: "100vw",
                      marginLeft: -sizeModel.marginWidth,
                      marginRight: -sizeModel.marginWidth,
                      objectFit: "contain",
                  })
                : sizeModel.isTablet
                ? setImageStyle({
                      width: "100%",
                      marginLeft: undefined,
                      marginRight: undefined,
                      objectFit: "contain",
                  })
                : setImageStyle({
                      width: "360px",
                      marginLeft: undefined,
                      marginRight: undefined,
                      objectFit: "contain",
                  });
        if (contentImageType === ContentImageType.DEFAULT)
            sizeModel.isMobile
                ? setImageStyle({
                      width: "100vw",
                      marginLeft: -sizeModel.marginWidth,
                      marginRight: -sizeModel.marginWidth,
                      objectFit: undefined,
                  })
                : setImageStyle({
                      width: "100%",
                      marginLeft: undefined,
                      marginRight: undefined,
                      objectFit: undefined,
                  });
    }, [sizeModel.viewportWidth]);

    const imageSubtitleStyle = {
        marginTop: "20px",
    };
    if (!imageUrl) return null;

    return (
        <Box
            marginTop={
                contentImageType === ContentImageType.DEFAULT
                    ? "80px"
                    : undefined
            }
            display="flex"
            maxWidth="100%"
            height="auto"
            flexDirection="column"
            style={{ borderWidth: 1 }}
        >
            <>
                <Image alt={image.alt} src={imageUrl} style={imageStyle}  className={customClass}/>
                {contentImageType === ContentImageType.DEFAULT ? (
                    <Typography
                        variant="subtitle1"
                        align="center"
                        style={imageSubtitleStyle}
                    >
                        {image.description}
                    </Typography>
                ) : null}
            </>
        </Box>
    );
}

export default React.memo(ContentImage);
