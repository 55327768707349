"use strict";
exports.__esModule = true;
exports.cleanStorage = void 0;
var persist_1 = require("./persist");
var cleanStorage = function (mode, keysToKeep) {
    if (keysToKeep === void 0) { keysToKeep = []; }
    var storageApi = persist_1.getStorageApi(mode);
    var thirdPartyData = {};
    var dataKept = {};
    // remember data to keep
    keysToKeep.forEach(function (key) {
        dataKept[key] = storageApi[key];
    });
    for (var i = 0; i < storageApi.length; i++) {
        var key = storageApi.key(i);
        var value = storageApi.getItem(key);
        // _ starting keys are used by tracking scripts; uc_* is used by Usercentrics
        if (key.startsWith('_') || key.startsWith('uc_')) {
            thirdPartyData[key] = value;
        }
    }
    // clear storage
    storageApi.clear();
    // re-apply third-party data
    for (var key in thirdPartyData) {
        storageApi.setItem(key, thirdPartyData[key]);
    }
    // re-apply data to keep
    for (var key in dataKept) {
        storageApi.setItem(key, dataKept[key]);
    }
    return {
        thirdPartyData: thirdPartyData,
        dataKept: dataKept
    };
};
exports.cleanStorage = cleanStorage;
