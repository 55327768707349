import { makeStyles } from '@material-ui/styles';

export const getClasses = makeStyles(() => ({
    background: {
        display: 'flex',
        flexDirection: 'column',
    },
    backgroundRect: {
        flex: 1,
        display: 'flex',
        flexDirection: 'column',
    },
}));