import React, { useContext } from 'react'
import { Helmet } from 'react-helmet'
import { getImageUrl } from '../functions/getImageUrl';
import { GSeo } from '../interface/graphql/GSeo';

export const SeoContext = React.createContext<GSeo>({ title: '' });

const Seo = () => {
    const seo = useContext(SeoContext);

    const { title } = seo;
    const { titleTemplate } = seo;
    const { description } = seo;
    const imageUrl = getImageUrl(seo.image);
    const ogUrl = '';
    const ogLocale = '';
    const ogTitle = seo.ogTitle || title;
    const ogDescription = seo.ogDescription || description;
    const ogImageUrl = getImageUrl(seo.ogImage) || imageUrl;
    const { ogType } = seo;
    const { twitterUsername } = seo;
    const twitterTitle = seo.twitterTitle || title;
    const twitterDescription = seo.twitterDescription || description;

    return (
        <Helmet title={title} titleTemplate={titleTemplate}>
            {!!description
      && <meta name="description" content={description} />}
            {!!imageUrl
      && <meta name="image" content={imageUrl} />}

            {/* seo og */}
            {!!ogUrl
      && <meta property="og:url" content={ogUrl} />}
            {!!ogLocale
      && <meta property="og:locale" content={ogLocale} />}
            {!!ogTitle
      && <meta property="og:title" content={ogTitle} />}
            {!!ogType
      && <meta property="og:type" content={ogType} />}
            {!!ogDescription
      && <meta property="og:description" content={ogDescription} />}
            {!!ogImageUrl
      && <meta property="og:image" content={ogImageUrl} />}

            {/* seo twitter */}
            {!!twitterUsername
      && <meta property="twitter:creator" content={twitterUsername} />}
            {!!twitterTitle
      && <meta property="twitter:title" content={twitterTitle} />}
            {!!twitterDescription
      && <meta property="twitter:description" content={twitterDescription} />}

        </Helmet>
    )
}
export default Seo

export interface OgSeo {
  locale: string;
  type: string;
  tile: string;
  description: string;
  imageUrl: string;
}

export interface SiteMetadata {
  title: string;
  description: string;
  siteUrl: string;
  image: string;
  twitterUsername: string;
  titleTemplate: string;
}
