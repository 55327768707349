export const flattenObject = (object: any, delimiter = '_') => {
    const flattenedObject: any = {};

    Object.keys(object).forEach((parentKey: string) => {
        if (typeof object[parentKey] === 'object' && object[parentKey] !== null) {
            const flatObject = flattenObject(object[parentKey]);
            Object.keys(flatObject).forEach((childKey: string) => {
                flattenedObject[`${parentKey}${delimiter}${childKey}`] = flatObject[childKey];
            });
        } else {
            flattenedObject[parentKey] = object[parentKey];
        }
    });
    return flattenedObject;
};
