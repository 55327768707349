import { makeStyles } from '@material-ui/core';

export const useStyles = makeStyles(() => ({
    root: {
        position: 'fixed',
        top: 0,
        left: 0,
        height: '100vh',
        width: '100vw',
        zIndex: 99999,
        backgroundColor: '#ffffff44',
        justifyContent: 'center',
        display: 'flex',
    },
    hidden: {
        opacity: 0,
        transition: 'opacity 0.7s linear, z-index 0.7s',
        zIndex: -1,
    },
    loadingIconContainer: {
        margin: 'auto',
    },
    loadingIcon: {
        width: 110,
        height: 110,
        backgroundColor: '#fff',
        display: 'block',
        borderRadius: 85,
    },
    loadingMessage: {
        marginTop: 30,
        fontSize: 14,
        fontWeight: 400,
        textAlign: 'center',
        width: 300,
        marginLeft: -95,
    },
}));
