import React, { memo } from 'react';
import { joinClasses } from '../functions/join';
import GlobeIcon from './icon/animated/GlobeIcon';
import { useStyles } from './LoadingScreen.jss';

export interface LoadingScreenProps {
    isHidden: boolean;
}

export const LoadingScreen = memo(({ isHidden }: LoadingScreenProps) => {
    const classes = useStyles();
    return (
        <span className={joinClasses({
            [classes.root]: true,
            [classes.hidden]: isHidden,
        })}
        >
            <div className={classes.loadingIconContainer}>
                <div className={classes.loadingIcon}>
                    <GlobeIcon />
                </div>
            </div>
        </span>
    )
});
