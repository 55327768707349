import React, { useState } from "react";
import { useMount, useSessionStorage } from "react-use";
import { graphql, PageProps } from "gatsby";
import { getClasses } from "./CalculatorPageTemplate.jss";
import { App } from "../../components/App";
import { BusinessType } from "../../interface/BusinessType";
import { GFooter } from "../../interface/graphql/GFooter";
import { GHeader } from "../../interface/graphql/GHeader";
import { GKeyValuePair } from "../../interface/graphql/GKeyValuePair";
import { GSeo } from "../../interface/graphql/GSeo";
import { MainLayout } from "../../layouts/MainLayout";
import { SessionStorageKeys } from "../../interface/SessionStorageKeys";
import { detectBusinessType } from "../../functions/detectBusinessType";
import { getImageUrl } from "../../functions/getImageUrl";
import { logger } from "../../functions/logger";
import { getStepForSlug } from "../../functions/getStepForSlug";
import { mapKeyValues } from "../../functions/mapKeyValues";
import { navigate } from "../../functions/navigate";
import {
    CALCULATOR_COMPONENT_ID,
    CalculatorManager,
} from "./components/CalculatorManager/CalculatorManager";
import { IAirport } from "../../interface/Airport";
import { ICountry } from "../../interface/Country";
import { IProjectPrice } from "../../interface/IProjectPrice";
import { IGOffsetProjects } from "../../interface/IGOffsetProjects";
import { mapCalculatorProjects } from "../../functions/mapCalculatorProjects";
import { LoadingScreen } from "../../components/LoadingScreen";
import { useAlias } from "../../hook/useAlias";
import { Helmet } from "react-helmet";
import { brandLink, IFeatureFlags } from "@cpyou/shared/dist";
import { IPageContext } from "../../interface/PageContext";
import { getLocale } from "../../functions/getLocale";
import { getSlugLanguageName } from "../../functions/getSlugLanguageName";
import { GLink } from "../../interface/graphql/GLink";
import { GClimateOffsetProjectPage } from "../../interface/graphql/GClimateOffsetProjectPage";
import { Base64 } from "js-base64";
export interface Image {
    title: string; // e.g. SDG
    url: string; // CDN URL
    contentType: string; // e.g. image/png
    fileName: string; // e.g. SDG.png
}

export interface IImageEdge {
    node: {
        id: string;
        images: Array<{
            id: string;
            title: string;
            locale: "de" | "en-US";
            file: {
                url: string;
                fileName: string;
                contentType: string;
            };
        }>;
    };
}

interface IContentfulCalculatorPage {
    title: string;
    locale: string;
    slug: string;
    header: GHeader;
    footer: GFooter;
    nextPage: GLink;
    seo: GSeo;
    journeyName: string;
    flowName: string;
    stepName: string;
    backPage: { slug: string } | null;
    privatePolicy: { slug: string };
    termsOfUse: { slug: string };
    translations: GKeyValuePair;
    canonicalPage: { slug: string } | null;
}

interface IContentfulCalculatorFlow {
    flowName: string;
    locale: string;
    calculatorPages: Array<IContentfulCalculatorPage>;
}

interface IContentfulCalculatorUserJourney {
    journeyName: string;
    locale: string;
    successPage: {
        page?: { slug: string };
    } | null;
    calculatorFlows: Array<IContentfulCalculatorFlow>;
}

export interface IPropCalculatorPageTemplate {
    allContentfulCalculatorPage: {
        nodes: Array<IContentfulCalculatorPage>;
    };
    contentfulCalculatorPage: IContentfulCalculatorPage;
    contentfulCalculatorTranslations: {
        keyValuePair: GKeyValuePair;
    };
    allContentfulClimateOffsetProject: {
        nodes: Array<IGOffsetProjects>;
    };
    allContentfulCalculatorImages: {
        edges: Array<IImageEdge>;
    };
    allContentfulKeyValue: { nodes: GKeyValuePair };
    allContentfulCalculatorUserJourney: {
        edges: Array<{
            node: IContentfulCalculatorUserJourney;
        }>;
    };
    allContentfulCalculatorFlow: {
        edges: Array<{
            node: IContentfulCalculatorFlow;
        }>;
    };
    allContentfulClimateOffsetProjectPage: {
        edges: Array<{
            node: GClimateOffsetProjectPage;
        }>;
    };
}

export interface IPropsCalculatorPageTemplateContext extends IPageContext {
    calculatorCmpEnv: "production" | "development";
    googleTagManagerId: string;
    distanceService: {
        apiToken: string;
        baseUrl?: string;
    };
    language: string;
    offsetProjects: Array<IProjectPrice>;
    airports: Array<IAirport>;
    countries: Array<ICountry>;
    mailchimpServiceEndpoint: string;
    featureFlags: IFeatureFlags;
    images: {
        [imageTitle: string]: {
            title: string;
            url: string;
            fileName: string;
        };
    };
    adyenClientKey: string;
}

interface IUserJourneyMap {
    [language: string]: {
        [userJourney: string]: {
            [flowName: string]: {
                [stepName: string]: any;
            };
            successPage: {
                page?: { slug: string };
            };
        };
    };
}

interface IFlowMap {
    [flowName: string]: {
        [stepName: string]: any;
    };
}

let interval: any;

const buildFlowMap = (
    flows: Array<IContentfulCalculatorFlow>,
    flowMap: IFlowMap = {}
) => {
    flows.forEach((flow: IContentfulCalculatorFlow) => {
        if (!flowMap[flow.flowName]) {
            flowMap[flow.flowName] = {};
        }

        if (!flow.calculatorPages) return;

        flow.calculatorPages.forEach(
            (calculatorPage: IContentfulCalculatorPage, index: number) => {
                flowMap[flow.flowName][calculatorPage.stepName] = {
                    ...calculatorPage,
                    index,
                };
            }
        );
    });
    return flowMap;
};

export interface ImageMap {
    [locale: string]: {
        [title: string]: Image;
    };
}

const mapUserJourneys = (
    rawUserJourneys: Array<{ node: IContentfulCalculatorUserJourney }>,
    currentSlug: string,
    currentLanguage: string
) => {
    const userJourneyMap: IUserJourneyMap = {};
    let flowMap: IFlowMap = {};
    let calculatorPage: IContentfulCalculatorPage;

    rawUserJourneys.forEach((journey) => {
        const journeyLanguage = getSlugLanguageName(journey.node.locale);

        if (!userJourneyMap[journeyLanguage]) {
            userJourneyMap[journeyLanguage] = {};
        }

        if (!userJourneyMap[journeyLanguage][journey.node.journeyName]) {
            userJourneyMap[journeyLanguage][journey.node.journeyName] = {
                successPage: null,
            };
        }

        if (journey.node.successPage && journey.node.successPage.page) {
            userJourneyMap[journeyLanguage][
                journey.node.journeyName
            ].successPage = journey.node.successPage;
        }

        if (!journey.node.calculatorFlows) {
            return;
        }

        const currentFlowMap = buildFlowMap(
            journey.node.calculatorFlows,
            flowMap
        );

        flowMap = {
            ...flowMap,
            ...currentFlowMap,
        };

        journey.node.calculatorFlows.forEach(
            (flow: IContentfulCalculatorFlow, index: number) => {
                userJourneyMap[journeyLanguage][journey.node.journeyName][
                    flow.flowName
                ] = {
                    ...flowMap[flow.flowName],
                    index,
                };
            }
        );
    });

    const currentCalculatorPage: IContentfulCalculatorPage = getStepForSlug(
        {
            userJourneyMap,
            language: currentLanguage,
        } as any,
        currentSlug
    );

    if (currentCalculatorPage) {
        calculatorPage = currentCalculatorPage;
    }

    return {
        userJourneyMap,
        flowMap,
        calculatorPage,
    };
};

const CalculatorPageTemplate = (
    props: PageProps<
        IPropCalculatorPageTemplate,
        IPropsCalculatorPageTemplateContext
    >
) => {
    const [businessType, setBusinessType] = useSessionStorage(
        SessionStorageKeys.BUSINESS_TYPE,
        BusinessType.B2C
    );
    const { setAlias } = useAlias();

    logger.log("Calculator page template props", props);
    useMount(() => {
        setBusinessType(detectBusinessType());
    });
    const [showLoading, setShowLoading] = useState(true);

    const {
        data: {
            allContentfulClimateOffsetProject: { nodes: rawCalcualtorProjects },
            allContentfulCalculatorUserJourney: { edges: rawUserJourneys },
            contentfulCalculatorTranslations,
            allContentfulKeyValue,
            allContentfulClimateOffsetProjectPage,
        },
        pageContext,
    } = props;

    const {
        language,
        calculatorCmpEnv,
        googleTagManagerId,
        distanceService,
        offsetProjects: offsetProjectsPrice,
        mailchimpServiceEndpoint,
        featureFlags,
        images,
        serviceNgEndpoint,
        adyenClientKey,
        coBrand,
        slug,
    } = pageContext;

    const calculatorProjects = mapCalculatorProjects(
        rawCalcualtorProjects,
        offsetProjectsPrice,
        allContentfulClimateOffsetProjectPage.edges,
        calculatorCmpEnv,
        coBrand
    );

    // we use the current pages slug (the concrete generated one of the specific page)
    // to determine the user journey of this page and then map an object
    // containing the matching flowMap and userJourneyMap
    const { calculatorPage, flowMap, userJourneyMap } = mapUserJourneys(
        rawUserJourneys,
        slug,
        getSlugLanguageName(language)
    );

    const translations = mapKeyValues(allContentfulKeyValue.nodes);
    const calculatorTranslations = mapKeyValues(
        contentfulCalculatorTranslations.keyValuePair
    );

    useMount(async () => {
        // dynamic import for language-specific airport list
        const airportList: Array<any> = await (
            await fetch(`/airports/airport_list_${language.toLowerCase()}.json`)
        ).json();

        // TODO: Pass down serviceUrl and serviceClientId from env variables
        // TODO: SERVICE_URL and SERVICE_CLIENT_ID
        // TODO: Could use @cpyou/shared interface
        const calculatorConfiguration = {
            selector: `#${CALCULATOR_COMPONENT_ID}`,
            userJourneyMap,
            flowMap,
            googleTagManagerId,
            currentJourneyName: calculatorPage.journeyName,
            currentFlowName: calculatorPage.flowName,
            currentStepName: calculatorPage.stepName,
            backPage: calculatorPage.backPage,
            language,
            coBrand,
            locale: getLocale(language),
            featureFlags,
            airports: airportList.map((airport: any) => ({
                ...airport,
                displayName: `${airport.code} - ${airport.name}`,
            })),
            images,
            translations: {
                [language]: {
                    ...translations,
                    ...calculatorTranslations,
                },
            },
            env: calculatorCmpEnv,
            mailchimpServiceEndpoint,
            distanceService,
            serviceNgEndpoint,
            adyenClientKey,
            projects: calculatorProjects,
            projectPages: allContentfulClimateOffsetProjectPage.edges,
            // TODO: refactor: SUCCESS event instead of callback (this one breaks API design)
            onSuccess: (data: any) => {
                const mixpanel = setAlias(data.contactForm.email, "email");

                if (mixpanel) {
                    // track ecommerce revenue; tracking in EUR
                    mixpanel.people.track_charge(data.response.total_price);
                }

                const paymentType = data.response.payment_type
                const transactionId = data.response.new_id

                const link = brandLink(
                    `/${language}/${userJourneyMap[language][
                        calculatorPage.journeyName
                    ].successPage.page?.slug!}?${Base64.encode(
                        JSON.stringify({
                            selectedAmountDonation: {
                                amountInEuro: data.selectedAmountDonation.amountInEuro,
                                projectName: data.selectedAmountDonation.projectName
                            },
                            projectId: data.project.projectId,
                            paymentType,
                            transactionId
                        })
                    )}`,
                    coBrand
                );

                navigate(link);
            },
        };

        if (interval) {
            clearInterval(interval);
        }

        interval = setInterval(() => {
            if (
                (window as any).ClimatePartnerYouComponent &&
                !document.body.contains(
                    (window as any).$climatePartnerYouComponent
                )
            ) {
                //console.log('calculatorConfiguration', calculatorConfiguration);

                (window as any).$climatePartnerYouComponent = (window as any).ClimatePartnerYouComponent.init(
                    calculatorConfiguration
                );
                clearInterval(interval);

                setShowLoading(false);
            }
        }, 10);
    });

    const classes = getClasses();
    return (
        <>
            {calculatorPage && (
                <App
                    pageContext={
                        {
                            ...pageContext,
                            templatePath: "CalculatorPageTemplate",
                        } as IPageContext
                    }
                    seoContext={calculatorPage.seo}
                    header={calculatorPage.header}
                    footerContext={calculatorPage.footer}
                    keyValuesContext={translations}
                >
                    <MainLayout
                        logoUrl={getImageUrl(calculatorPage.header.logo)}
                        header={{ mainSlug: calculatorPage.header.mainSlug }}
                        classes={{
                            background: classes.background,
                            backgroundRect: classes.backgroundRect,
                        }}
                    >
                        {calculatorPage.canonicalPage &&
                            calculatorPage.canonicalPage.slug && (
                                <Helmet>
                                    <link
                                        rel="canonical"
                                        href={calculatorPage.canonicalPage.slug}
                                    />
                                </Helmet>
                            )}
                        <LoadingScreen isHidden={!showLoading} />
                        <CalculatorManager
                            language={language}
                            nextPage={calculatorPage.nextPage}
                            termsOfUse={calculatorPage.termsOfUse}
                            privatePolicy={calculatorPage.privatePolicy}
                            mainSlug={{ slug: calculatorPage.header.mainSlug }}
                        />
                    </MainLayout>
                </App>
            )}
            {!calculatorPage && (
                <span>
                    Error: The page requested is no longer available. Please
                    reach out to our support team.
                </span>
            )}
        </>
    );
};
export default CalculatorPageTemplate;

export const queryPage = graphql`
    query ContentfulCalculatorPageBySlugAndLocale(
        $locale: String
        $featureFlagsQuery: StringQueryOperatorInput
    ) {
        allContentfulKeyValue(filter: { node_locale: { eq: $locale } }) {
            nodes {
                key {
                    key
                }
                value {
                    value
                }
            }
        }
        allContentfulCalculatorUserJourney(
            filter: { featureFlags: $featureFlagsQuery }
        ) {
            edges {
                node {
                    journeyName
                    successPage {
                        ...LinkFragment
                    }
                    locale: node_locale
                    calculatorFlows {
                        flowName
                        locale: node_locale
                        calculatorPages {
                            slug
                            stepName
                            flowName
                            journeyName
                            translations {
                                key {
                                    key
                                }
                                value {
                                    value
                                }
                            }
                            locale: node_locale
                            title
                            backPage {
                                ... on ContentfulLandingPage {
                                    slug
                                }
                            }
                            privatePolicy {
                                slug
                            }
                            termsOfUse {
                                slug
                            }
                            footer {
                                ...FooterFragment
                            }
                            nextPage {
                                ...LinkFragment
                            }
                            seo {
                                ...SeoFragment
                            }
                            header {
                                ...HeaderFragment
                            }
                            canonicalPage {
                                slug
                            }
                        }
                    }
                }
            }
        }
        contentfulCalculatorTranslations(node_locale: { eq: $locale }) {
            keyValuePair {
                key {
                    key
                }
                value {
                    value
                }
            }
        }
        allContentfulClimateOffsetProjectPage(
            filter: { node_locale: { eq: $locale } }
        ) {
            edges {
                node {
                    locale: node_locale
                    title
                    slug
                    environment
                    climateOffsetProject {
                        title
                        projectId
                        heroImage {
                            title
                            file {
                                url
                                fileName
                                contentType
                            }
                        }
                        projectName
                        projectImages {
                            file {
                                url
                                fileName
                                contentType
                            }
                        }
                        heroCertificateImage {
                            title
                            file {
                                url
                                fileName
                                contentType
                            }
                        }
                        howItCompensates {
                            childContentfulRichText {
                                html
                            }
                        }
                        projectDeveloperImage {
                            title
                            file {
                                url
                                fileName
                                contentType
                            }
                        }
                        certificateImages {
                            title
                            file {
                                url
                                fileName
                                contentType
                            }
                        }
                        effectChainText {
                            childContentfulRichText {
                                html
                            }
                            internal {
                                content
                            }
                        }
                        additionalBenefits {
                            childContentfulRichText {
                                html
                            }
                            internal {
                                content
                            }
                        }
                        sustainableDevelopmentGoals
                        startYearOfProject
                        totalCompensationVolumePerYear
                        partnerImages {
                            title
                            file {
                                url
                                fileName
                                contentType
                            }
                        }
                        places {
                            title
                            url
                        }
                        primaryUnitIcon
                        isPortfolioProject
                        sustainableDevelopmentGoalList {
                            sdg
                            text
                        }
                        environment
                    }
                    seo {
                        ...SeoFragment
                    }
                    footer {
                        ...FooterFragment
                    }
                    header {
                        ...HeaderFragment
                    }
                }
            }
        }
        allContentfulClimateOffsetProject(
            filter: {
                node_locale: { eq: $locale }
                featureFlags: $featureFlagsQuery
            }
        ) {
            nodes {
                title
                projectId
                heroImage {
                    title
                    file {
                        url
                        fileName
                        contentType
                    }
                }
                projectName
                projectImages {
                    file {
                        url
                        fileName
                        contentType
                    }
                }
                heroCertificateImage {
                    title
                    file {
                        url
                        fileName
                        contentType
                    }
                }
                howItCompensates {
                    childContentfulRichText {
                        html
                    }
                }
                projectDeveloperImage {
                    title
                    file {
                        url
                        fileName
                        contentType
                    }
                }
                certificateImages {
                    title
                    file {
                        url
                        fileName
                        contentType
                    }
                }
                effectChainText {
                    childContentfulRichText {
                        html
                    }
                    internal {
                        content
                    }
                }
                additionalBenefits {
                    childContentfulRichText {
                        html
                    }
                    internal {
                        content
                    }
                }
                sustainableDevelopmentGoals
                startYearOfProject
                totalCompensationVolumePerYear
                partnerImages {
                    title
                    file {
                        url
                        fileName
                        contentType
                    }
                }
                places {
                    title
                    url
                }
                primaryUnitIcon
                isPortfolioProject
                sustainableDevelopmentGoalList {
                    sdg
                    text
                }
                environment
                isStandaloneProject
            }
        }
    }
`;
