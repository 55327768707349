import { isInDebugMode } from "@cpyou/shared/dist";

export const logger = {
    log: (message?: any, ...optionalParams: any[]) => {
        if (isInDebugMode()) {
            // eslint-disable-next-line no-console
            console.log(message, ...optionalParams);
        }
    },
    info: (message?: any, ...optionalParams: any[]) => {
        if (isInDebugMode()) {
            // eslint-disable-next-line no-console
            console.info(message, ...optionalParams);
        }
    },
}
