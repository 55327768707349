import { NavigateOptions } from '@reach/router';
import { navigate as gatsbyNavigate } from 'gatsby'

export const navigate = async (to: string, options?: NavigateOptions<unknown>, copySearch = false): Promise<void> => {
    if (typeof window !== 'undefined') {
        // fix if change language add also search
        let search = '';
        if (copySearch && window.location && window.location.search) {
            search = window.location.search;
        }
        await gatsbyNavigate(`${to}${search}`, options);
    }
}
