import { useLayoutEffect, useState } from 'react';

export const useIsNativeShare = () => {
    const [isNativeShare, setIsNativeShare] = useState(false);

    useLayoutEffect(() => {
        if (typeof window !== 'undefined') {
            setIsNativeShare(typeof window.navigator.share !== 'undefined');
        }
    }, [setIsNativeShare])

    return isNativeShare;
}
