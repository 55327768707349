"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    Object.defineProperty(o, k2, { enumerable: true, get: function() { return m[k]; } });
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
exports.__esModule = true;
exports.PieFootPrintStatus = void 0;
var react_1 = __importStar(require("react"));
var react_minimal_pie_chart_1 = require("react-minimal-pie-chart");
var PieFootPrintStatus = function (_a) {
    var footPrintDataSteps = _a.footPrintDataSteps, totalFootprint = _a.totalFootprint, viewBoxSize = _a.viewBoxSize, center = _a.center, classNameChart = _a.classNameChart, classNameText = _a.classNameText, showFootprintTotal = _a.showFootprintTotal, showFootprintsType = _a.showFootprintsType, showFootprintsTypeAll = _a.showFootprintsTypeAll, style = _a.style;
    var _b = react_1.useState(25), fontSize = _b[0], setFontSize = _b[1]; // Initial font size
    var textRef = react_1.useRef(null);
    // Function to calculate the font size based on available space
    var calculateFontSize = react_1.useCallback(function () {
        if (textRef.current) {
            var textContainerWidth = textRef.current.getBBox().width;
            var maxTextWidth = 0.95 * (viewBoxSize[0] / 2);
            var newFontSize = Math.round(Math.min(20, (maxTextWidth / textContainerWidth) * 25));
            setFontSize(newFontSize);
        }
    }, [textRef]);
    react_1.useEffect(function () {
        // Calculate font size on component mount and on window resize
        calculateFontSize();
        window.addEventListener("resize", calculateFontSize);
        return function () {
            window.removeEventListener("resize", calculateFontSize);
        };
    }, [textRef, showFootprintTotal]);
    return (react_1["default"].createElement(react_minimal_pie_chart_1.PieChart, { style: style, data: footPrintDataSteps, label: function (_a) {
            var dataEntry = _a.dataEntry;
            var title = "";
            if (showFootprintsType && dataEntry.value !== 0) {
                title = "" + dataEntry.title.split(" ")[0];
                if (showFootprintsTypeAll) {
                    title = dataEntry.title + "t";
                }
            }
            return title;
        }, labelStyle: {
            fontSize: "8px",
            fontWeight: 700
        }, className: classNameChart, lengthAngle: 360, startAngle: 270, viewBoxSize: viewBoxSize, lineWidth: 22, labelPosition: 115, center: center, animate: false, background: "linear-gradient(180deg, rgba(251, 208, 208, 0.49) 0%, rgba(251, 208, 208, 0) 100%)" }, showFootprintTotal && (react_1["default"].createElement(react_1["default"].Fragment, null,
        react_1["default"].createElement("text", { className: classNameText, style: {
                fontSize: fontSize + "px",
                fontWeight: 900
            }, ref: textRef, x: center[0], y: center[1], textAnchor: "middle", dominantBaseline: "middle" },
            totalFootprint,
            " t"),
        react_1["default"].createElement("text", { className: classNameText, style: { fontSize: "12px", fontWeight: 700 }, x: center[0], y: center[1] + 20, textAnchor: "middle", dominantBaseline: "middle" }, "CO\u2082")))));
};
exports.PieFootPrintStatus = PieFootPrintStatus;
