"use strict";
var __spreadArray = (this && this.__spreadArray) || function (to, from) {
    for (var i = 0, il = from.length, j = to.length; i < il; i++, j++)
        to[j] = from[i];
    return to;
};
exports.__esModule = true;
exports.warn = exports.error = exports.log = exports.printLog = void 0;
var env_1 = require("./env");
var printLog = function (level) {
    if (level === void 0) { level = 'log'; }
    var params = [];
    for (var _i = 1; _i < arguments.length; _i++) {
        params[_i - 1] = arguments[_i];
    }
    switch (level) {
        case 'error':
            console.error.apply(console, params);
            break;
        case 'warn':
            // IDEA!!!: Add feature flags for this in the future
            if (process.env.NODE_ENV === env_1.Env.production)
                break;
            console.warn.apply(console, params);
            break;
        // istanbul ignore next: impossible to call in TypeScript
        default:
        case 'log':
            // IDEA: Add feature flags for this in the future
            if (process.env.NODE_ENV === env_1.Env.production)
                break;
            console.log.apply(console, params);
            break;
    }
};
exports.printLog = printLog;
var log = function () {
    var params = [];
    for (var _i = 0; _i < arguments.length; _i++) {
        params[_i] = arguments[_i];
    }
    exports.printLog.apply(void 0, __spreadArray(['log'], params));
};
exports.log = log;
var error = function () {
    var params = [];
    for (var _i = 0; _i < arguments.length; _i++) {
        params[_i] = arguments[_i];
    }
    exports.printLog.apply(void 0, __spreadArray(['error'], params));
};
exports.error = error;
var warn = function () {
    var params = [];
    for (var _i = 0; _i < arguments.length; _i++) {
        params[_i] = arguments[_i];
    }
    exports.printLog.apply(void 0, __spreadArray(['warn'], params));
};
exports.warn = warn;
