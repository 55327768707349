import { BREAKPOINTS, useForceUpdate, useOnResize, useViewport } from '@cpyou/shared/dist'
import { getClasses } from "./HomePageHeader.jss"
import { LayoutContext } from '../../../context/layoutContext'
import { Box, Typography } from '@material-ui/core'
import React, { useContext, useLayoutEffect } from 'react'
import { KeyValuesContext } from '../../../components/KeyValuesContext'
import { GLink } from '../../../interface/graphql/GLink'
import { GoToCalculationButton } from './NewContentJourneySelector/NewHomePageContentJourneySelector'
import { HeaderHeights } from '../../../layouts/headerHeights'
import { getPageHeaderBgImageUrl, PageTemplateName } from '../../../functions/getPageHeaderBgImageUrl';
import { PageContext } from '../../../layouts/MainLayout';

export const headerHeights : HeaderHeights = {
  mobile: 717,
  tablet: 740,
  desktop: 800,
  desktopXl: 824,
  desktopXxl: 650,
}
export interface IPropHomePageHeader {
    title: string;
    highlightedTitle: string;
    subTitle: string;
    ctaLink: GLink
}
export function HomePageHeader({title, highlightedTitle, subTitle, ctaLink}: IPropHomePageHeader) {
  const classes = getClasses()
  const keyValues = useContext(KeyValuesContext);
  const { containerRef } = useContext(LayoutContext);
  const sizeModel = useViewport(BREAKPOINTS, containerRef);
  const { images } = useContext(PageContext);

  const forceUpdate = useForceUpdate();

  useLayoutEffect(() => {

    // eslint-disable-next-line
    return useOnResize(() => {
        forceUpdate();
    }, BREAKPOINTS, containerRef)
  })

  const headerHeight = headerHeights[sizeModel.breakpointSize]

  const pageTemplateName : PageTemplateName = 'home'
  const backgroundURL = getPageHeaderBgImageUrl(pageTemplateName, sizeModel.breakpointSize, images)

  const headerContentMarginHorizontal =
    (sizeModel.isTablet || sizeModel.isDesktopXL)
    ? sizeModel.marginWidth + sizeModel.gutterWidth + sizeModel.columnWidth
    : (sizeModel.isMobile || sizeModel.isDesktop)
    ? sizeModel.marginWidth
    : sizeModel.columnWidth

  const headerContentMarginTop =
    (sizeModel.isMobile || sizeModel.isTablet)
    ? 25
    : (sizeModel.isDesktop || sizeModel.isDesktopXL)
    ? 144
    : 180

  const hh = (sizeModel.isMobile || sizeModel.isTablet) ? "fit-content" : headerHeight
  return (
    <Box className={classes.homePageHeader} style={{height: hh}}>
      <img className={classes.image} src={backgroundURL}></img>
      <Box
        className={classes.headerContent}
        marginLeft={`${headerContentMarginHorizontal}px`}
        marginTop={`${headerContentMarginTop}px`}
       >
        <Box>
          <Typography
            variant='h1'
            className={
              title.length && highlightedTitle.length < 20
                ? classes.title
                : classes.longTitle}
          >
            {title}
          </Typography>
          <Typography
            variant='h1'
            className={
              title.length && highlightedTitle.length < 20
                ? classes.highlightedTitle
                : classes.longHighlightedTitle}
            >
              {highlightedTitle}
            </Typography>
        </Box>
        <Typography
          variant={( sizeModel.isMobile || sizeModel.isTablet) ? 'h3' : 'body1'}
          className={classes.subTitle} dangerouslySetInnerHTML={{ __html: subTitle }}
        />
        <GoToCalculationButton link={ctaLink} text={keyValues.homePageLetsGo} buttonClass={classes.calculateButton} hasArrowRight={true}/>
      </Box>
    </Box>
    )
}
