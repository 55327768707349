import { makeStyles } from '@material-ui/styles';
import { darken, lighten, Theme } from '@material-ui/core';
import { getColorShades } from '@cpyou/shared/dist';

export const getClasses = makeStyles(({ breakpoints, palette }: Theme) => ({
    categoriesWrapper: {
        paddingBottom: 40,
        [breakpoints.only('xs')]: {
            paddingBottom: 25,
        },
        '@media (min-height: 800px)': {
            // position: 'sticky',
            top: 0,
        },
    },
    categoriesContainer: {
        borderTop: '1px solid #DEDEDE',
        borderBottom: '1px solid #DEDEDE',
        marginTop: '40px',
        justifyContent: 'flex-start',
        display: 'flex',
        flexDirection: 'column'
    },
    categoryItem: {
        padding: '12px 27px',
        '&:hover': {
            backgroundColor: getColorShades(palette.primary.main, lighten, darken).tint90Percent,
            opacity: 0.6
        }
    },
    activeCategoryItem: {
        backgroundColor: getColorShades(palette.primary.main, lighten, darken).tint90Percent,
        '& > a': {
            fontWeight: 'bold',
            color: '#2B2D34',
        }

    },
    categoryItemLink: {
        fontSize: 16,
        color: '#818181',
        textDecorationLine: 'none',
        cursor: 'pointer'
    },
}));
