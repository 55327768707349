"use strict";
exports.__esModule = true;
exports.useViewport = exports.useOnResize = exports.getActiveSizeModel = exports.getViewportHeight = exports.getViewportWidth = void 0;
var debounce_1 = require("debounce");
var BREAKPOINTS_1 = require("./BREAKPOINTS");
var getColumnWidth_1 = require("./getColumnWidth");
var BREAKPOINTS_2 = require("./BREAKPOINTS");
var ResizeEventName = "resize";
var ResizeUpdateScheduleMs = 100;
var getViewportWidth = function (viewportEl) {
    if (typeof viewportEl !== 'undefined') {
        return viewportEl.clientWidth;
    }
    if (typeof window !== 'undefined') {
        return window.innerWidth;
    }
    return 0;
};
exports.getViewportWidth = getViewportWidth;
var getViewportHeight = function (viewportEl) {
    if (typeof viewportEl !== 'undefined') {
        return viewportEl.clientHeight;
    }
    if (typeof window !== 'undefined') {
        return window.innerHeight;
    }
    return 0;
};
exports.getViewportHeight = getViewportHeight;
var getActiveSizeModel = function (breakpoints, viewportEl) {
    if (breakpoints === void 0) { breakpoints = BREAKPOINTS_1.BREAKPOINTS; }
    var viewportWidth = exports.getViewportWidth(viewportEl);
    var viewportHeight = exports.getViewportHeight(viewportEl);
    var sizeModel = {
        viewportWidth: viewportWidth,
        viewportHeight: viewportHeight,
        isMobile: viewportWidth < breakpoints.tablet,
        isTablet: viewportWidth >= breakpoints.tablet &&
            viewportWidth < breakpoints.desktop,
        isDesktop: viewportWidth >= breakpoints.desktop &&
            viewportWidth < breakpoints.desktopXl,
        isDesktopXL: viewportWidth >= breakpoints.desktopXl &&
            viewportWidth < breakpoints.desktopXxl,
        isDesktopXXL: viewportWidth >= breakpoints.desktopXxl,
        breakpointSize: 'mobile',
        gutterWidth: BREAKPOINTS_1.GutterMobilePx,
        columnWidth: 0,
        marginWidth: BREAKPOINTS_2.MarginMobilePx
    };
    if (sizeModel.isMobile) {
        sizeModel.breakpointSize = 'mobile';
        sizeModel.gutterWidth = BREAKPOINTS_1.GutterMobilePx;
        sizeModel.marginWidth = BREAKPOINTS_2.MarginMobilePx;
    }
    if (sizeModel.isTablet) {
        sizeModel.breakpointSize = 'tablet';
        sizeModel.gutterWidth = BREAKPOINTS_1.GutterTabletPx;
        sizeModel.marginWidth = BREAKPOINTS_2.MarginTabletPx;
    }
    if (sizeModel.isDesktop) {
        sizeModel.breakpointSize = 'desktop';
        sizeModel.gutterWidth = BREAKPOINTS_1.GutterDesktopAllPx;
        sizeModel.marginWidth = BREAKPOINTS_2.MarginDesktopAllPx;
    }
    if (sizeModel.isDesktopXL) {
        sizeModel.breakpointSize = 'desktopXl';
        sizeModel.gutterWidth = BREAKPOINTS_1.GutterDesktopAllPx;
        sizeModel.marginWidth = BREAKPOINTS_2.MarginDesktopAllPx;
    }
    if (sizeModel.isDesktopXXL) {
        sizeModel.breakpointSize = 'desktopXxl';
        sizeModel.gutterWidth = BREAKPOINTS_1.GutterDesktopAllPx;
        sizeModel.marginWidth = BREAKPOINTS_2.MarginDesktopAllPx;
    }
    sizeModel.columnWidth = getColumnWidth_1.getColumnWidth(sizeModel, viewportEl);
    return sizeModel;
};
exports.getActiveSizeModel = getActiveSizeModel;
var useOnResize = function (cb, breakpoints, viewportEl) {
    if (breakpoints === void 0) { breakpoints = BREAKPOINTS_1.BREAKPOINTS; }
    var debounced = debounce_1.debounce(function () {
        cb(exports.getActiveSizeModel(breakpoints, viewportEl));
    }, ResizeUpdateScheduleMs);
    if (typeof window !== 'undefined') {
        window.addEventListener(ResizeEventName, debounced);
    }
    return function () {
        if (typeof window !== 'undefined') {
            window.removeEventListener(ResizeEventName, debounced);
        }
    };
};
exports.useOnResize = useOnResize;
var useDevice = function (breakpoints, viewportEl) {
    if (breakpoints === void 0) { breakpoints = BREAKPOINTS_1.BREAKPOINTS; }
    return exports.getActiveSizeModel(breakpoints, viewportEl);
};
exports.useViewport = useDevice;
exports["default"] = useDevice;
