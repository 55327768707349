import React, { CSSProperties } from 'react';
import { useTheme } from '@material-ui/core/styles';

export interface IArrowLeftIconProps {
  style?: CSSProperties;
  className?: string;
  color?: string;
}

export const ArrowLeftIcon = ({ style, className, color }: IArrowLeftIconProps) => {
    const theme = useTheme();

    return (
      <svg style={style} className={className} viewBox="0 0 16 11" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path d="M3.56322 4.61758L14.6214 4.61758C15.075 4.61758 15.4427 4.97095 15.4427 5.40684C15.4427 5.84273 15.075 6.1961 14.6214 6.1961L3.56322 6.19609L6.23847 8.76713C6.57523 9.09076 6.57523 9.61548 6.23847 9.93911C5.90172 10.2627 5.35573 10.2627 5.01898 9.93911L0.912721 5.99283C0.575967 5.66919 0.575967 5.14448 0.912722 4.82084L5.01898 0.874563C5.35573 0.550928 5.90172 0.550929 6.23847 0.874564C6.57523 1.1982 6.57523 1.72291 6.23847 2.04655L3.56322 4.61758Z"
              fill={color ? color : theme.palette.primary.main}
          />
      </svg>
    )

}
