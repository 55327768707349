import { Theme } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';

export const getClasses = makeStyles(({ palette, breakpoints }: Theme) => ({
    embeddedAssetImage: {
        [breakpoints.down('md')]: {
            width: '100%',
        },
    },
    embeddedAssetDescription: {
        fontSize: 14,
        lineHeight: '14px',
        color: palette.grey[500],
    },
    embeddedAssetContainer: {
    },
}))
