import { useMixpanel } from "../hook/useMixpanel"

export const track = (tags: Array<string>, eventName = 'mailingListSignUp', tagsName = 'listNames') => {
    console.log('Mixpanel - track ', eventName, tags, 'using custom property', tagsName)

    const { trackEvent } = useMixpanel()
    trackEvent(eventName, {
        [tagsName]: tags,
    })
}
