import { makeStyles } from '@material-ui/styles';
import { darken, lighten, Theme } from '@material-ui/core';
import { getColorShades, ISizeModel } from '@cpyou/shared/dist';

export const getClasses = (
    size: ISizeModel,
    backgroundUrl: string,
    headerBgContainerHeight?: number,
    headerBgContainerColor?: string
) => makeStyles(() => ({
        background: {
            width: '100%',
            minHeight: `${headerBgContainerHeight}px !important`,
            image: {
                background: `url("${backgroundUrl}") no-repeat`,
                backgroundPosition: 'top right',
                backgroundSize: 'auto 100%'
            }
        },
        header: {
            position: 'absolute',
            top: 0,
            left: 0,
            right: 0,
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
            /* left/right padding (12px) is different from margin value because the
                images have inner padding (can be fixed by changing images) */
            padding: size.isMobile || size.isTablet ? '24px 12px' :
                size.isDesktopXL || size.isDesktopXXL ? '47px 49px' : '44px 50px' /* desktop */,
        },

        homePageHeader: {
            display: 'flex',
            zIndex: -1,
            backgroundColor: headerBgContainerColor === 'transparent'
                ? 'transparent'
                : getColorShades(headerBgContainerColor, lighten, darken).tint90Percent
        }
}))()
