/**
 * Implement Gatsby's Browser APIs in this file.
 *
 * See: https://www.gatsbyjs.org/docs/browser-apis/
 */
const useMixpanelModule = require('./src/hook/useMixpanel');

exports.onRouteUpdate = () => {
  // track page views using the function in useMixpanel hook (Also used in normal code)
  useMixpanelModule.useMixpanel().trackPageView();
}