import { Theme } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';

export const getClasses = (dimension: { left: number; width: number; height: number, sloganHeight: number }) => makeStyles(({ breakpoints }: Theme) => ({
    mainContainer: {
        paddingTop: 120,
        [breakpoints.down('md')]: {
            paddingTop: 20,
        },
    },
    backgroundDivWrapper: {
        zIndex: -2,
        position: 'absolute',
        [breakpoints.down('md')]: {
            display: 'none',
        },
        '&::before': {
            backgroundColor: 'rgba(0, 0, 0, 0.20)',
            content: '""',
            display: 'block',
            position: 'absolute',
            right: 0,
            width: dimension.width,
            height: dimension.height,
        },
    },
    backgroundDiv: {
        marginLeft: dimension.left,
        height: dimension.height,
        width: dimension.width,
        objectFit: 'cover',
    },
    approvedWrapper: {
        maxWidth: 500,
        marginLeft: 'auto',
        marginRight: 'auto',
    },
    approvedIcon: {
        fontSize: 80,
        marginLeft: 'auto',
        marginRight: 'auto',
    },
    headlineCool: {
        fontSize: 30,
        fontWeight: 'bold',
        maxWidth: 300,
        marginLeft: 'auto',
        marginRight: 'auto',

    },
    approvedHeader: {
        fontWeight: 'bold',
        fontSize: 20,
        maxWidth: 350,
        marginLeft: 'auto',
        marginRight: 'auto',
        '& p': {
            margin: 0,
            padding: 0
        }
    },
    approvedBody: {
        fontSize: 14,
        paddingTop: 25,
        marginLeft: 'auto',
        marginRight: 'auto',
        [breakpoints.down('md')]: {
            paddingBottom: 40,
        },
    },
    sloganHeader: {
        fontSize: 28,
        paddingTop: 90,
        fontWeight: 'bold',
        maxWidth: 530,
        color: '#fff',
        position: 'relative',
        marginLeft: 80,
        [breakpoints.down('md')]: {
            paddingTop: 20,
            paddingLeft: 40,
            paddingRight: 40,
            textAlign: 'center',
            margin: 'auto',
        },
    },
    sloganBody: {
        fontSize: 22,
        position: 'relative',
        maxWidth: 500,
        marginTop: 25,
        marginBottom: 25,
        color: '#fff',
        marginLeft: 80,
        [breakpoints.down('md')]: {
            margin: 'auto',
            paddingTop: 15,
            marginBottom: 25,
            paddingLeft: 40,
            paddingRight: 40,
            display: 'block',
            textAlign: 'center',
        },
    },
    sharIconsWrapper: {
        marginLeft: 15,
        marginTop: 15,
        [breakpoints.down('md')]: {
            marginLeft: 0,
        },
    },
    shareButton: {
        padding: 2,
    },
    nativeShare: {
        display: 'flex',
        marginTop: 25,
        marginBottom: 25,
    },
    sloganBodySecond: {
        fontSize: 22,
        maxWidth: 500,
        paddingTop: 25,
        color: '#fff',
        marginLeft: 80,
        paddingBottom: 140,
        [breakpoints.down('md')]: {
            margin: 'auto',
            paddingTop: 15,
            paddingLeft: 40,
            paddingRight: 40,
            paddingBottom: 40,
            textAlign: 'center',
        },
    },
    sloganWrapper: {
        overflowY: 'hidden',
        '&::before': {
            backgroundColor: 'rgba(0, 0, 0, 0.20)',
            content: '""',
            display: 'block',
            position: 'absolute',
            [breakpoints.down('md')]: {
                right: 0,
                width: dimension.width,
                height: dimension.sloganHeight,
            },
        },
    },
    mobileVideo: {
        objectFit: 'cover',
        zIndex: -1,
        width: dimension.width,
        height: dimension.sloganHeight,
        overflow: 'hidden',
        position: 'absolute',
        [breakpoints.up('lg')]: {
            display: 'none',
        },
    },
    feedbackButton: {
        marginTop: 0,
        marginBottom: 42,
        [breakpoints.up('lg')]: {
            marginTop: 42,
        },
        height: 40,
        fontSize: 16,
        fontWeight: 700,
        color: '#ffffff',
    },
}))();
