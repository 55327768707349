import React from 'react';
import { useMount, useSessionStorage } from 'react-use';
import { navigate } from '../functions/navigate';
import { BusinessType } from '../interface/BusinessType';
import { SessionStorageKeys } from '../interface/SessionStorageKeys';
import { useDetectLanguage } from '../hook/useDetectLanguage';

const RouterPage = () => {
    const language = useDetectLanguage();

    navigate(`/${language}/`, { replace: true });

    const [, setBusinessType] = useSessionStorage(SessionStorageKeys.BUSINESS_TYPE, BusinessType.B2C);

    useMount(() => {
        setBusinessType(BusinessType.B2B);
    })

    // only for routing
    return (
        <>
        </>
    )
}

export default RouterPage
