import { makeStyles } from "@material-ui/styles";
import { Theme } from "@material-ui/core";

export const getClasses = makeStyles(({ breakpoints, palette }: Theme) => ({
    contentJourneySelector: {
      display: 'flex',
      flex:1,
      justifyContent: 'space-between',
      flexDirection: 'column',
      [breakpoints.up("md")]: {
        marginTop: "120px",
      },
      [breakpoints.down("sm")]: {
        marginTop: "40px",
      },
      [breakpoints.only("xs")]: {
        alignItems: 'center'
      },
    },
    tileContainer: {
      width: "100%",
      marginBottom: "20px",
      paddingBottom: "20px",
      display: 'flex',
      flex:1,
      [breakpoints.up("md")]: {
        justifyContent: 'space-between',
        marginBottom: "120px",
      },
      [breakpoints.down("sm")]: {
        marginBottom: "20px",
      },
      [breakpoints.only("xs")]: {
        justifyContent: 'center',
      },
    },
    carousel:{
      width: "100%"
    },
    carouselTileGroup: {
      width: "100%",
      marginBottom: "20px",
      display: 'flex',
      flex:1,
      [breakpoints.up("sm")]: {
        justifyContent: 'space-between',
      },
      [breakpoints.only("xs")]: {
        justifyContent: 'center',
      },
    },
    tile: {
      display: "flex",
      width: "30%",
      [breakpoints.only("xs")]: {
        width: "100%",
      },
      [breakpoints.only("sm")]: {
        width: "48%",
        minHeight: '600px'
      },
      flexDirection: 'column',
      boxShadow: "0 0 16px rgb(0 0 0 / 13%)",
      borderRadius: "10px"
    },
    tileImage: {
      width: "100%",

    },
    tileTitle: {
      [breakpoints.down("md")]: {
        fontSize: 18,
        marginTop: "24px",
      },
      [breakpoints.up("lg")]: {
        fontSize: 28,
        marginTop: "40px",
      },
    },
    tileContent: {
      display: 'flex',
      flexDirection: 'column',
      width: "80%",
      marginLeft: "10%",
      marginRight: "10%",
    },
    title: {
      [breakpoints.up("md")]: {
        marginBottom: "32px",
      },
      [breakpoints.down("sm")]: {
        marginBottom: "16px",
      },
    },
    goToCalculationButton: {
        padding: "18.5px",
        fontSize: '16px',
        [breakpoints.down("md")]: {
          marginBottom: '24px'
        },
        [breakpoints.up("lg")]: {
            marginBottom: '72px'
      },
    },
    goToCalculationButtonText: {
      display: "flex",
      flexDirection: "row",
      alignItems: "center",
      color: "white",
      boxShadow: "0 0 16px rgb(0 0 0 / 13%)",
      borderRadius: "10px"
    },
    carouselIndicator: {
      color: '#f64b54'
    },
    certImage :{
      height: 33,
      position: 'absolute',
      bottom: 20,
      right: 20
    },
    image: {
      objectFit: 'contain'

    },
    tilePlace: {
      display: 'flex',
      alignItems: 'center',
      [breakpoints.up("md")]: {
        marginTop: "24px",
      },
      [breakpoints.down("sm")]: {
        marginTop: "8px",
      },
    },
    tilePlaceText: {
      marginLeft: '5px'
    },
    tileTeaser: {
      [breakpoints.up("md")]: {
        marginBottom: "40px",
      },
      [breakpoints.down("sm")]: {
        marginBottom: "24px",
      },
    },
    tileTeaserText: {
      marginBottom: "-15px"
    },
  }));
