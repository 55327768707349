import React, { useContext } from 'react';
import { IPropsLottieBase, Lottie, unwrapJsonModule } from 'react-lottie-v2';
import { PageContext } from '../../../layouts/MainLayout';
import * as ICON_DATA from '../../../assets/icons/animated/globe.json';
import { useTheme } from '@material-ui/core';
import { colorizeLottieAnimation } from '@cpyou/shared/dist';

const GlobeIcon = (props: IPropsLottieBase) => {
    const { coBrand } = useContext(PageContext);
    const theme = useTheme();

    colorizeLottieAnimation(ICON_DATA, coBrand ? { primary: coBrand.primaryColor, secondary: coBrand.secondaryColor } : { primary: '#000000', secondary: theme.palette.primary.main});

    return (

    <Lottie {...props} animationData={unwrapJsonModule(ICON_DATA)} />
)}

export default GlobeIcon;
