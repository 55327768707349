/**
 * This file was generated
 */
export enum IPaymentTypeCreateTransactionRequest {

    'PAYPAL'='PAYPAL',

    'INVOICE'='INVOICE',

}
