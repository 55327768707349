import React, { useContext, useState } from 'react';
import { BLOCKS, INLINES } from '@contentful/rich-text-types';
import { documentToHtmlString } from '@contentful/rich-text-html-renderer';
import { GRichTextForRenderer } from '../../interface/graphql/GRichTextForRenderer';
import { getContentLanguageName } from '../../functions/getContentLanguageName';
import { PageContext } from '../../layouts/MainLayout';
import { IPageContext } from '../../interface/PageContext';
import { getClasses } from './RichTextRenderer.jss'
import { joinClasses } from '../../functions/join';
import { FeatureFlagsContext } from '../App';
import { IFeatureFlags, FeatureFlags } from '@cpyou/shared/dist';

export const renderNode = (node, props: { language: string, embeddedAssetImageClass: string, embeddedAssetDescriptionClass: string, embeddedAssetContainerClass: string, linkClass: string, newBrandDesignEnabled?: boolean, listItemClass?: string, listClass?: string  }) => {
  const renderer = getRendererOptions(props);
  return renderer.renderNode[node.nodeType](node);
}

export const getRendererOptions = (props: { language: string, embeddedAssetImageClass: string, embeddedAssetDescriptionClass: string, embeddedAssetContainerClass: string, linkClass: string, newBrandDesignEnabled?: boolean, listItemClass?: string, listClass?: string   }) => {
    const imgId = `img-${props.embeddedAssetDescriptionClass.replace(' ', '-')}`

    return {
        renderNode: {
            ["text"]: (node: any) => node.value.replace(/\n/g, '<br>'),
            [INLINES.HYPERLINK]: (node: any) => ` <a href="${node.data.uri}" class="${props.linkClass}" target="_blank">${node.content.map((v: any) => v.value).join(' ')}</a> `,
            [BLOCKS.EMBEDDED_ASSET]: (node: any) => {
              const field = node.data.target.fields;
              let description = '';
              if (field.description) {
                description = `<span id="${imgId}" class="${props.embeddedAssetDescriptionClass}">${field.description[getContentLanguageName(props.language)]}</span>`;
              }
              return (field.file && field.title) ? ` <div class="${props.embeddedAssetContainerClass}"><img src="${field.file[getContentLanguageName(props.language)].url}" class="${props.embeddedAssetImageClass}" alt="${field.title[getContentLanguageName(props.language)]}" aria-describedby="${imgId}" />${description}</div>` : '<div></div>'
            },
            [INLINES.ASSET_HYPERLINK]: (node: any) => {
              return ` <a href="${node.data.target.fields.file[getContentLanguageName(props.language)].url}" class="${props.linkClass}" target="_blank">${node.content.map((v: any) => v.value).join(' ')}</a> `
            },
            [BLOCKS.LIST_ITEM]: (node:any) => {
              return `<li class="${props.listItemClass}">${node.content.map((node: any) => renderNode(node, props)).join(' ')}</li>`
            },
            [BLOCKS.UL_LIST]: (node:any) => {
              return `<ul class="${props.listClass}">${node.content.map((node: any) => renderNode(node, props)).join(' ')}</ul>`
            },
            [BLOCKS.PARAGRAPH]: (node: any) => {
              const style = hasBoldMark(node.content) ? "font-family: Proxima Nova Bold;" :  ""

              return props.newBrandDesignEnabled ? `
                <p class="MuiTypography-root MuiTypography-body1" style="${style}">
                  ${node.content.map((node: any) => renderNode(node, props)).join(' ')}
                </p>` : `<p>${node.content.map((node: any) => renderNode(node, props)).join(' ')}</p>`;
            },
        },
}};

export interface IPropsRichText {
    children: GRichTextForRenderer;
    embeddedAssetImageClass?: string;
    embeddedAssetDescriptionClass?: string;
    embeddedAssetContainerClass?: string;
    linkClass?: string;
    listItemClass?: string;
    listClass?: string;

}

export const RichTextRenderer = ({
    children, embeddedAssetImageClass, embeddedAssetDescriptionClass, embeddedAssetContainerClass, linkClass, listItemClass, listClass
}: IPropsRichText) => {
    const { language } = useContext(PageContext) as IPageContext
    const classes = getClasses()
    const featureFlags = useContext(FeatureFlagsContext) as IFeatureFlags;
    const [newBrandDesignEnabled] = useState(featureFlags[FeatureFlags.NEW_BRAND_DESIGN]);
    const richTextDocument = JSON.parse(children?.children[0]?.internal?.content);

    const renderedRichText = documentToHtmlString(richTextDocument, getRendererOptions({
        language,
        embeddedAssetImageClass: joinClasses([classes.embeddedAssetImage, embeddedAssetImageClass]),
        embeddedAssetDescriptionClass: joinClasses([classes.embeddedAssetDescription, embeddedAssetDescriptionClass]),
        embeddedAssetContainerClass: joinClasses([classes.embeddedAssetContainer, embeddedAssetContainerClass]),
        linkClass: joinClasses([linkClass]),
        listItemClass: joinClasses([listItemClass]),
        listClass: joinClasses([listClass]),
        newBrandDesignEnabled
    }));

    return (<div dangerouslySetInnerHTML={{ __html: renderedRichText }} />);
}

function hasBoldMark(nodeContent) {
  const contentWithMarks = nodeContent.filter(c => c.marks !== undefined)
  if (contentWithMarks.length === 0) return false

  const marks = contentWithMarks[0].marks
  return marks.find(m => m.type === 'bold') !== undefined
}
