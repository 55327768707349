import { getLocalStorageApi } from '@cpyou/shared/dist';
import { graphql, useStaticQuery } from 'gatsby';

export const DEFAULT_LANGUAGE = 'en'

const getLanguage = (language: string) => language.substring(0, 2).toLowerCase();

export const useDetectLanguage = (defaultLanguage: string = DEFAULT_LANGUAGE) => {
    const queryLocales: { allContentfulLocale: { nodes: Array<{ code: string }> } } = useStaticQuery(graphql`
    {
      allContentfulLocale{
        nodes{
          code
        }
      }
    }`);

    const foundLanguage: string | null = getLocalStorageApi().getItem('language');
    let resultLanguage;

    const contentLanguages = queryLocales.allContentfulLocale.nodes
        .map((n) => getLanguage(n.code));

    if (foundLanguage) {
        resultLanguage = contentLanguages.find((l) => l === foundLanguage)
    }
    if (!resultLanguage && typeof window !== 'undefined' && window.navigator) {
        resultLanguage = contentLanguages.find((l) => l === getLanguage(window.navigator.language))
    }

    return resultLanguage || defaultLanguage;
}
