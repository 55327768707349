import { Base64 } from 'js-base64';

export const getLocationSearchParameters = (locationSearch: string): any => {
    let search = locationSearch;
    if (locationSearch.startsWith('?')) {
        search = search.substring(1);
    }
    try {
        return JSON.parse(Base64.decode(search));
    } catch (e) {
        return {}
    }
}
