"use strict";
exports.__esModule = true;
exports.restore = exports.persist = exports.getLocalStorageApi = exports.getSessionStorageApi = exports.getStorageApi = void 0;
globalThis.$shadowStorage = {
    length: 0
};
var shadowStorage = globalThis.$shadowStorage;
var mockLocalStorage = {
    length: 0,
    key: function (index) { return shadowStorage[index]; },
    clear: function () {
        globalThis.$shadowStorage = {
            length: 0
        };
    },
    getItem: function (key) { return shadowStorage[key]; },
    setItem: function (key, value) {
        shadowStorage[key] = value;
        //  eslint-disable-next-line
        if (!mockLocalStorage.hasOwnProperty(key)) {
            shadowStorage[shadowStorage.length] = key;
            shadowStorage.length = shadowStorage.length + 1;
            Object.defineProperty(mockLocalStorage, key, {
                get: function () { return shadowStorage[key]; },
                set: function (value) { return shadowStorage[key] = value; }
            });
        }
    },
    removeItem: function (key) {
        if (shadowStorage[key]) {
            delete shadowStorage[key];
            for (var i = 0; i < shadowStorage.length; i++) {
                if (key === shadowStorage[i]) {
                    delete shadowStorage[i];
                }
            }
            shadowStorage.length = shadowStorage.length - 1;
        }
    }
};
Object.defineProperty(mockLocalStorage, 'length', {
    get: function () { return shadowStorage.length; }
});
var getStorageApi = function (mode) {
    try {
        var storageApiImpl = void 0;
        if (typeof window !== 'undefined' && window.localStorage) {
            if (mode === 'LOCAL') {
                storageApiImpl = window.localStorage;
            }
            else if (mode === 'SESSION') {
                storageApiImpl = window.sessionStorage;
            }
            else {
                storageApiImpl = mockLocalStorage;
            }
        }
        else {
            storageApiImpl = mockLocalStorage;
        }
        // test impl
        storageApiImpl.setItem('__test', '123');
        if (storageApiImpl.getItem('__test') !== '123') {
            throw "StorageException"; // runs in catch() branch
        }
        return storageApiImpl;
    }
    catch (e) {
        // security exceptions can happen by just attempting to
        // read the value of sessionStorage or localStorage on a
        // window object that is
        // - accessed inside of an <iframe>
        // - in cross-origin (coBrand case)
        // - when in private browsing mode
        return mockLocalStorage;
    }
};
exports.getStorageApi = getStorageApi;
var getSessionStorageApi = function () { return exports.getStorageApi('SESSION'); };
exports.getSessionStorageApi = getSessionStorageApi;
var getLocalStorageApi = function () { return exports.getStorageApi('LOCAL'); };
exports.getLocalStorageApi = getLocalStorageApi;
var persist = function (key, data, mode) {
    exports.getStorageApi(mode).setItem(key, JSON.stringify(data));
};
exports.persist = persist;
var restore = function (key, mode, defaultValue) {
    if (defaultValue === void 0) { defaultValue = {}; }
    var jsonRawData = exports.getStorageApi(mode).getItem(key);
    if (jsonRawData) {
        return JSON.parse(jsonRawData);
    }
    return defaultValue;
};
exports.restore = restore;
