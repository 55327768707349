import { makeStyles } from '@material-ui/styles';
import { Theme } from '@material-ui/core';


export const getClasses = (backgroundUrl?: string) => makeStyles(({ breakpoints }: Theme) => {
    let image = {}
    let rect = {}

    if (backgroundUrl) {
        image = {
            background: `url("${backgroundUrl}") no-repeat center`,
            backgroundSize: 'cover',
        }
        rect = {
            backgroundColor: 'rgba(0,0,0,0.3)',
        }
    }
    return {
        backgroundRect: {
            width: '100%',
            minHeight: '100vh',
            ...rect,
        },
        background: {
          width: '100%',
          minHeight: '100vh',
          ...image,
      },
      header: {
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
          padding: '44px 50px',
          [breakpoints.down('sm')]: {
            padding: '24px 20px 32px 20px'
        },
      }
    }
})()
