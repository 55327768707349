"use strict";
exports.__esModule = true;
exports.ComponentEventsSupported = void 0;
var ComponentEventsSupported;
(function (ComponentEventsSupported) {
    // used to notify about component load
    ComponentEventsSupported["COMPONENT_LOAD"] = "you.component.load";
    // used to change the language from outside
    ComponentEventsSupported["COMPONENT_CHANGE_LANGUAGE"] = "you.component.changelanguage";
    // bi-directional: used to navigate and notify
    ComponentEventsSupported["COMPONENT_CHANGE_STEP"] = "you.component.changestep";
    // bi-directional: used to navigate and notify
    ComponentEventsSupported["COMPONENT_STEP_BACK"] = "you.component.stepback";
    // bi-directional: used to navigate and notify
    ComponentEventsSupported["COMPONENT_STEP_NEXT"] = "you.component.stepnext";
    ComponentEventsSupported["COMPONENT_BACK_TO_LANDING_PAGE"] = "you.component.stepToLandingPage";
    // used to notify outside listeners about config upates
    ComponentEventsSupported["COMPONENT_CONFIG_CHANGE"] = "you.component.configchange";
    ComponentEventsSupported["COMPONENT_FEEDBACK"] = "you.component.feedback";
    // used to trigger an priority update of the config from outside
    ComponentEventsSupported["COMPONENT_UPDATE_CONFIG"] = "you.component.updateconfig";
    // used to notify outside listeners about specific links clicked
    // TODO: refactor: use COMPONENT_EXTERNAL_LINK_CLICKED instead
    ComponentEventsSupported["COMPONENT_PRESSED_LEGAL_NOTICE"] = "component-pressed-legal-notice";
    ComponentEventsSupported["COMPONENT_PRESSED_TERMS_OF_USE"] = "component-pressed-terms-of-use";
    // used to route with Gatsby navigate() instead of component-internal routing
    // TODO: refactor: use COMPONENT_EXTERNAL_LINK_CLICKED instead
    ComponentEventsSupported["COMPONENT_PRESSED_BACK_TO_MAIN_PAGE"] = "component-pressed-back-to-main-page";
    // unused, but planned for refactoring
    ComponentEventsSupported["COMPONENT_EXTERNAL_LINK_CLICKED"] = "you.component.externallinkclicked";
    ComponentEventsSupported["COMPONENT_INTERNAL_LINK_CLICKED"] = "you.component.internallinkclicked";
    // updates the URL bar after a component-internal navigation has happened
    ComponentEventsSupported["COMPONENT_UPDATE_HISTORY"] = "you.component.updatehistory";
    // fired when user clicks an external link and/or confirms the LeaveModal
    ComponentEventsSupported["COMPONENT_USER_JOURNEY_EXIT"] = "you.component.userjourneyexit";
    // fired then user visits the first step of a calculation flow
    ComponentEventsSupported["COMPONENT_USER_JOURNEY_STARTED"] = "you.component.userjourneystarted";
    // fired when the compensation has happened
    ComponentEventsSupported["COMPONENT_USER_JOURNEY_COMPLETED"] = "you.component.userjourneycompleted";
    // fired when user signs up for mailchimp list
    ComponentEventsSupported["COMPONENT_MAILCHIMP_SIGNUP"] = "you.component.mailchimpsignup";
    // location of the page changed; ususally happens after COMPONENT_UPDATE_HISTORY
    ComponentEventsSupported["COMPONENT_LOCATION_CHANGE"] = "you.component.locationchange";
    // user compensated (payed)
    ComponentEventsSupported["COMPONENT_COMPENSATED"] = "you.component.compensated";
    // track an arbitrary event using Mixpanel
    ComponentEventsSupported["COMPONENT_TRACK_EVENT"] = "you.component.trackevent";
})(ComponentEventsSupported = exports.ComponentEventsSupported || (exports.ComponentEventsSupported = {}));
