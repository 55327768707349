import { useEffectOnce } from "react-use";

/**
 * Does wait for a key in an object to be defined.
 * Once it renders defined, calls the callback function.
 * Also works with SSG (server-side generation).
 * @param object The object to check (most usually, window)
 * @param key The name of the key to check
 * @param cb Function to be called when the key is defined
 * @param timerIntervalTimeMs Timer check schedule in milliseconds
 */
export const useWaitForDefined = (object: any, key: string, cb: Function, timerIntervalTimeMs = 50) => {

  const callOnDefined = () => {
    if (object && object[key]) {
      cb();
      return true;
    }
    return false;
  }

  useEffectOnce(() => {
    // if it is defined already, we don't need to interval-check
    if (!callOnDefined()) {

      // in case of interval check...
      const waitForRefToBeSet = setInterval(() => {
        if (callOnDefined()) {
          // ...interval needs to be cleared as well
          clearInterval(waitForRefToBeSet);
        }
      }, timerIntervalTimeMs);
    }
  })
}
