import { makeStyles } from "@material-ui/styles";
import { Theme } from "@material-ui/core";

export const getClasses = makeStyles(({ breakpoints }: Theme) => ({
    contentAbout: {
      display: 'flex',
      backgroundColor: 'white',
      justifyContent: 'space-between',
      [breakpoints.down('sm')]: {
        flexDirection: 'column'
      },
      [breakpoints.up('md')]: {
        alignItems: 'center',
      },
    },
    textContent: {
      display: "flex",
      flexDirection: "column",
      paddingTop: "40px",
      paddingBottom: "20px",
    },
    title: {
      marginBottom: "20px"
    },
    teaser: {
      [breakpoints.down('sm')]: {
        fontSize: 16
      },
      [breakpoints.up('md')]: {
        fontSize: 24
      },
    },
    link: {},
    image: {
      [breakpoints.down('sm')]: {
        width: '100vw',
      },
    },

}));
