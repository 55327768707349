import { useCallback, useContext } from 'react';
import { KeyValuesContext } from '../components/KeyValuesContext';

export const useKeyFunction = () => {
    // TODO: use static query
    const translations = useContext(KeyValuesContext);
    return useCallback((keyValue: string, options: {[variableName: string]: string} = {}) => {
        const valueWithVariables = translations[keyValue];
        if (!valueWithVariables) {
            // eslint-disable-next-line no-console
            console.warn(`missing key '${keyValue}' in translations`)
        }
        let value = valueWithVariables || keyValue;
        Object.keys(options).forEach((opt) => {
            const key = `{{${opt}}}`;
            value = value.split(key).join(options[opt])
        });
        return value;
    }, [translations]);
}
