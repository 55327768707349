import { getColorPalette, GutterDesktopAllPx, GutterMobilePx, GutterTabletPx, ICoBrand, IFeatureFlags, ISizeModel } from '@cpyou/shared/dist';
import { darken, lighten, ThemeOptions } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles'

export const useStyles = (featureFlags: IFeatureFlags, coBrand: ICoBrand, sizeModel: ISizeModel) => makeStyles(({ palette }: ThemeOptions) => ({
    root: {
        flexGrow: 1,
        userSelect: 'none',
        position: 'relative',
        marginTop: !(sizeModel.isMobile || sizeModel.isTablet) ? 68 : 0,
    },
    rootButtons: {
        display: 'flex',
        justifyContent: 'center',
        padding: '8px',
        position: 'absolute',
        bottom: 0,
        // middle position using the absolute percentage hack sub half of width
        right: 'calc(50% - 51px)',
        backgroundColor: 'transparent',
    },
    rootDotsMobile: {
        left: '50%',
        right: 'unset',
    },
    img: {
        height: 400,
        display: 'block',
        overflow: 'hidden',
        width: '100%',
        objectFit: 'cover',
    },
    projectCertImage: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        backgroundColor: 'white',
        position: 'absolute',
        top: -35,
        right: sizeModel.isMobile ? +GutterMobilePx : sizeModel.isTablet ? +GutterTabletPx : sizeModel.isDesktop ? 0 : sizeModel.isDesktopXL ? (GutterDesktopAllPx / 4) : -(GutterDesktopAllPx / 8),
        zIndex: 2,
        borderRadius: 5,
    },
    certificationImage: {
        width: (sizeModel.isMobile || sizeModel.isTablet) ? 190 : 135,
        marginRight: sizeModel.isMobile ? +GutterMobilePx : sizeModel.isTablet ? +GutterTabletPx : 0,
        paddingLeft: 15,
        paddingRight: 15,
        paddingTop: 5,
        paddingBottom: 5
    },
    partnerImage: {
        height: 101,
        width: 144,
    },
    imageCertificate: {
    },
    imagePartner: {
        height: 58,
    },
    divProject: {
        backgroundColor: getColorPalette(featureFlags, coBrand, lighten, darken).primary.tint60Percent,
        position: 'absolute',
        top: (sizeModel.isMobile || sizeModel.isTablet) ? -10 : -28,
        right: -8,
        paddingLeft: 8,
        paddingRight: 8,
        fontStyle: 'italic',
        borderRadius: 30,
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
    },
    dots: {
        display: 'none',
    },
    dotsMobile: {
        display: 'flex',
        alignItems: 'center',
        position: 'relative',
        left: '-50%',
    },
    dot: {
        width: 8,
        height: 8,
        margin: '0 7px',
        borderRadius: '50%',
        border: '1px solid white',
        backgroundColor: 'transparent',
    },
    dotActive: {
        width: 14,
        height: 14,
        borderColor: getColorPalette(featureFlags, coBrand, lighten, darken).primary.tint30Percent,
        backgroundColor: getColorPalette(featureFlags, coBrand, lighten, darken).primary.tint30Percent,
    },
}));
