export const joinClasses = (...elements: Array<string|undefined|Array<string | undefined> |{[className: string]: boolean}>) => elements
    .map((v) => {
        if (!!v && Array.isArray(v)) {
            return v.filter((c) => !!c).reduce((prev, curr) => `${prev} ${curr}`, '');
        } if (!!v && typeof v === 'object') {
            const joinedClasses: Array<string> = []
            Object.keys(v).forEach((clazz) => {
                if (v[clazz]) {
                    joinedClasses.push(clazz);
                }
            })
            return joinedClasses.join(' ');
        }
        return v;
    })
    .filter((v) => !!v)
    .join(' ')
