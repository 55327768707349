import React, { useContext } from "react";
import { Typography } from "@material-ui/core";

import { GSection } from "../../../../interface/graphql/GSection";
import { RichTextRenderer } from "../../../../components/contentful/RichtTextRenderer";

import { getClasses } from "./NewWhoWeAreContent.jss";

import { ContentLayout } from "../../../../layouts/ContentLayout";
import ContentImage, {
    ContentImageType,
} from "../../../../layouts/ContentImage";

export const NewWhoWeAreContent = ({
    section,
    isLastSection,
}: {
    section: GSection;
    isLastSection: boolean;
}) => {
    const { title, text, image } = section;
    const classes = getClasses();

    const containerPaddingBottom = isLastSection ? "80px" : 0;

    return (
        <ContentLayout containerPaddingBottom={containerPaddingBottom}>
            <Typography variant={"h2"} className={classes.contentTitle}>
                {title}
            </Typography>

            <Typography variant="body1">
                <RichTextRenderer
                    listClass={classes.listClass}
                    listItemClass={classes.listItemClass}
                    embeddedAssetImageClass={classes.imageClass}
                    linkClass={classes.linkClass}
                >
                    {text}
                </RichTextRenderer>
            </Typography>

            <ContentImage
                image={image}
                contentImageType={ContentImageType.DEFAULT}
            />
        </ContentLayout>
    );
};
