import React, {
    memo,
    SyntheticEvent,
    useCallback,
    useContext,
    useLayoutEffect,
    useState,
} from "react";
import { graphql, PageProps } from "gatsby";
import { GHeader } from "../../interface/graphql/GHeader";
import { App, FeatureFlagsContext } from "../../components/App";
import { mapKeyValues } from "../../functions/mapKeyValues";
import { getClasses } from "./ClimateOffsetProjectPageTemplate.jss";
import { NewMainBrandLayout } from "../../layouts/NewMainBrandLayout";
import { useOnResize, useViewport } from "@cpyou/shared/dist/lib/useViewport";
import { getImageUrl } from "../../functions/getImageUrl";
import {
    Avatar,
    Box,
    Button,
    darken,
    lighten,
    Typography,
    useTheme,
} from "@material-ui/core";
import { ShowGridComponent } from "@cpyou/shared/dist/lib/ShowGridComponent";
import { ImageStepper } from "../../components/ImageStepper";
import { GClimateOffsetProject } from "../../interface/graphql/GClimateOffsetProject";
import { joinClasses } from "../../functions/join";
import {
    BREAKPOINTS,
    FeatureFlags,
    getColorPalette,
    getLocalStorageApi,
    IFeatureFlags,
    isWebsiteReferred,
    Margin,
    useForceUpdate,
} from "@cpyou/shared/dist";
import { Location } from "../../components/icon/Location";
import { PageContext } from "../../layouts/MainLayout";
import { BackTo } from "../../components/BackTo";
import { KeyValuesContext } from "../../components/KeyValuesContext";
import { LayoutContext } from "../../context/layoutContext";
import { formatNumber, useTwoPassRender } from "@cpyou/shared/dist";
import { GClimateOffsetProjectPage } from "../../interface/graphql/GClimateOffsetProjectPage";
import { pageToLink } from "../../functions/pageToLink";
import { OffsetProjects } from "../NewHomePage/NewHomePageTemplate";
import { GLink } from "../../interface/graphql/GLink";
export interface IPropsClimateOffsetProjectPageTemplate {
    allContentfulKeyValue: any;
    contentfulClimateOffsetProjectPage: GClimateOffsetProjectPage;
}

const ClimateOffsetProjectPageTemplate = (
    props: PageProps<IPropsClimateOffsetProjectPageTemplate>
) => {
    const {
        data: { contentfulClimateOffsetProjectPage, allContentfulKeyValue },
        pageContext,
    } = props;
    const {
        seo,
        header,
        footer,
        offsetForProjectLink,
    } = contentfulClimateOffsetProjectPage;
    const keyValues = mapKeyValues(allContentfulKeyValue.nodes);

    return (
        <App
            pageContext={pageContext as any}
            seoContext={seo}
            header={header}
            footerContext={footer}
            keyValuesContext={keyValues}
            borderMobileMenu
        >
            <OffsetProject
                offsetForProjectLink={offsetForProjectLink}
                header={header}
                climateOffsetProject={
                    contentfulClimateOffsetProjectPage.climateOffsetProject
                }
            />
        </App>
    );
};

interface IOffsetProjectProps {
    header: GHeader;
    climateOffsetProject: GClimateOffsetProject;
    offsetForProjectLink: GLink;
}

const ProjectImageStepper = ({
    offsetProject,
}: {
    offsetProject: GClimateOffsetProject;
}) => {
    const { containerRef } = useContext(LayoutContext);
    const sizeModel = useViewport(BREAKPOINTS, containerRef);

    // quadratic height enforcement calculation
    let columnsUsedForImage = 12;
    let margins = 0;
    if (sizeModel.isDesktopXL || sizeModel.isDesktopXXL) {
        columnsUsedForImage = 4;
    }
    if (sizeModel.isDesktop) {
        columnsUsedForImage = 5;
    }
    if (sizeModel.isMobile || sizeModel.isTablet) {
        // on mobile/tablet the image goes fullscreen
        // so we need to add margin widths to become
        // truly quadratic
        margins = 2 * sizeModel.marginWidth;
    }

    const height =
        columnsUsedForImage * sizeModel.columnWidth +
        (columnsUsedForImage - 1) /* gutter count */ * sizeModel.gutterWidth +
        margins;

    // const projectCertImage =
    //     offsetProject.primaryUnitIcon === "water"
    //         ? offsetProject.certificateImages[0].file.url
    //         : offsetProject.partnerImages[0].file.url;

    return (
        <ImageStepper
            height={height}
            projectImages={offsetProject.projectImages}
            // projectCertImage={
            //     !offsetProject.isStandaloneProject ? projectCertImage : null
            // }
            projectType={offsetProject.primaryUnitIcon}
        />
    );
};

export interface IProjectSectionProps {
    offsetProject: GClimateOffsetProject;
}

const ProjectLocation = ({ offsetProject }: IProjectSectionProps) => {
    const { containerRef } = useContext(LayoutContext);
    const sizeModel = useViewport(BREAKPOINTS, containerRef);
    const featureFlags = useContext(FeatureFlagsContext);
    const { coBrand } = useContext(PageContext);
    const classes = getClasses(featureFlags, coBrand, sizeModel)(useTheme());

    return (
        <div
            className={joinClasses({
                [classes.location]: true,
            })}
        >
            {offsetProject.places.map((place: any) => (
                <div className={classes.locationEntry} key={place.title}>
                    <Location
                        color={
                            getColorPalette(
                                featureFlags,
                                coBrand,
                                lighten,
                                darken
                            ).grey
                        }
                    />
                    <Typography
                        variant="body1"
                        className={classes.locationName}
                    >
                        {offsetProject.isStandaloneProject ? (
                            <span className={classes.hrefStyle}>
                                {place.title}
                            </span>
                        ) : (
                            <a
                                className={joinClasses({
                                    [classes.hrefStyle]: true,
                                })}
                                href={place.url}
                                target="_blank"
                                rel="noopener noreferrer"
                            >
                                {place.title}
                            </a>
                        )}
                    </Typography>
                </div>
            ))}
        </div>
    );
};

const ProjectHeading = ({ title }: { title: string }) => (
    <Typography variant="h2">{title}</Typography>
);

const ProjectBackLink = ({ className }: { className?: string }) => {
    const keyValues = useContext(KeyValuesContext);
    const { containerRef } = useContext(LayoutContext);
    const featureFlags = useContext(FeatureFlagsContext);
    const { coBrand } = useContext(PageContext);
    const sizeModel = useViewport(BREAKPOINTS, containerRef);
    const classes = getClasses(featureFlags, coBrand, sizeModel)(useTheme());

    const onBackLinkClick = () =>
        // eslint-disable-next-line
        useCallback((evt: SyntheticEvent) => {
            // user visited via page navigation
            // (1 reload automatically happens with language change)

            /*
        if (window.history.state != null) {
            navigate(-1)
        } else {
            // direct page visit; send to homepage
            navigate(brandLink('/', coBrand));
        }
        */
            window.history.back();
            evt.preventDefault();
        }, []);

    return (
        <BackTo
            href={"#"}
            className={joinClasses(classes.backLink, className)}
            text={keyValues.Back}
            onClick={onBackLinkClick()}
        />
    );
};

const ProjectHeader = ({ offsetProject }: IProjectSectionProps) => {
    const { containerRef } = useContext(LayoutContext);
    const sizeModel = useViewport(BREAKPOINTS, containerRef);
    const forceUpdate = useForceUpdate();

    const featureFlags = useContext(FeatureFlagsContext);
    const { coBrand } = useContext(PageContext);
    const classes = getClasses(featureFlags, coBrand, sizeModel)(useTheme());

    useLayoutEffect(() => {
        // eslint-disable-next-line
        return useOnResize(
            () => {
                forceUpdate();
            },
            BREAKPOINTS,
            containerRef
        );
    });

    return (
        <div className={classes.root}>
            {sizeModel.isMobile && (
                <React.Fragment key="isMobile">
                    <Box
                        role="row"
                        display="flex"
                        flex={1}
                        flexWrap="nowrap"
                        flexDirection="row"
                    >
                        <Margin viewportEl={containerRef} />
                        <Box
                            role="content"
                            width={
                                11 * sizeModel.columnWidth +
                                10 * sizeModel.gutterWidth
                            }
                        >
                            <ShowGridComponent
                                color="green"
                                width={
                                    11 * sizeModel.columnWidth +
                                    10 * sizeModel.gutterWidth
                                }
                            />
                            {!offsetProject.isStandaloneProject ? (
                                <ProjectBackLink />
                            ) : (
                                <div className={classes.backLink} />
                            )}
                            <ProjectHeading title={offsetProject.projectName} />
                            <ProjectLocation offsetProject={offsetProject} />
                        </Box>
                        <Box
                            role="content"
                            width={
                                sizeModel.gutterWidth + sizeModel.columnWidth
                            }
                        >
                            <ShowGridComponent
                                color="blue"
                                width={
                                    sizeModel.columnWidth +
                                    sizeModel.gutterWidth
                                }
                            />
                        </Box>
                        <Margin viewportEl={containerRef} />
                    </Box>
                    <Box
                        role="row"
                        display="flex"
                        flex={1}
                        flexWrap="nowrap"
                        flexDirection="row"
                    >
                        <Box
                            role="content"
                            width={
                                12 * sizeModel.columnWidth +
                                11 * sizeModel.gutterWidth +
                                2 * sizeModel.marginWidth
                            }
                        >
                            <ShowGridComponent
                                color="green"
                                width={
                                    12 * sizeModel.columnWidth +
                                    11 * sizeModel.gutterWidth +
                                    2 * sizeModel.marginWidth
                                }
                            />
                            <ProjectImageStepper
                                offsetProject={offsetProject}
                            />
                        </Box>
                    </Box>
                </React.Fragment>
            )}
            {sizeModel.isTablet && (
                <React.Fragment key="isTablet">
                    <Box
                        role="row"
                        display="flex"
                        flex={1}
                        flexWrap="nowrap"
                        flexDirection="row"
                    >
                        <Margin viewportEl={containerRef} />
                        <Box
                            role="content"
                            width={
                                3 * sizeModel.columnWidth +
                                2 * sizeModel.gutterWidth
                            }
                        >
                            <ShowGridComponent
                                color="orange"
                                width={
                                    3 * sizeModel.columnWidth +
                                    2 * sizeModel.gutterWidth
                                }
                            />
                        </Box>
                        <Box
                            role="content"
                            width={
                                8 * sizeModel.columnWidth +
                                9 * sizeModel.gutterWidth
                            }
                        >
                            <ShowGridComponent
                                color="green"
                                width={
                                    8 * sizeModel.columnWidth +
                                    9 * sizeModel.gutterWidth
                                }
                            />
                            {!offsetProject.isStandaloneProject ? (
                                <ProjectBackLink />
                            ) : (
                                <div className={classes.backLink} />
                            )}
                            <ProjectHeading title={offsetProject.projectName} />
                            <ProjectLocation offsetProject={offsetProject} />
                        </Box>
                        <Box role="content" width={sizeModel.columnWidth}>
                            <ShowGridComponent
                                color="blue"
                                width={sizeModel.columnWidth}
                            />
                        </Box>
                        <Margin viewportEl={containerRef} />
                    </Box>
                    <Box
                        role="row"
                        display="flex"
                        flex={1}
                        flexWrap="nowrap"
                        flexDirection="row"
                    >
                        <Box
                            role="content"
                            width={
                                12 * sizeModel.columnWidth +
                                11 * sizeModel.gutterWidth +
                                2 * sizeModel.marginWidth
                            }
                        >
                            <ShowGridComponent
                                color="green"
                                width={
                                    12 * sizeModel.columnWidth +
                                    11 * sizeModel.gutterWidth +
                                    2 * sizeModel.marginWidth
                                }
                            />
                            <ProjectImageStepper
                                offsetProject={offsetProject}
                            />
                        </Box>
                    </Box>
                </React.Fragment>
            )}
            {sizeModel.isDesktop && (
                <React.Fragment key="isDesktop">
                    <Box
                        role="row"
                        display="flex"
                        flex={1}
                        flexWrap="nowrap"
                        flexDirection="row"
                    >
                        <Margin viewportEl={containerRef} />
                        <Box
                            role="content"
                            width={
                                sizeModel.columnWidth + sizeModel.gutterWidth
                            }
                        >
                            <ShowGridComponent
                                color="orange"
                                width={
                                    sizeModel.columnWidth +
                                    sizeModel.gutterWidth
                                }
                            />
                        </Box>
                        <Box
                            role="content"
                            width={
                                5 * sizeModel.columnWidth +
                                4 * sizeModel.gutterWidth
                            }
                        >
                            <ShowGridComponent
                                color="red"
                                width={
                                    5 * sizeModel.columnWidth +
                                    4 * sizeModel.gutterWidth
                                }
                            />
                            <ProjectImageStepper
                                offsetProject={offsetProject}
                            />
                        </Box>
                        <Box role="content" width={sizeModel.gutterWidth}>
                            <ShowGridComponent
                                color="yellow"
                                width={sizeModel.gutterWidth}
                            />
                        </Box>
                        <Box
                            role="content"
                            width={
                                5 * sizeModel.columnWidth +
                                4 * sizeModel.gutterWidth
                            }
                        >
                            <ShowGridComponent
                                color="green"
                                width={
                                    5 * sizeModel.columnWidth +
                                    4 * sizeModel.gutterWidth
                                }
                            />
                            {!offsetProject.isStandaloneProject ? (
                                <ProjectBackLink />
                            ) : (
                                <div className={classes.backLink} />
                            )}
                            <ProjectHeading title={offsetProject.projectName} />
                            <ProjectLocation offsetProject={offsetProject} />
                        </Box>
                        <Box
                            role="content"
                            width={
                                sizeModel.gutterWidth + sizeModel.columnWidth
                            }
                        >
                            <ShowGridComponent
                                color="blue"
                                width={
                                    sizeModel.gutterWidth +
                                    sizeModel.columnWidth
                                }
                            />
                        </Box>
                        <Margin viewportEl={containerRef} />
                    </Box>
                </React.Fragment>
            )}
            {sizeModel.isDesktopXL && (
                <React.Fragment key="isDesktopXL">
                    <Box
                        role="row"
                        display="flex"
                        flex={1}
                        flexWrap="nowrap"
                        flexDirection="row"
                    >
                        <Margin viewportEl={containerRef} />
                        <Box
                            role="content"
                            width={
                                sizeModel.columnWidth + sizeModel.gutterWidth
                            }
                        >
                            <ShowGridComponent
                                color="orange"
                                width={
                                    sizeModel.columnWidth +
                                    sizeModel.gutterWidth
                                }
                            />
                        </Box>
                        <Box
                            role="content"
                            width={
                                4 * sizeModel.columnWidth +
                                3 * sizeModel.gutterWidth
                            }
                        >
                            <ShowGridComponent
                                color="red"
                                width={
                                    4 * sizeModel.columnWidth +
                                    3 * sizeModel.gutterWidth
                                }
                            />
                            <ProjectImageStepper
                                offsetProject={offsetProject}
                            />
                        </Box>
                        <Box
                            role="content"
                            width={
                                sizeModel.columnWidth + sizeModel.gutterWidth
                            }
                        >
                            <ShowGridComponent
                                color="yellow"
                                width={
                                    sizeModel.columnWidth +
                                    sizeModel.gutterWidth
                                }
                            />
                        </Box>
                        <Box
                            role="content"
                            width={
                                4 * sizeModel.columnWidth +
                                5 * sizeModel.gutterWidth
                            }
                        >
                            <ShowGridComponent
                                color="green"
                                width={
                                    4 * sizeModel.columnWidth +
                                    5 * sizeModel.gutterWidth
                                }
                            />
                            {!offsetProject.isStandaloneProject ? (
                                <ProjectBackLink />
                            ) : (
                                <div className={classes.backLink} />
                            )}
                            <ProjectHeading title={offsetProject.projectName} />
                            <ProjectLocation offsetProject={offsetProject} />
                        </Box>
                        <Box
                            role="content"
                            width={
                                2 * sizeModel.columnWidth +
                                sizeModel.gutterWidth
                            }
                        >
                            <ShowGridComponent
                                color="blue"
                                width={
                                    2 * sizeModel.columnWidth +
                                    sizeModel.gutterWidth
                                }
                            />
                        </Box>
                        <Margin viewportEl={containerRef} />
                    </Box>
                </React.Fragment>
            )}
            {sizeModel.isDesktopXXL && (
                <React.Fragment key="isDesktopXXL">
                    <Box
                        role="row"
                        display="flex"
                        flex={1}
                        flexWrap="nowrap"
                        flexDirection="row"
                    >
                        <Margin viewportEl={containerRef} />
                        <Box
                            role="content"
                            width={
                                sizeModel.columnWidth + sizeModel.gutterWidth
                            }
                        >
                            <ShowGridComponent
                                color="orange"
                                width={
                                    sizeModel.columnWidth +
                                    sizeModel.gutterWidth
                                }
                            />
                        </Box>
                        <Box
                            role="content"
                            width={
                                4 * sizeModel.columnWidth +
                                3 * sizeModel.gutterWidth
                            }
                        >
                            <ShowGridComponent
                                color="red"
                                width={
                                    4 * sizeModel.columnWidth +
                                    3 * sizeModel.gutterWidth
                                }
                            />
                            <ProjectImageStepper
                                offsetProject={offsetProject}
                            />
                        </Box>
                        <Box
                            role="content"
                            width={
                                sizeModel.columnWidth + sizeModel.gutterWidth
                            }
                        >
                            <ShowGridComponent
                                color="yellow"
                                width={
                                    sizeModel.columnWidth +
                                    sizeModel.gutterWidth
                                }
                            />
                        </Box>
                        <Box
                            role="content"
                            width={
                                4 * sizeModel.columnWidth +
                                5 * sizeModel.gutterWidth
                            }
                        >
                            <ShowGridComponent
                                color="green"
                                width={
                                    4 * sizeModel.columnWidth +
                                    5 * sizeModel.gutterWidth
                                }
                            />
                            {!offsetProject.isStandaloneProject ? (
                                <ProjectBackLink />
                            ) : (
                                <div className={classes.backLink} />
                            )}
                            <ProjectHeading title={offsetProject.projectName} />
                            <ProjectLocation offsetProject={offsetProject} />
                        </Box>
                        <Box
                            role="content"
                            width={
                                2 * sizeModel.columnWidth +
                                sizeModel.gutterWidth
                            }
                        >
                            <ShowGridComponent
                                color="blue"
                                width={
                                    2 * sizeModel.columnWidth +
                                    sizeModel.gutterWidth
                                }
                            />
                        </Box>
                        <Margin viewportEl={containerRef} />
                    </Box>
                </React.Fragment>
            )}
        </div>
    );
};

const ProjectDescription = ({ offsetProject }: IProjectSectionProps) => {
    const keyValues = useContext(KeyValuesContext);
    const { containerRef } = useContext(LayoutContext);
    const sizeModel = useViewport(BREAKPOINTS, containerRef);

    return (
        <>
            {/* {!offsetProject.isStandaloneProject && (
                <Typography
                    variant={
                        sizeModel.isMobile || sizeModel.isTablet ? "h3" : "h5"
                    }
                >
                    {keyValues["How does this project save carbon emissions"]}
                </Typography>
            )} */}

            <Typography variant="body1">
                <div
                    dangerouslySetInnerHTML={{
                        __html:
                            offsetProject.effectChainText
                                .childContentfulRichText.html,
                    }}
                ></div>
            </Typography>
        </>
    );
};

const ProjectDescriptionSection = ({ offsetProject }: IProjectSectionProps) => {
    const { containerRef } = useContext(LayoutContext);
    const sizeModel = useViewport(BREAKPOINTS, containerRef);
    const forceUpdate = useForceUpdate();
    const featureFlags = useContext(FeatureFlagsContext);
    const { coBrand } = useContext(PageContext);
    const classes = getClasses(featureFlags, coBrand, sizeModel)(useTheme());

    useLayoutEffect(() => {
        // eslint-disable-next-line
        return useOnResize(
            () => {
                forceUpdate();
            },
            BREAKPOINTS,
            containerRef
        );
    });

    return (
        <div className={classes.descriptionRoot}>
            {sizeModel.isMobile && (
                <Box
                    role="row"
                    display="flex"
                    flex={1}
                    flexWrap="nowrap"
                    flexDirection="row"
                >
                    <Margin viewportEl={containerRef} />
                    <Box
                        role="content"
                        width={
                            12 * sizeModel.columnWidth +
                            11 * sizeModel.gutterWidth
                        }
                    >
                        <ShowGridComponent
                            color="green"
                            width={
                                12 * sizeModel.columnWidth +
                                11 * sizeModel.gutterWidth
                            }
                        />
                        <ProjectDescription offsetProject={offsetProject} />
                    </Box>
                    <Margin viewportEl={containerRef} />
                </Box>
            )}
            {sizeModel.isTablet && (
                <Box
                    role="row"
                    display="flex"
                    flex={1}
                    flexWrap="nowrap"
                    flexDirection="row"
                >
                    <Margin viewportEl={containerRef} />
                    <Box
                        role="content"
                        width={sizeModel.columnWidth + sizeModel.gutterWidth}
                    >
                        <ShowGridComponent
                            color="orange"
                            width={
                                sizeModel.columnWidth + sizeModel.gutterWidth
                            }
                        />
                    </Box>
                    <Box
                        role="content"
                        width={
                            10 * sizeModel.columnWidth +
                            9 * sizeModel.gutterWidth
                        }
                    >
                        <ShowGridComponent
                            color="green"
                            width={
                                10 * sizeModel.columnWidth +
                                9 * sizeModel.gutterWidth
                            }
                        />
                        <ProjectDescription offsetProject={offsetProject} />
                    </Box>
                    <Box
                        role="content"
                        width={sizeModel.columnWidth + sizeModel.gutterWidth}
                    >
                        <ShowGridComponent
                            color="blue"
                            width={
                                sizeModel.columnWidth + sizeModel.gutterWidth
                            }
                        />
                    </Box>
                    <Margin viewportEl={containerRef} />
                </Box>
            )}
            {sizeModel.isDesktop && (
                <Box
                    role="row"
                    display="flex"
                    flex={1}
                    flexWrap="nowrap"
                    flexDirection="row"
                >
                    <Margin viewportEl={containerRef} />
                    <Box
                        role="content"
                        width={sizeModel.columnWidth + sizeModel.gutterWidth}
                    >
                        <ShowGridComponent
                            color="orange"
                            width={
                                sizeModel.columnWidth + sizeModel.gutterWidth
                            }
                        />
                    </Box>
                    <Box
                        role="content"
                        width={
                            10 * sizeModel.columnWidth +
                            9 * sizeModel.gutterWidth
                        }
                    >
                        <ShowGridComponent
                            color="green"
                            width={
                                10 * sizeModel.columnWidth +
                                9 * sizeModel.gutterWidth
                            }
                        />
                        <ProjectDescription offsetProject={offsetProject} />
                    </Box>
                    <Box
                        role="content"
                        width={sizeModel.columnWidth + sizeModel.gutterWidth}
                    >
                        <ShowGridComponent
                            color="blue"
                            width={
                                sizeModel.columnWidth + sizeModel.gutterWidth
                            }
                        />
                    </Box>
                    <Margin viewportEl={containerRef} />
                </Box>
            )}
            {sizeModel.isDesktopXL && (
                <Box
                    role="row"
                    display="flex"
                    flex={1}
                    flexWrap="nowrap"
                    flexDirection="row"
                >
                    <Margin viewportEl={containerRef} />
                    <Box
                        role="content"
                        width={
                            2 * sizeModel.columnWidth +
                            2 * sizeModel.gutterWidth
                        }
                    >
                        <ShowGridComponent
                            color="orange"
                            width={
                                2 * sizeModel.columnWidth +
                                2 * sizeModel.gutterWidth
                            }
                        />
                    </Box>
                    <Box
                        role="content"
                        width={
                            8 * sizeModel.columnWidth +
                            7 * sizeModel.gutterWidth
                        }
                    >
                        <ShowGridComponent
                            color="green"
                            width={
                                8 * sizeModel.columnWidth +
                                7 * sizeModel.gutterWidth
                            }
                        />
                        <ProjectDescription offsetProject={offsetProject} />
                    </Box>
                    <Box
                        role="content"
                        width={
                            2 * sizeModel.columnWidth +
                            2 * sizeModel.gutterWidth
                        }
                    >
                        <ShowGridComponent
                            color="blue"
                            width={
                                2 * sizeModel.columnWidth +
                                2 * sizeModel.gutterWidth
                            }
                        />
                    </Box>
                    <Margin viewportEl={containerRef} />
                </Box>
            )}
            {sizeModel.isDesktopXXL && (
                <Box
                    role="row"
                    display="flex"
                    flex={1}
                    flexWrap="nowrap"
                    flexDirection="row"
                >
                    <Margin viewportEl={containerRef} />
                    <Box
                        role="content"
                        width={
                            3 * sizeModel.columnWidth +
                            3 * sizeModel.gutterWidth
                        }
                    >
                        <ShowGridComponent
                            color="orange"
                            width={
                                3 * sizeModel.columnWidth +
                                3 * sizeModel.gutterWidth
                            }
                        />
                    </Box>
                    <Box
                        role="content"
                        width={
                            6 * sizeModel.columnWidth +
                            5 * sizeModel.gutterWidth
                        }
                    >
                        <ShowGridComponent
                            color="green"
                            width={
                                6 * sizeModel.columnWidth +
                                5 * sizeModel.gutterWidth
                            }
                        />
                        <ProjectDescription offsetProject={offsetProject} />
                    </Box>
                    <Box
                        role="content"
                        width={
                            3 * sizeModel.columnWidth +
                            3 * sizeModel.gutterWidth
                        }
                    >
                        <ShowGridComponent
                            color="blue"
                            width={
                                3 * sizeModel.columnWidth +
                                3 * sizeModel.gutterWidth
                            }
                        />
                    </Box>
                    <Margin viewportEl={containerRef} />
                </Box>
            )}
        </div>
    );
};

const ProjectSDGSection = ({ offsetProject }: IProjectSectionProps) => {
    const { containerRef } = useContext(LayoutContext);
    const sizeModel = useViewport(BREAKPOINTS, containerRef);
    const forceUpdate = useForceUpdate();
    const featureFlags = useContext(FeatureFlagsContext);
    const { coBrand, images, language } = useContext(PageContext);
    const classes = getClasses(featureFlags, coBrand, sizeModel)(useTheme());
    const keyValues = useContext(KeyValuesContext);

    const sustainableDevelopmentGoals: Array<{
        title: string;
        url: string;
        contentType?: string;
        fileName: string;
        text: string;
    }> = [];

    offsetProject.sustainableDevelopmentGoalList.forEach((sdgId: any) => {
        const imageDetails = images[sdgId.sdg];
        sustainableDevelopmentGoals.push({ ...imageDetails, text: sdgId.text });
    });

    useLayoutEffect(() => {
        // eslint-disable-next-line
        return useOnResize(
            () => {
                forceUpdate();
            },
            BREAKPOINTS,
            containerRef
        );
    });

    const SDGHeader = memo(() => (
        <>
            <img
                alt="SDG"
                src={images["SDG"].url}
                className={classes.sdgImage}
            />
            {!offsetProject.isStandaloneProject && (
                <Typography variant="h5" className={classes.sdgHeader}>
                    {
                        keyValues[
                            "Influence of the project on the local population and the environment"
                        ]
                    }
                </Typography>
            )}
        </>
    ));

    const SDGs = memo(() => (
        <div className={classes.sdgAvatarRoot}>
            {sustainableDevelopmentGoals.map((avt: any) => (
                <div key={avt.title} className={classes.sdgAvatarContainer}>
                    <div className={classes.sdgAvatar}>
                        <Avatar
                            className={joinClasses(
                                classes.sdgAvatarImage,
                                classes[`sdg${avt.title}`]
                            )}
                            src={avt.url}
                        />
                    </div>
                    <Typography
                        variant="body2"
                        className={classes.sdgAvatarsText}
                    >
                        {avt.text}
                    </Typography>
                </div>
            ))}
        </div>
    ));

    return (
        <div className={classes.sdgRoot}>
            {sizeModel.isMobile && (
                <Box
                    role="row"
                    display="flex"
                    flex={1}
                    flexWrap="nowrap"
                    flexDirection="row"
                >
                    <Margin viewportEl={containerRef} />
                    <Box role="content" width={sizeModel.columnWidth}>
                        <ShowGridComponent
                            color="orange"
                            width={sizeModel.columnWidth}
                        />
                    </Box>
                    <Box
                        role="content"
                        width={
                            10 * sizeModel.columnWidth +
                            11 * sizeModel.gutterWidth
                        }
                    >
                        <ShowGridComponent
                            color="green"
                            width={
                                10 * sizeModel.columnWidth +
                                11 * sizeModel.gutterWidth
                            }
                        />
                        <SDGHeader />
                    </Box>
                    <Box role="content" width={sizeModel.columnWidth}>
                        <ShowGridComponent
                            color="orange"
                            width={sizeModel.columnWidth}
                        />
                    </Box>
                    <Margin viewportEl={containerRef} />
                </Box>
            )}
            {sizeModel.isTablet && (
                <Box
                    role="row"
                    display="flex"
                    flex={1}
                    flexWrap="nowrap"
                    flexDirection="row"
                >
                    <Margin viewportEl={containerRef} />
                    <Box
                        role="content"
                        width={sizeModel.columnWidth + sizeModel.gutterWidth}
                    >
                        <ShowGridComponent
                            color="orange"
                            width={
                                sizeModel.columnWidth + sizeModel.gutterWidth
                            }
                        />
                    </Box>
                    <Box
                        role="content"
                        width={
                            10 * sizeModel.columnWidth +
                            9 * sizeModel.gutterWidth
                        }
                    >
                        <ShowGridComponent
                            color="green"
                            width={
                                10 * sizeModel.columnWidth +
                                9 * sizeModel.gutterWidth
                            }
                        />
                        <SDGHeader />
                    </Box>
                    <Box
                        role="content"
                        width={sizeModel.columnWidth + sizeModel.gutterWidth}
                    >
                        <ShowGridComponent
                            color="orange"
                            width={
                                sizeModel.columnWidth + sizeModel.gutterWidth
                            }
                        />
                    </Box>
                    <Margin viewportEl={containerRef} />
                </Box>
            )}
            {(sizeModel.isDesktop ||
                sizeModel.isDesktopXL ||
                sizeModel.isDesktopXXL) && (
                <Box
                    role="row"
                    display="flex"
                    flex={1}
                    flexWrap="nowrap"
                    flexDirection="row"
                >
                    <Margin viewportEl={containerRef} />
                    <Box
                        role="content"
                        width={
                            3 * sizeModel.columnWidth +
                            3 * sizeModel.gutterWidth
                        }
                    >
                        <ShowGridComponent
                            color="orange"
                            width={
                                3 * sizeModel.columnWidth +
                                3 * sizeModel.gutterWidth
                            }
                        />
                    </Box>
                    <Box
                        role="content"
                        width={
                            6 * sizeModel.columnWidth +
                            5 * sizeModel.gutterWidth
                        }
                    >
                        <ShowGridComponent
                            color="green"
                            width={
                                6 * sizeModel.columnWidth +
                                5 * sizeModel.gutterWidth
                            }
                        />
                        <SDGHeader />
                    </Box>
                    <Box
                        role="content"
                        width={
                            3 * sizeModel.columnWidth +
                            3 * sizeModel.gutterWidth
                        }
                    >
                        <ShowGridComponent
                            color="orange"
                            width={
                                3 * sizeModel.columnWidth +
                                3 * sizeModel.gutterWidth
                            }
                        />
                    </Box>
                    <Margin viewportEl={containerRef} />
                </Box>
            )}

            {/* --- SDG images section --- */}

            {(sizeModel.isMobile ||
                sizeModel.isDesktop ||
                sizeModel.isDesktopXXL) && (
                <Box
                    role="row"
                    display="flex"
                    flex={1}
                    flexWrap="nowrap"
                    flexDirection="row"
                >
                    <Margin viewportEl={containerRef} />
                    <Box
                        role="content"
                        width={
                            2 * sizeModel.columnWidth +
                            2 * sizeModel.gutterWidth
                        }
                    >
                        <ShowGridComponent
                            color="orange"
                            width={
                                2 * sizeModel.columnWidth +
                                2 * sizeModel.gutterWidth
                            }
                        />
                    </Box>
                    <Box
                        role="content"
                        width={
                            8 * sizeModel.columnWidth +
                            7 * sizeModel.gutterWidth
                        }
                    >
                        <ShowGridComponent
                            color="green"
                            width={
                                8 * sizeModel.columnWidth +
                                7 * sizeModel.gutterWidth
                            }
                        />
                        <SDGs />
                    </Box>
                    <Box
                        role="content"
                        width={
                            2 * sizeModel.columnWidth +
                            2 * sizeModel.gutterWidth
                        }
                    >
                        <ShowGridComponent
                            color="orange"
                            width={
                                2 * sizeModel.columnWidth +
                                2 * sizeModel.gutterWidth
                            }
                        />
                    </Box>
                    <Margin viewportEl={containerRef} />
                </Box>
            )}

            {sizeModel.isTablet && (
                <Box
                    role="row"
                    display="flex"
                    flex={1}
                    flexWrap="nowrap"
                    flexDirection="row"
                >
                    <Margin viewportEl={containerRef} />
                    <Box
                        role="content"
                        width={sizeModel.columnWidth + sizeModel.gutterWidth}
                    >
                        <ShowGridComponent
                            color="orange"
                            width={
                                sizeModel.columnWidth + sizeModel.gutterWidth
                            }
                        />
                    </Box>
                    <Box
                        role="content"
                        width={
                            10 * sizeModel.columnWidth +
                            9 * sizeModel.gutterWidth
                        }
                    >
                        <ShowGridComponent
                            color="green"
                            width={
                                10 * sizeModel.columnWidth +
                                9 * sizeModel.gutterWidth
                            }
                        />
                        <SDGs />
                    </Box>
                    <Box
                        role="content"
                        width={sizeModel.columnWidth + sizeModel.gutterWidth}
                    >
                        <ShowGridComponent
                            color="orange"
                            width={
                                sizeModel.columnWidth + sizeModel.gutterWidth
                            }
                        />
                    </Box>
                    <Margin viewportEl={containerRef} />
                </Box>
            )}

            {sizeModel.isDesktopXL && (
                <Box
                    role="row"
                    display="flex"
                    flex={1}
                    flexWrap="nowrap"
                    flexDirection="row"
                >
                    <Margin viewportEl={containerRef} />
                    <Box
                        role="content"
                        width={
                            3 * sizeModel.columnWidth +
                            3 * sizeModel.gutterWidth
                        }
                    >
                        <ShowGridComponent
                            color="orange"
                            width={
                                3 * sizeModel.columnWidth +
                                3 * sizeModel.gutterWidth
                            }
                        />
                    </Box>
                    <Box
                        role="content"
                        width={
                            6 * sizeModel.columnWidth +
                            5 * sizeModel.gutterWidth
                        }
                    >
                        <ShowGridComponent
                            color="green"
                            width={
                                6 * sizeModel.columnWidth +
                                5 * sizeModel.gutterWidth
                            }
                        />
                        <SDGs />
                    </Box>
                    <Box
                        role="content"
                        width={
                            3 * sizeModel.columnWidth +
                            3 * sizeModel.gutterWidth
                        }
                    >
                        <ShowGridComponent
                            color="orange"
                            width={
                                3 * sizeModel.columnWidth +
                                3 * sizeModel.gutterWidth
                            }
                        />
                    </Box>
                    <Margin viewportEl={containerRef} />
                </Box>
            )}
        </div>
    );
};

const ProjectQualityBadges = ({ offsetProject }: IProjectSectionProps) => {
    const { containerRef } = useContext(LayoutContext);
    const sizeModel = useViewport(BREAKPOINTS, containerRef);
    const forceUpdate = useForceUpdate();
    const featureFlags = useContext(FeatureFlagsContext);
    const { coBrand, images, language } = useContext(PageContext);
    const classes = getClasses(featureFlags, coBrand, sizeModel)(useTheme());
    const keyValues = useContext(KeyValuesContext);
    const [projectDevelopers, setProjectDevelopers] = useState<
        Array<{ title: string; url: string }>
    >([]);

    useLayoutEffect(() => {
        const newProjectDevelopers = [];

        if (offsetProject.projectDeveloperImage) {
            newProjectDevelopers.push({
                title: offsetProject.projectDeveloperImage.title,
                url: offsetProject.projectDeveloperImage.file.url,
            });
        }

        if (offsetProject.partnerImages && offsetProject.partnerImages[0]) {
            newProjectDevelopers.push({
                title: offsetProject.partnerImages[0].title,
                url: offsetProject.partnerImages[0].file.url,
            });
        }
        setProjectDevelopers(newProjectDevelopers);
    }, [offsetProject]);

    useLayoutEffect(() => {
        // eslint-disable-next-line
        return useOnResize(
            () => {
                forceUpdate();
            },
            BREAKPOINTS,
            containerRef
        );
    });

    const ProjectDeveloperBadges = memo(() => (
        <div className={classes.badgeContainer}>
            {projectDevelopers.map((projectDeveloper) => (
                <div key={projectDeveloper.url} className={classes.badgeBox}>
                    <div className={classes.badgeHead}>
                        <img
                            src={projectDeveloper.url}
                            alt={projectDeveloper.title}
                            className={joinClasses(
                                classes.qualityBadgeProjectDeveloperImage
                            )}
                        />
                    </div>
                    <Typography variant="subtitle1">
                        {keyValues["Project developer"]}
                    </Typography>
                </div>
            ))}
            <div className={classes.badgeBox}>
                <Typography variant="h2" className={classes.badgeHead}>
                    {offsetProject.startYearOfProject}
                </Typography>
                <Typography variant="subtitle1">
                    {keyValues["Project start"]}
                </Typography>
            </div>
            <div className={classes.badgeBox}>
                <Typography variant="h2" className={classes.badgeHead}>
                    {offsetProject.totalCompensationVolumePerYear
                        ? `${formatNumber(
                              language,
                              offsetProject.totalCompensationVolumePerYear,
                              0,
                              0
                          )}t`
                        : keyValues["No limit"]}
                </Typography>
                <Typography variant="subtitle1">
                    {keyValues["Annual compensation volume (CO2)"]}
                </Typography>
            </div>
        </div>
    ));

    return (
        <>
            {(sizeModel.isMobile ||
                sizeModel.isDesktop ||
                sizeModel.isDesktopXXL) && (
                <Box
                    role="row"
                    display="flex"
                    flex={1}
                    flexWrap="nowrap"
                    flexDirection="row"
                >
                    <Margin viewportEl={containerRef} />
                    <Box
                        role="content"
                        width={
                            2 * sizeModel.columnWidth +
                            2 * sizeModel.gutterWidth
                        }
                    >
                        <ShowGridComponent
                            color="orange"
                            width={
                                2 * sizeModel.columnWidth +
                                2 * sizeModel.gutterWidth
                            }
                        />
                    </Box>
                    <Box
                        role="content"
                        width={
                            8 * sizeModel.columnWidth +
                            7 * sizeModel.gutterWidth
                        }
                    >
                        <ShowGridComponent
                            color="green"
                            width={
                                8 * sizeModel.columnWidth +
                                7 * sizeModel.gutterWidth
                            }
                        />
                        <ProjectDeveloperBadges />
                    </Box>
                    <Box
                        role="content"
                        width={
                            2 * sizeModel.columnWidth +
                            2 * sizeModel.gutterWidth
                        }
                    >
                        <ShowGridComponent
                            color="orange"
                            width={
                                2 * sizeModel.columnWidth +
                                2 * sizeModel.gutterWidth
                            }
                        />
                    </Box>
                    <Margin viewportEl={containerRef} />
                </Box>
            )}

            {sizeModel.isTablet && (
                <Box
                    role="row"
                    display="flex"
                    flex={1}
                    flexWrap="nowrap"
                    flexDirection="row"
                >
                    <Margin viewportEl={containerRef} />
                    <Box
                        role="content"
                        width={sizeModel.columnWidth + sizeModel.gutterWidth}
                    >
                        <ShowGridComponent
                            color="orange"
                            width={
                                sizeModel.columnWidth + sizeModel.gutterWidth
                            }
                        />
                    </Box>
                    <Box
                        role="content"
                        width={
                            10 * sizeModel.columnWidth +
                            9 * sizeModel.gutterWidth
                        }
                    >
                        <ShowGridComponent
                            color="green"
                            width={
                                10 * sizeModel.columnWidth +
                                9 * sizeModel.gutterWidth
                            }
                        />
                        <ProjectDeveloperBadges />
                    </Box>
                    <Box
                        role="content"
                        width={sizeModel.columnWidth + sizeModel.gutterWidth}
                    >
                        <ShowGridComponent
                            color="orange"
                            width={
                                sizeModel.columnWidth + sizeModel.gutterWidth
                            }
                        />
                    </Box>
                    <Margin viewportEl={containerRef} />
                </Box>
            )}

            {sizeModel.isDesktopXL && (
                <Box
                    role="row"
                    display="flex"
                    flex={1}
                    flexWrap="nowrap"
                    flexDirection="row"
                >
                    <Margin viewportEl={containerRef} />
                    <Box
                        role="content"
                        width={
                            3 * sizeModel.columnWidth +
                            3 * sizeModel.gutterWidth
                        }
                    >
                        <ShowGridComponent
                            color="orange"
                            width={
                                3 * sizeModel.columnWidth +
                                3 * sizeModel.gutterWidth
                            }
                        />
                    </Box>
                    <Box
                        role="content"
                        width={
                            6 * sizeModel.columnWidth +
                            5 * sizeModel.gutterWidth
                        }
                    >
                        <ShowGridComponent
                            color="green"
                            width={
                                6 * sizeModel.columnWidth +
                                5 * sizeModel.gutterWidth
                            }
                        />
                        <ProjectDeveloperBadges />
                    </Box>
                    <Box
                        role="content"
                        width={
                            3 * sizeModel.columnWidth +
                            3 * sizeModel.gutterWidth
                        }
                    >
                        <ShowGridComponent
                            color="orange"
                            width={
                                3 * sizeModel.columnWidth +
                                3 * sizeModel.gutterWidth
                            }
                        />
                    </Box>
                    <Margin viewportEl={containerRef} />
                </Box>
            )}
        </>
    );
};

const ProjectSelectButton = ({
    offsetProject,
    offsetForProjectLink,
}: {
    offsetProject: GClimateOffsetProject;
    offsetForProjectLink: GLink;
}) => {
    const { containerRef } = useContext(LayoutContext);
    const sizeModel = useViewport(BREAKPOINTS, containerRef);
    const forceUpdate = useForceUpdate();
    const featureFlags = useContext(FeatureFlagsContext);
    const { coBrand, language } = useContext(PageContext);
    const classes = getClasses(featureFlags, coBrand, sizeModel)(useTheme());
    const keyValues = useContext(KeyValuesContext);

    useLayoutEffect(() => {
        // eslint-disable-next-line
        return useOnResize(
            () => {
                forceUpdate();
            },
            BREAKPOINTS,
            containerRef
        );
    });

    const onProjectSelectClick = useCallback(() => {
        getLocalStorageApi().setItem(
            "selectedProjectId",
            JSON.stringify(offsetProject.projectId)
        );

        // TODO check if browser back could also works also without this line
        window.history.back();
    }, [offsetProject.projectId]);

    const SelectButtonContainer = memo(() => {
        const slug = offsetForProjectLink?.page?.slug;
        return (
            <div className={classes.projectSelectButtonContainer}>
                <Button
                    href={pageToLink(language, coBrand, { slug })}
                    onClick={onProjectSelectClick}
                    variant="contained"
                    color="secondary"
                    className={classes.selectButton}
                >
                    {keyValues["Support project"]}
                </Button>
                <ProjectBackLink className={classes.footerBackLink} />
            </div>
        );
    });

    return (
        <>
            {sizeModel.isMobile && (
                <Box
                    role="row"
                    display="flex"
                    flex={1}
                    flexWrap="nowrap"
                    flexDirection="row"
                >
                    <Margin viewportEl={containerRef} />
                    <Box
                        role="content"
                        width={
                            12 * sizeModel.columnWidth +
                            11 * sizeModel.gutterWidth
                        }
                    >
                        <ShowGridComponent
                            color="orange"
                            width={
                                12 * sizeModel.columnWidth +
                                11 * sizeModel.gutterWidth
                            }
                        />
                        <SelectButtonContainer />
                    </Box>
                    <Margin viewportEl={containerRef} />
                </Box>
            )}

            {(sizeModel.isTablet || sizeModel.isDesktopXL) && (
                <Box
                    role="row"
                    display="flex"
                    flex={1}
                    flexWrap="nowrap"
                    flexDirection="row"
                >
                    <Margin viewportEl={containerRef} />
                    <Box
                        role="content"
                        width={
                            3 * sizeModel.columnWidth +
                            3 * sizeModel.gutterWidth
                        }
                    >
                        <ShowGridComponent
                            color="green"
                            width={
                                3 * sizeModel.columnWidth +
                                3 * sizeModel.gutterWidth
                            }
                        />
                    </Box>
                    <Box
                        role="content"
                        width={
                            6 * sizeModel.columnWidth +
                            5 * sizeModel.gutterWidth
                        }
                    >
                        <ShowGridComponent
                            color="orange"
                            width={
                                6 * sizeModel.columnWidth +
                                5 * sizeModel.gutterWidth
                            }
                        />
                        <SelectButtonContainer />
                    </Box>
                    <Box
                        role="content"
                        width={
                            3 * sizeModel.columnWidth +
                            3 * sizeModel.gutterWidth
                        }
                    >
                        <ShowGridComponent
                            color="blue"
                            width={
                                3 * sizeModel.columnWidth +
                                3 * sizeModel.gutterWidth
                            }
                        />
                    </Box>
                    <Margin viewportEl={containerRef} />
                </Box>
            )}

            {sizeModel.isDesktop && (
                <Box
                    role="row"
                    display="flex"
                    flex={1}
                    flexWrap="nowrap"
                    flexDirection="row"
                >
                    <Margin viewportEl={containerRef} />
                    <Box
                        role="content"
                        width={
                            4 * sizeModel.columnWidth +
                            4 * sizeModel.gutterWidth
                        }
                    >
                        <ShowGridComponent
                            color="green"
                            width={
                                4 * sizeModel.columnWidth +
                                4 * sizeModel.gutterWidth
                            }
                        />
                    </Box>
                    <Box
                        role="content"
                        width={
                            4 * sizeModel.columnWidth +
                            3 * sizeModel.gutterWidth
                        }
                    >
                        <ShowGridComponent
                            color="orange"
                            width={
                                4 * sizeModel.columnWidth +
                                3 * sizeModel.gutterWidth
                            }
                        />
                        <SelectButtonContainer />
                    </Box>
                    <Box
                        role="content"
                        width={
                            4 * sizeModel.columnWidth +
                            4 * sizeModel.gutterWidth
                        }
                    >
                        <ShowGridComponent
                            color="blue"
                            width={
                                4 * sizeModel.columnWidth +
                                4 * sizeModel.gutterWidth
                            }
                        />
                    </Box>
                    <Margin viewportEl={containerRef} />
                </Box>
            )}

            {sizeModel.isDesktopXXL && (
                <Box
                    role="row"
                    display="flex"
                    flex={1}
                    flexWrap="nowrap"
                    flexDirection="row"
                >
                    <Margin viewportEl={containerRef} />
                    <Box
                        role="content"
                        width={
                            5 * sizeModel.columnWidth +
                            5 * sizeModel.gutterWidth
                        }
                    >
                        <ShowGridComponent
                            color="green"
                            width={
                                5 * sizeModel.columnWidth +
                                5 * sizeModel.gutterWidth
                            }
                        />
                    </Box>
                    <Box
                        role="content"
                        width={
                            2 * sizeModel.columnWidth + sizeModel.gutterWidth
                        }
                    >
                        <ShowGridComponent
                            color="orange"
                            width={
                                2 * sizeModel.columnWidth +
                                sizeModel.gutterWidth
                            }
                        />
                        <SelectButtonContainer />
                    </Box>
                    <Box
                        role="content"
                        width={
                            5 * sizeModel.columnWidth +
                            5 * sizeModel.gutterWidth
                        }
                    >
                        <ShowGridComponent
                            color="blue"
                            width={
                                5 * sizeModel.columnWidth +
                                5 * sizeModel.gutterWidth
                            }
                        />
                    </Box>
                    <Margin viewportEl={containerRef} />
                </Box>
            )}
        </>
    );
};

const OffsetProject = ({
    header,
    offsetForProjectLink,
    climateOffsetProject,
}: IOffsetProjectProps) => {
    const theme = useTheme();
    const hasBeenTwoPassRendered = useTwoPassRender();
    const { coBrand } = useContext(PageContext);
    const featureFlags = useContext(FeatureFlagsContext) as IFeatureFlags;
    const [newBrandDesignEnabled] = useState(
        featureFlags[FeatureFlags.NEW_BRAND_DESIGN]
    );

    if (!hasBeenTwoPassRendered) {
        return null;
    }

    return (
        <NewMainBrandLayout
            header={{ mainSlug: header.mainSlug, show: true }}
            logoUrl={getImageUrl(header.logo)}
            headerBgShadeColor={theme.palette.primary.main}
        >
            <ProjectHeader offsetProject={climateOffsetProject} />
            <ProjectDescriptionSection offsetProject={climateOffsetProject} />
            {/* <ProjectSDGSection offsetProject={climateOffsetProject} />
            {!climateOffsetProject.isStandaloneProject && (
                <ProjectQualityBadges offsetProject={climateOffsetProject} />
            )}
            {!coBrand &&
                !climateOffsetProject.isStandaloneProject &&
                newBrandDesignEnabled && (
                    <ProjectSelectButton
                        offsetForProjectLink={offsetForProjectLink}
                        offsetProject={climateOffsetProject}
                    />
                )}
            */}
        </NewMainBrandLayout>
    );
};

export default ClimateOffsetProjectPageTemplate;

export const queryPage = graphql`
    query ContentfulClimateOffsetProjectPageBySlugAndLocale(
        $locale: String
        $contentful_id: String
    ) {
        allContentfulKeyValue(filter: { node_locale: { eq: $locale } }) {
            nodes {
                key {
                    key
                }
                locale: node_locale
                value {
                    value
                }
            }
        }
        contentfulClimateOffsetProjectPage(
            node_locale: { eq: $locale }
            contentful_id: { eq: $contentful_id }
        ) {
            locale: node_locale
            title
            slug
            environment
            offsetForProjectLink {
                ...LinkFragment
            }
            climateOffsetProject {
                title
                projectId
                heroImage {
                    title
                    file {
                        url
                        fileName
                        contentType
                    }
                }
                projectName
                projectImages {
                    file {
                        url
                        fileName
                        contentType
                    }
                }
                heroCertificateImage {
                    title
                    file {
                        url
                        fileName
                        contentType
                    }
                }
                howItCompensates {
                    childContentfulRichText {
                        html
                    }
                }
                projectDeveloperImage {
                    title
                    file {
                        url
                        fileName
                        contentType
                    }
                }
                certificateImages {
                    title
                    file {
                        url
                        fileName
                        contentType
                    }
                }
                effectChainText {
                    childContentfulRichText {
                        html
                    }
                    internal {
                        content
                    }
                }
                additionalBenefits {
                    childContentfulRichText {
                        html
                    }
                    internal {
                        content
                    }
                }
                sustainableDevelopmentGoals
                startYearOfProject
                totalCompensationVolumePerYear
                partnerImages {
                    title
                    file {
                        url
                        fileName
                        contentType
                    }
                }
                places {
                    title
                    url
                }
                primaryUnitIcon
                isPortfolioProject
                sustainableDevelopmentGoalList {
                    sdg
                    text
                }
                environment
                isStandaloneProject
            }
            seo {
                ...SeoFragment
            }
            footer {
                ...FooterFragment
            }
            header {
                ...HeaderFragment
            }
        }
    }
`;
