"use strict";
// see https://www.figma.com/file/pe7pnSsRxst4AOJ4DzpKZp/Design-space-%2F%2F-CP-YOU?node-id=6583%3A57338
exports.__esModule = true;
exports.BREAKPOINTS = exports.BreakpointNames = exports.MarginDesktopAllPx = exports.MarginTabletPx = exports.MarginMobilePx = exports.GutterDesktopAllPx = exports.GutterTabletPx = exports.GutterMobilePx = exports.BreakpointDesktopXxlPx = exports.BreakpointDesktopXlPx = exports.BreakpointDesktopPx = exports.BreakpointTabletPx = exports.BreakpointMobilePx = void 0;
exports.BreakpointMobilePx = 0;
exports.BreakpointTabletPx = 650;
exports.BreakpointDesktopPx = 1008;
exports.BreakpointDesktopXlPx = 1280;
exports.BreakpointDesktopXxlPx = 1720;
// gutter space between grid columns
exports.GutterMobilePx = 10;
exports.GutterTabletPx = 20;
exports.GutterDesktopAllPx = 20;
// left and right page margins
exports.MarginMobilePx = 20;
exports.MarginTabletPx = 20;
exports.MarginDesktopAllPx = 50;
var BreakpointNames;
(function (BreakpointNames) {
    BreakpointNames["MOBILE"] = "mobile";
    BreakpointNames["TABLET"] = "tablet";
    BreakpointNames["DESKTOP"] = "desktop";
    BreakpointNames["DESKTOP_XL"] = "desktopXl";
    BreakpointNames["DESKTOP_XXL"] = "desktopXxl";
})(BreakpointNames = exports.BreakpointNames || (exports.BreakpointNames = {}));
exports.BREAKPOINTS = {
    mobile: exports.BreakpointMobilePx,
    tablet: exports.BreakpointTabletPx,
    desktop: exports.BreakpointDesktopPx,
    desktopXl: exports.BreakpointDesktopXlPx,
    desktopXxl: exports.BreakpointDesktopXxlPx
};
