import { brandLink, ICoBrand } from '@cpyou/shared/dist';

export const pageToLink = (language: string, coBrand?: ICoBrand, page?: { slug: string }, search?: string) => {
    if (page && page.slug) {
        let slug = page.slug === '/' ? '' : page.slug;
        if (search) {
            slug = `${slug}${search}`;
        }

        slug = `/${language}/${slug}`.toLowerCase();

        return coBrand ? brandLink(slug, coBrand) : slug;
    }
    return '';
}
