import { makeStyles } from '@material-ui/styles';
import { Theme } from '@material-ui/core';

export const getClasses = makeStyles(({ breakpoints }: Theme) => ({
    logo: {
        cursor: 'pointer',
        marginLeft: 10,
        marginTop: 10,
        width: 90,
        [breakpoints.up('md')]: {
            marginLeft: 47,
            marginTop: 27,
            width: 100,
        },
    },
}))
