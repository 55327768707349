import { makeStyles } from "@material-ui/styles";
import { Theme } from "@material-ui/core";

export const getClasses = () =>
    makeStyles(({ breakpoints }: Theme) => ({
        contentTitle: {
            marginBottom: 16,
        },
        linkClass: {
            textDecoration: "none",
        },
        imageClass: {
            width: "inherit !important",
            maxWidth: "100%",
        },

        listItemClass: {
            listStyle: "none",
            margin: "30px 0",
            "& > p": {
                [breakpoints.down("sm")]: {
                    fontSize: 14,
                },
                [breakpoints.up("md")]: {
                    fontSize: 16,
                },
            },
        },
        listClass: {
            margin: "0px 30px",
            fontSize: "100px",
        },
    }))();
