import { makeStyles } from '@material-ui/styles';
import { Theme } from '@material-ui/core';
import { ISizeModel } from '@cpyou/shared/dist';

export const getClasses = (sizeModel: ISizeModel) => makeStyles(({ breakpoints }: Theme) => ({
    main: {
        backgroundColor: '#fff',
        paddingTop: 19,
        paddingBottom: 22,
        paddingLeft: sizeModel ? sizeModel.marginWidth : 19,
        paddingRight: sizeModel ? sizeModel.marginWidth : 19,
        [breakpoints.up('md')]: {
            display: 'flex',
            paddingTop: 17,
            paddingBottom: 25,
        },
    },
    partners: {
        [breakpoints.up('md')]: {
            float: 'right',
        },
    },
    partnerImg: {
        height: 48,
        paddingRight: 39,
        [breakpoints.up('md')]: {
            paddingLeft: 45,
            paddingRight: 0,
        },
    },
    copyright: {
        fontSize: 10,
        marginTop: 8,
        paddingTop: 2,
        paddingBottom: 2,

        [breakpoints.up('md')]: {
            fontSize: 12,
            paddingTop: 3,
            paddingBottom: 3,
        },
    },
    linkContainer: {
        display: 'flex',
        flexWrap: 'wrap',
        lineHeight: '30px',
    },
    link: {
        fontWeight: 500,
        fontSize: 12,
        paddingRight: 25,
        textDecoration: 'none',
        color: 'black',
    },
    noButtonStyles: {
        background: 'none!important',
        border: 'none',
        padding: 0,
        cursor: 'pointer'
    }
}))
