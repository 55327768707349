import React from 'react'
import { graphql, PageProps } from 'gatsby';

import { navigate } from '../../functions/navigate';
import { GSeo } from '../../interface/graphql/GSeo';
import Seo, { SeoContext } from '../../components/Seo';
import { Helmet } from 'react-helmet';
import { useEffectOnce } from 'react-use';

export interface IRedirectTemplateProps {
  contentfulRedirectPage: {
    locale: string;
    slugTo: string;
    copySearch?: boolean;
    seo: GSeo
  }
}

export default ({ data: { contentfulRedirectPage: { seo, slugTo, copySearch = false, locale } } }: PageProps<IRedirectTemplateProps>) => {
    useEffectOnce(() => {
      let redirectSlug = slugTo
      // add / to beginning if none is set
      if(redirectSlug.substr(0, 1) !== '/') redirectSlug = `/${redirectSlug}`

      // add language if not set
      if(!slugTo.match(/(en\/|de\/)/g)) redirectSlug = `/${locale === 'en-US' ? 'en' : locale}${redirectSlug}`

      // navigate 
      navigate(redirectSlug, { replace: true }, copySearch)
    })

    return (
        <>
            <SeoContext.Provider value={seo}>
                <Helmet>
                  <link rel="canonical" href={slugTo} />
                </Helmet>
                <Seo />
            </SeoContext.Provider>
        </>
    )
}

export const queryPage = graphql`
query ContentfulRedirectPage($slug: String, $locale: String) {
  contentfulRedirectPage(slug: {eq: $slug}, node_locale: {eq: $locale}) {
    locale: node_locale,
    slugTo,
    seo {
      ...SeoFragment
    }
  }
}
`
